import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';

const Skeleton = styled.h4`
  color: ${getColor('primary')};
  width: 100%;
  text-align: center;
  margin: 1rem;
`;

export const StyledAutocompleteLoader = {
  Skeleton,
};
