/*************************************************  Imports  *************************************************/
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppAnalytics } from 'analytics';

import { ConfiguratorDropdown } from '../configurator-dropdown/ConfiguratorDropdown';
import {
  filterChargeMaxOptionDisabled,
  filterChargeMinOptionDisabled,
  getSocDropdownOptions,
  SocControlNameModel,
  SOC_SELECTION_RANGE_VALUES,
} from './soc-selection.util';

import { SocSelectionFormModel } from '../../../../models/car-config/soc-selection-form.model';
import { SocSelectionPropsModel } from '../../../../models/car-config/soc-selection-props.model';

import { SocSelectionStyles } from './SocSelection.styles';
import { SocSlider } from './soc-slider/SocSlider';
import { useEffect } from 'react';
import { useRef } from 'react';
import { loaderService } from '../../../../services/loader.service';
import { getVehicleDetails } from '../../../../services/http/get-vehicle-details';
import { VehicleDetailsModel } from '../../../../models/vehicle-details.model';
import { errorService } from '../../../../services/error.service';

/*************************************************  Component  *************************************************/
export const SocSelection = ({
  localStorageData,
  selectedVehicleId,
  onSocConfigChange,
  updateSocValuesOnOptimalChargingSpeedChange,
}: SocSelectionPropsModel) => {
  /*************************************************  State  *************************************************/
  const [socSelectionForm, setSocSelectionForm] = useState<SocSelectionFormModel>({
    soc_origin: localStorageData
      ? localStorageData.config.soc_origin
      : SOC_SELECTION_RANGE_VALUES.SOC_ORIGIN.default,
    soc_destination: localStorageData
      ? localStorageData.config.soc_destination
      : SOC_SELECTION_RANGE_VALUES.SOC_DESTINATION.default,
    charge_min: localStorageData
      ? localStorageData.config.charge_min
      : SOC_SELECTION_RANGE_VALUES.CHARGE_MIN.default,
    charge_max: localStorageData
      ? localStorageData.config.charge_max
      : SOC_SELECTION_RANGE_VALUES.CHARGE_MAX.default,
    charge_penalty: localStorageData
      ? localStorageData.config.charge_penalty
      : SOC_SELECTION_RANGE_VALUES.CHARGE_PENALTY.default,
  });

  const [selectedVehicleDetails, setSelectedVehicleDetails] = useState<VehicleDetailsModel | null>(
    null
  );

  const { t } = useTranslation();
  const { trackEvent } = useAppAnalytics();

  /*************************************************  useEffect() and API  *************************************************/
  const fetchVehicleDetails = useCallback(async (vId: string) => {
    loaderService.showLoader();
    try {
      const response = await getVehicleDetails(vId);

      setSelectedVehicleDetails(response);

      const maxSoc = response.optimum_charging_level
        ? response.optimum_charging_level
        : SOC_SELECTION_RANGE_VALUES.CHARGE_MAX.default;

      setSocSelectionForm({
        ...socSelectionForm,
        charge_min: SOC_SELECTION_RANGE_VALUES.CHARGE_MIN.default,
        charge_max: maxSoc,
      });

      updateSocValuesOnOptimalChargingSpeedChange(
        SOC_SELECTION_RANGE_VALUES.CHARGE_MIN.default,
        maxSoc
      );

      loaderService.closeLoader();
    } catch (error) {
      errorService.showError('vehicleConfigurator.errorGettingVehicleDetails');
      loaderService.closeLoader();
    }
  }, []);

  const isFirstCarSelection = useRef(true);

  useEffect(() => {
    if (selectedVehicleId) {
      if (localStorageData && isFirstCarSelection.current) {
        isFirstCarSelection.current = false;
        return;
      } else {
        (async () => {
          try {
            fetchVehicleDetails(selectedVehicleId);
          } catch (error) {
            errorService.showError('vehicleConfigurator.errorGettingVehicleDetails');
          }
        })();
      }
    }
  }, [selectedVehicleId, fetchVehicleDetails]);

  const getMaxSocAdditionalOption = () => {
    if (selectedVehicleDetails?.optimum_charging_level) {
      return selectedVehicleDetails.optimum_charging_level;
    } else if (localStorageData) {
      return localStorageData.config.charge_max;
    }
    return null;
  };

  /*************************************************  Dropdown logic  *************************************************/
  // Handlers for  disabled options
  const handleChargeMinDisabledFilter = (value: number) => {
    return filterChargeMinOptionDisabled(value, socSelectionForm.charge_max);
  };

  const handleChargeMaxDisabledFilter = (value: number) => {
    return filterChargeMaxOptionDisabled(value, socSelectionForm.charge_min);
  };

  /*************************************************  Value change logic  *************************************************/
  // onChange
  const handleFormControlValueChange = (value: number, name: SocControlNameModel) => {
    setSocSelectionForm({ ...socSelectionForm, [name]: value });
    sendDataToConfigurator(value, name);
  };

  /*************************************************  Send data back to 'Configurator.tsx'  *************************************************/
  // It is necessary to pass 'name' and 'value'
  // Because last state is not updated at the moment of calling this function
  const sendDataToConfigurator = (value: number, name: SocControlNameModel) => {
    onSocConfigChange({ ...socSelectionForm, [name]: value });
  };

  const sendSocToConfig = (min: number, max: number) => {};

  /*************************************************  Template  *************************************************/
  return (
    <>
      <SocSelectionStyles.SocHeading>{t('carConfig.setSoc')}</SocSelectionStyles.SocHeading>

      <SocSlider
        label="carConfig.socAtOrigin"
        selected={socSelectionForm.soc_origin}
        defaultConfig={SOC_SELECTION_RANGE_VALUES.SOC_ORIGIN}
        onValueChange={(event) => {
          // TODO: Use onChangeCommitted instead to prevent unnecessary re-renders until final slider value is selected by
          // depressing the mouse button
          handleFormControlValueChange(event, 'soc_origin');
        }}
        onChangeCommitted={(_, value) => {
          trackEvent({
            category: 'carAndRouteSettings-granular',
            action: 'SoC at origin changed',
            name: value.toString(),
          });
        }}
        dataCy="configuration-soc-origin"
      />
      <SocSlider
        label="carConfig.socAtDestination"
        selected={socSelectionForm.soc_destination}
        defaultConfig={SOC_SELECTION_RANGE_VALUES.SOC_DESTINATION}
        onValueChange={(event) => handleFormControlValueChange(event, 'soc_destination')} // TODO: Use onChangeCommitted
        onChangeCommitted={(_, value) => {
          trackEvent({
            category: 'carAndRouteSettings-granular',
            action: 'SoC at destination changed',
            name: value.toString(),
          });
        }}
        dataCy="configuration-soc-destination"
      />

      <SocSelectionStyles.MinMaxContainer>
        <ConfiguratorDropdown
          values={getSocDropdownOptions(SOC_SELECTION_RANGE_VALUES.CHARGE_MIN)}
          label="carConfig.chargeMin"
          onValueChange={(event) => {
            handleFormControlValueChange(event, 'charge_min');

            trackEvent({
              category: 'carAndRouteSettings-granular',
              action: 'Minimum SoC changed',
              name: event.toString(),
            });
          }}
          selected={socSelectionForm.charge_min}
          disabledOptionFilter={handleChargeMinDisabledFilter}
          suffix="%"
          dataCy="configuration-charge-min"
        />

        <ConfiguratorDropdown
          values={getSocDropdownOptions(
            SOC_SELECTION_RANGE_VALUES.CHARGE_MAX,
            getMaxSocAdditionalOption()
          )}
          label="carConfig.chargeMax"
          onValueChange={(event) => {
            handleFormControlValueChange(event, 'charge_max');
            trackEvent({
              category: 'carAndRouteSettings-granular',
              action: 'Maximum SoC changed',
              name: event.toString(),
            });
          }}
          selected={socSelectionForm.charge_max}
          disabledOptionFilter={handleChargeMaxDisabledFilter}
          suffix="%"
          additionalSuffix={{
            text: t('carConfig.chargeMax.optimal'),
            conditionValueForSuffix: getMaxSocAdditionalOption(),
          }}
          dataCy="configuration-charge-max"
        />
      </SocSelectionStyles.MinMaxContainer>

      <ConfiguratorDropdown
        values={getSocDropdownOptions(SOC_SELECTION_RANGE_VALUES.CHARGE_PENALTY)}
        label="carConfig.chargePenalty"
        onValueChange={(event) => {
          handleFormControlValueChange(event, 'charge_penalty');

          trackEvent({
            category: 'carAndRouteSettings-granular',
            action: 'Charging penalty changed',
            name: `${event}min`,
          });
        }}
        selected={socSelectionForm.charge_penalty}
        suffix="min"
        dataCy="configuration-charge-penalty"
      />
    </>
  );
};
