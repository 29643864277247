import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';

const OriginMarker = styled.div`
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  background-color: ${getColor('primary')};
  border-radius: 50%;
  border: 2px solid ${getColor('primaryContrast')};
  box-shadow: 2px 4px 4px ${getColor('primaryDisabled')};
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1000;
`;

const MarkerTooltip = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 1rem);
  transform: translateY(-50%);
  background: ${getColor('primary')};
  color: ${getColor('primaryContrast')};
  max-width: 30rem;
  width: max-content;
  padding: 0.5rem 1.5rem;
  border-radius: 0.8rem;
  pointer-events: none;

  &.safari {
    width: auto;
  }
`;

export const StyledOriginalMarker = {
  OriginMarker,
  MarkerTooltip,
};
