/*************************************************  Imports  *************************************************/
import { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';

import { routeFiltersLocalStorageService } from 'core/services/local-storage/route-filters-ls.service';
import { getVehicles } from '../../../services/http/get-vehicles';

import { loaderService } from '../../../services/loader.service';
import { errorService } from '../../../services/error.service';
import { vehicleConfigurationService } from '../../../services/local-storage/vehicle-configuration.service';

import { SOC_SELECTION_RANGE_VALUES } from './soc-selection/soc-selection.util';

import { CarSelection } from './car-selection/CarSelection';
import { SocSelection } from './soc-selection/SocSelection';
import { PaymentProvidersAndChargingNetworksFilters } from '../payment-providers-and-charging-networks-filters/PaymentProvidersAndChargingNetworksFilters';
import { PowerTypeFilter } from '../power-type-filter/PowerTypeFilter';

import { ConfiguratorStyles } from './Configurator.styles';
import CloseIcon from '@material-ui/icons/Close';
import logoIcon from '../../../../assets/images/logo/logo-icon.svg';

import { ConfiguratorPropsModel } from '../../../models/car-config/configurator-props.model';
import { VehicleModel } from '../../../models/vehicle.model';
import { ConfiguratorFormModel } from '../../../models/car-config/configurator-form.model';
import { CarSelectionFormModel } from '../../../models/car-config/car-selection-form.model';
import { SocSelectionFormModel } from '../../../models/car-config/soc-selection-form.model';
import { FilterPowerTypeModel } from '../../../models/power-type/filter-power-type.model';
import { useAppAnalytics } from 'analytics';
import { CarConfigModel } from '../../../models/car-config/car-config.model';
import * as _ from 'lodash';
import { DrivingStyle } from './driving-style/DrivingStyle';
import { DrivingStyleModel } from '../../../models/car-config/driving-style.model';
import {
  powerTypeToPowerTypeLabelMap,
  POWER_TYPE_FIELD_NAMES,
} from 'core/util/filter-power-type.util';

/*************************************************  Component  *************************************************/
export const Configurator = ({
  onConfiguratorSave,
  isConfiguratorOpened,
  setIsOverlayOpened,
}: ConfiguratorPropsModel) => {
  /*************************************************  State  *************************************************/
  const localStorageData = vehicleConfigurationService.isVehicleConfigurationSet()
    ? vehicleConfigurationService.getVehicleConfiguration()
    : null;

  const [vehicles, setVehicles] = useState<VehicleModel[]>([]);

  const [selectedVehicleId, setSelectedVehicleId] = useState('');
  const [configuratorForm, setConfiguratorForm] = useState<ConfiguratorFormModel>({
    manufacturer: '',
    model: '',
    variant: '',
    soc_origin: SOC_SELECTION_RANGE_VALUES.SOC_ORIGIN.default,
    soc_destination: SOC_SELECTION_RANGE_VALUES.SOC_DESTINATION.default,
    charge_min: SOC_SELECTION_RANGE_VALUES.CHARGE_MIN.default,
    charge_max: SOC_SELECTION_RANGE_VALUES.CHARGE_MAX.default,
    charge_penalty: SOC_SELECTION_RANGE_VALUES.CHARGE_PENALTY.default,
    driving_profile:
      localStorageData && localStorageData.config.driving_profile
        ? localStorageData.config.driving_profile
        : 'NORMAL',
  });
  const [settings, setSettings] = useState<CarConfigModel | null>(null);

  const isFirstCarSelection = useRef(false);

  const { trackEvent } = useAppAnalytics();

  /*************************************************  API  *************************************************/
  const fetchVehicles = useCallback(async () => {
    loaderService.showLoader();
    try {
      const response = await getVehicles();
      setVehicles(response);
      loaderService.closeLoader();
    } catch (error) {
      errorService.showError('vehicleConfigurator.errorLoadingVehicles');
      loaderService.closeLoader();
    }
  }, []);

  useEffect(() => {
    if (!vehicles.length) {
      (async () => {
        try {
          fetchVehicles();
        } catch (error) {
          errorService.showError('vehicleConfigurator.errorLoadingVehicles');
        }
      })();
    }
  }, [fetchVehicles]);

  /*************************************************  Logic  *************************************************/
  const saveDataAndCloseDialog = () => {
    // Save to localStorage
    vehicleConfigurationService.setVehicleConfiguration({
      vehicleId: selectedVehicleId,
      config: configuratorForm,
    });

    const carAndRouteConfigData = {
      vehicle_id: selectedVehicleId,
      soc_origin: configuratorForm.soc_origin,
      soc_destination: configuratorForm.soc_destination,
      charge_min: configuratorForm.charge_min,
      charge_max: configuratorForm.charge_max,
      charge_penalty: configuratorForm.charge_penalty * 60,
      driving_profile: configuratorForm.driving_profile,
    };

    let extendedCarAndRouteConfigData = _.extend({}, carAndRouteConfigData, {
      model: configuratorForm.model,
      manufacturer: configuratorForm.manufacturer,
      variant: configuratorForm.variant,
    });

    trackEvent({
      category: 'carAndRouteSettings',
      action: `${JSON.stringify(extendedCarAndRouteConfigData)}:`,
      name: JSON.stringify(settings),
    });

    trackIfChargingSpeedWasChanged();

    // Send data to 'Sidebar.tsx'
    onConfiguratorSave(carAndRouteConfigData);

    // Close overlay
    setIsOverlayOpened(false);
  };

  const trackIfChargingSpeedWasChanged = () => {
    // Used to track charging speed selection only if some speeds from default values were deselected
    try {
      // get charging speed config: { power_type_ac: true, power_type_dc: false, power_type_hpc: true }
      const powerTypeConfig = routeFiltersLocalStorageService.getPowerType();

      // transform to ['AC', 'HPC'] array containing only selected charging speed labels
      const selectedChargingSpeeds = Object.keys(powerTypeConfig)
        .filter((powerType) => !!powerTypeConfig[powerType as POWER_TYPE_FIELD_NAMES])
        .map((key) => powerTypeToPowerTypeLabelMap[key as POWER_TYPE_FIELD_NAMES]);

      if (selectedChargingSpeeds.length < Object.keys(powerTypeConfig).length) {
        // By default, all speeds are selected. We track event only if some were deselected.
        trackEvent({
          category: 'carAndRouteSettings-granular',
          action: 'Charging Speed changed',
          name: selectedChargingSpeeds.join(', '),
        });
      }
    } catch {}
  };

  // Initialize form
  useEffect(() => {
    if (vehicleConfigurationService.isVehicleConfigurationSet()) {
      const { config, vehicleId } = vehicleConfigurationService.getVehicleConfiguration();

      setConfiguratorForm({ ...configuratorForm, ...config });

      setSelectedVehicleId(vehicleId);

      onConfiguratorSave({
        vehicle_id: vehicleId,
        soc_origin: config.soc_origin,
        soc_destination: config.soc_destination,
        charge_min: config.charge_min,
        charge_max: config.charge_max,
        charge_penalty: config.charge_penalty * 60,
        driving_profile: config.driving_profile,
      });
    }
  }, []);

  // Car selection change handler
  const onCarSelectionChange = (carSelectionConfig: CarSelectionFormModel) => {
    if (!localStorageData && !isFirstCarSelection.current) {
      onConfiguratorSave({
        vehicle_id: carSelectionConfig.vehicleId,
        soc_origin: SOC_SELECTION_RANGE_VALUES.SOC_ORIGIN.default,
        soc_destination: SOC_SELECTION_RANGE_VALUES.SOC_DESTINATION.default,
        charge_min: SOC_SELECTION_RANGE_VALUES.CHARGE_MIN.default,
        charge_max: SOC_SELECTION_RANGE_VALUES.CHARGE_MAX.default,
        charge_penalty: SOC_SELECTION_RANGE_VALUES.CHARGE_PENALTY.default,
        driving_profile: configuratorForm.driving_profile,
      });

      isFirstCarSelection.current = true;
    }

    setConfiguratorForm({
      ...configuratorForm,
      manufacturer: carSelectionConfig.manufacturer,
      model: carSelectionConfig.model,
      variant: carSelectionConfig.variant,
    });
    setSelectedVehicleId(carSelectionConfig.vehicleId);
  };

  // SoC selection change handler
  const onSocSelectionChange = (socSelectionConfig: SocSelectionFormModel) => {
    setConfiguratorForm({
      ...configuratorForm,
      ...socSelectionConfig,
    });
  };

  const updateState = (min: number, max: number) => {
    setConfiguratorForm((configurationForm: ConfiguratorFormModel) => ({
      ...configurationForm,
      charge_min: min,
      charge_max: max,
    }));
  };

  const updateSocValuesOnOptimalChargingSpeedChange = (min: number, max: number) => {
    updateState(min, max);
  };

  const onDrivingStyleChange = (newDrivingStyle: DrivingStyleModel) => {
    setConfiguratorForm({
      ...configuratorForm,
      driving_profile: newDrivingStyle,
    });
  };

  /*************************************************  Template  *************************************************/
  return (
    <ConfiguratorStyles.OverlayContainer className={isConfiguratorOpened ? 'opened' : ''}>
      <ConfiguratorStyles.OverlayHeader>
        <ConfiguratorStyles.LogoIcon src={logoIcon} alt="Electric Routes Icon" />

        <IconButton onClick={saveDataAndCloseDialog} data-cy="configurator-close-button">
          <CloseIcon />
        </IconButton>
      </ConfiguratorStyles.OverlayHeader>

      <ConfiguratorStyles.OverlayContent>
        <form>
          <CarSelection
            vehicles={vehicles}
            localStorageData={localStorageData}
            onCarChange={onCarSelectionChange}
          />

          <DrivingStyle
            selectedDrivingStyle={configuratorForm.driving_profile}
            onDrivingStyleChange={onDrivingStyleChange}
          />

          <SocSelection
            localStorageData={localStorageData}
            selectedVehicleId={selectedVehicleId}
            onSocConfigChange={onSocSelectionChange}
            updateSocValuesOnOptimalChargingSpeedChange={
              updateSocValuesOnOptimalChargingSpeedChange
            }
          />
        </form>

        <PowerTypeFilter />

        <PaymentProvidersAndChargingNetworksFilters />
      </ConfiguratorStyles.OverlayContent>
    </ConfiguratorStyles.OverlayContainer>
  );
};
