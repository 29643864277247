import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './index.scss';
import './i18n';
import reportWebVitals from './reportWebVitals';

//In case we need it, wrap <App /> in <React.StrictMode></React.StrictMode>
ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
