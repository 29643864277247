import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { filterPopupService } from '../../../../services/filter-popup.service';
import { AppContext, AppContextModel } from '../../../../app-context';
import { FILTER_TYPES } from '../../../../util/filters.util';
import { routeFiltersLocalStorageService } from '../../../../services/local-storage/route-filters-ls.service';
import { useAppAnalytics } from 'analytics';
import { StyledFilter } from './Filter.styles';

const Filter = (props: any) => {
  const { t } = useTranslation();
  const { appContextValue, setAppContextValue } = useContext(AppContext);
  const [items, setItems] = useState<any>(null);
  const [filters, setFilters] = useState<any | null>(null);

  const { trackEvent } = useAppAnalytics();

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  Logic  *************************************************/
  useEffect(() => {
    if (props.items?.length) {
      const clonedItems = props.items.map((obj: any) => ({ ...obj }));

      if (props.filterName === FILTER_TYPES.PAYMENT_SERVICES) {
        if (appContextValue?.filterPaymentServices) {
          let excludedPaymentProviders: string[] = [];
          appContextValue.filterPaymentServices.forEach((item: any) => {
            const found = clonedItems.find((el: any) => el.uuid === item.uuid);
            if (found) {
              found.isChecked = item.isChecked;
              if (!found.isChecked) {
                excludedPaymentProviders.push(found.provider);
              }
            }
          });
          if (excludedPaymentProviders.length) {
            trackEvent({
              category: 'carAndRouteSettings',
              action: `Excluded payment providers`,
              name: excludedPaymentProviders.join(', '),
            });
          }
        }

        if (appContextValue?.manufacturer?.toLowerCase() !== 'tesla') {
          // Find Tesla payment provider and hide it
          clonedItems.forEach((item: any) => {
            if (item.provider.toLowerCase() === 'tesla') {
              item.isVisible = false;
            }
          });
        } else {
          // Find Tesla payment provider and un-hide it
          clonedItems.forEach((item: any) => {
            if (item.provider.toLowerCase() === 'tesla') {
              item.isVisible = true;
            }
          });
        }

        setItems(clonedItems);

        updateContext('filterPaymentServices', clonedItems);

        routeFiltersLocalStorageService.setPaymentProviders(clonedItems);
      } else {
        // Charging networks
        if (appContextValue?.filterChargingPointOperators) {
          let excludedChargingNetworks: string[] = [];
          appContextValue.filterChargingPointOperators.forEach((item: any) => {
            const found = clonedItems.find((el: any) => el.uuid === item.uuid);
            if (found) {
              found.isChecked = item.isChecked;
              if (!found.isChecked) {
                excludedChargingNetworks.push(found.name);
              }
            }
          });
          if (excludedChargingNetworks.length) {
            trackEvent({
              category: 'carAndRouteSettings',
              action: `Excluded charging networks`,
              name: excludedChargingNetworks.join(', '),
            });
          }
        }

        setItems(clonedItems);

        updateContext('filterChargingPointOperators', clonedItems);

        routeFiltersLocalStorageService.setChargingNetworks(clonedItems);
      }
    }
  }, [
    props.items,
    props.filterName,
    appContextValue.manufacturer,
    appContextValue.filterUpdated,
    filters,
    trackEvent,
  ]);

  const showFilterModal = () => {
    filterPopupService.showFilterPopup({
      filterName: props.filterName,
      items: items,
    });
  };

  return (
    <StyledFilter.StyledButton
      variant="outlined"
      size="large"
      onClick={showFilterModal}
      data-cy={
        'configuration-' + props.filterName.toLowerCase().split('_').join('-') + '-filter-button'
      }
    >
      <h3>
        {t(props.filterName)}
        <StyledFilter.NumberOfItems>
          {`(${items?.filter((item: any) => item.isChecked && item.isVisible).length})`}
        </StyledFilter.NumberOfItems>
      </h3>
      <ArrowRightIcon />
    </StyledFilter.StyledButton>
  );
};

export default Filter;
