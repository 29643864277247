import {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Box, TextField } from '@material-ui/core';
import useDebounce from '../../../shared/custom-hooks/useDebounce';

import { AppContext, AppContextModel } from '../../../../app-context';
import { errorService } from '../../../../services/error.service';
import { getGeocodingAutocomplete } from '../../../../services/http/get-geocoding-autocomplete';

import {
  GeocodingAutocompleteModel,
  GeocodingResultModel,
} from '../../../../models/geocoding-result.model';

import { GeolocationInputStyles } from '../GeolocationInput.styles';
import StopIcon from 'assets/images/trip-stop.svg';
import DefaultMarkerIcon from 'assets/images/marker/marker-default.svg';
import { AutocompleteLoader } from '../autocomplete-loader/AutocompleteLoader';
import { useAppAnalytics } from 'analytics';
import { RouteStop } from 'core/models/planner/route-stop.model';
import { RemoveStopButton } from './RemoveStopButton';
import { useRouteStops } from '../useRouteStops';

interface RouteStopInputProps {
  stopIndex: number;
  stop: RouteStop;
  onLoadingChange?: (isLoading: boolean) => void;
}

export const RouteStopInput: FunctionComponent<RouteStopInputProps> = memo(
  ({ stop, stopIndex, onLoadingChange }) => {
    const { appContextValue, setAppContextValue } = useContext(AppContext);
    const [address, setAddress] = useState('');
    const { stops, clearStop, removeStopAtIndex, addStopAtIndex } = useRouteStops();

    const [isLoading, setIsLoading_LOCAL] = useState(false);

    // TODO: Move loading indicator to redux slice, remove hijacking setState
    const setIsLoading = useCallback(
      (isLoading: boolean) => {
        setIsLoading_LOCAL(isLoading);
        if (onLoadingChange) {
          onLoadingChange(isLoading);
        }
      },
      [onLoadingChange]
    );

    const [
      autocompleteOptions,
      setAutocompleteOptions_LOCAL_STATE,
    ] = useState<GeocodingResultModel>();

    const updateContext = useCallback(
      (key: string, value: any) => {
        setAppContextValue((appContextValue: AppContextModel) => ({
          ...appContextValue,
          [key]: value,
        }));
      },
      [setAppContextValue]
    );

    /**
     * TODO: Fix this setState hijacking once autocomplete results are moved to Redux.
     *
     * Intermediate step can be moving results to context first.
     *
     * Why this: These autocomplete results are needed elsewhere, in origin/destination switch for better UX, to know
     * when to hide/show the switch button.
     *
     */
    const setAutocompleteOptions = useCallback(
      (options: GeocodingResultModel) => {
        setAutocompleteOptions_LOCAL_STATE(options);
        updateContext('totalResults', options.results.length);
      },
      [updateContext]
    );

    const inputRef = useRef<any>(null);

    const { t } = useTranslation();

    const { trackEvent } = useAppAnalytics();

    //*************************************************  User Locale  *************************************************//
    const getUsersLocale = () => {
      if (navigator.languages) return navigator.languages[0].split('-')[0];
      return navigator.language.split('-')[0];
    };

    const [usersLocale] = useState(() => getUsersLocale());

    //*************************************************  API  *************************************************//
    // Whenever address is changed API is called
    // Since Address is changed even on autocomplete option click, we will make an unwanted aPI call
    // This resolves that issue
    const makeADebouncedCall = useRef(false);
    // Debounce logic and API call
    const debouncedSearchTerm = useDebounce(address, 200, makeADebouncedCall.current);

    // TODO: Extract to hook, reuse in GeolocationInput
    // Fetch logic
    const fetchAutocomplete = useCallback(
      async (query: string) => {
        try {
          const response = await getGeocodingAutocomplete(query, usersLocale);

          setAutocompleteOptions(response);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          errorService.showError('sidebar.autocomplete.error');
        }
      },
      [usersLocale, setAutocompleteOptions, setIsLoading]
    );

    // useEffect for calling the API
    useEffect(() => {
      if (debouncedSearchTerm) {
        setIsLoading(true);

        fetchAutocomplete(debouncedSearchTerm);
      } else {
        setAutocompleteOptions({ results: [] });
      }
    }, [debouncedSearchTerm, fetchAutocomplete, setAutocompleteOptions, setIsLoading]);

    // populate 'address' when 'stop' prop is provided
    useEffect(() => {
      setAddress(stop.name);
    }, [stop]);

    /* -------------------- Input logic -------------------- */
    const handleChange = (value: string) => {
      //After 'address' is set, 'useDebounce' hook will perform API call
      makeADebouncedCall.current = true;

      setAddress(value);
    };

    const handleClearField = () => {
      // Clear the form control value
      if (address) {
        setAddress('');
      }

      // Invalidate stop to disable button
      clearStop(stopIndex);
    };

    /* -------------------- Autocomplete selection logic -------------------- */
    const handleSelect = (value: GeocodingAutocompleteModel) => {
      //This prevents 'useDebounce' to make a new API call after 'address' is set
      makeADebouncedCall.current = false;

      setAddress(value.title);

      const selectedLocation = {
        name: value.title.split(',')[0],
        lat: value.geo_location.latitude,
        lng: value.geo_location.longitude,
        id: stop.id,
      };

      // TODO: Extract to RouteStopInput
      if (typeof stopIndex !== 'number') {
        throw new Error('must provide stopIndex to GeolocationInput');
      }

      addStopAtIndex({
        lat: selectedLocation.lat,
        lng: selectedLocation.lng,
        name: selectedLocation.name,
        stopIndex,
      });

      setAutocompleteOptions({ results: [] });

      focusOnMap(selectedLocation);

      trackEvent({
        category: 'routeCalculation',
        action: 'Trip stop selected',
      });
    };

    // TODO: Extract to hook, reuse in GeolocationInput
    /* -------------------- Focus or center map -------------------- */
    const focusOnMap = (coords?: { lat: number; lng: number }) => {
      const origin = appContextValue.origin;
      const destination = appContextValue.destination;

      const bounds = new google.maps.LatLngBounds();

      const locations = [
        origin,
        destination,
        ...stops.filter((_stop, index) => (coords ? true : index !== stopIndex)),
        coords,
      ];

      locations
        .filter((location) => typeof location?.lat === 'number')
        .forEach((location) => {
          const position = new google.maps.LatLng(location.lat, location.lng);
          bounds.extend(position);
        });

      if ((!origin || !destination) && coords) {
        appContextValue.map.setCenter(coords);
      } else {
        appContextValue.map.fitBounds(bounds);
      }
    };

    const removeStop = () => {
      removeStopAtIndex(stopIndex);
      focusOnMap();
    };

    //*************************************************  Template  *************************************************//
    return (
      <GeolocationInputStyles.ContentContainer className="route-stop-input">
        <Box display="flex">
          <GeolocationInputStyles.CustomInput>
            <GeolocationInputStyles.InputIcon src={StopIcon} alt={'stop-icon'} />
            <TextField
              value={t(address)} // TODO: use flag to mark this is current location { lat, lng, name, isCurrentLocation } instead of translating any incoming string
              inputRef={inputRef}
              autoFocus
              fullWidth
              placeholder={t('stops.stopInputPlaceholder')}
              label={t('stops.stopInputLabel')}
              className="customInput"
              onChange={(event: any) => handleChange(event.target.value)}
              data-cy={`stop-input-${stopIndex}`}
              id={`stop-input-${stopIndex}`}
              autoComplete="off"
            />
            {address ? (
              <GeolocationInputStyles.CustomIconButton
                onClick={handleClearField}
                data-cy={`stop-input-${stopIndex}-clear`}
              >
                <GeolocationInputStyles.CustomCloseIcon />
              </GeolocationInputStyles.CustomIconButton>
            ) : null}
          </GeolocationInputStyles.CustomInput>
          <RemoveStopButton
            onClick={removeStop}
            id={`remove-stop-button-${stopIndex}`}
            data-cy={`remove-stop-button-${stopIndex}`}
          />
        </Box>

        <div>
          {isLoading ? <AutocompleteLoader /> : null}

          {autocompleteOptions?.results?.slice(0, 10).map((option: GeocodingAutocompleteModel) => {
            return (
              <GeolocationInputStyles.SuggestionItem
                key={option.title + option.geo_location.latitude + option.geo_location.longitude}
                onClick={() => handleSelect(option)}
                data-cy="geolocation-autocomplete-option"
              >
                <GeolocationInputStyles.SuggestionItemIcon
                  src={DefaultMarkerIcon}
                  alt="Default Marker Icon"
                />
                <GeolocationInputStyles.SuggestionItemTextContainer>
                  <GeolocationInputStyles.SuggestionItemTextMain>
                    {option.title.split(',')[0]}
                  </GeolocationInputStyles.SuggestionItemTextMain>
                  <GeolocationInputStyles.SuggestionItemTextSecondary>
                    {option.title.substring(option.title.indexOf(',') + 1)}
                  </GeolocationInputStyles.SuggestionItemTextSecondary>
                </GeolocationInputStyles.SuggestionItemTextContainer>
              </GeolocationInputStyles.SuggestionItem>
            );
          })}
        </div>
      </GeolocationInputStyles.ContentContainer>
    );
  }
);
