import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';
import { Accordion, AccordionDetails, Button } from '@material-ui/core';

const DetailsInfoContainer = styled.div`
  margin-top: -2.5rem;
  margin-bottom: 3.8rem;
  flex: 1;
`;

const TitleH3 = styled.h3`
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
`;

const BlueHours = styled.span`
  color: ${getColor('primary')};
  margin-right: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
`;

const BlueChip = styled.div`
  height: 3rem;
  border-radius: 3rem;
  padding: 0.8rem 1.5rem 0.8rem 0.5rem;
  background-color: ${getColor('primary')};
  color: ${getColor('primaryContrast')};
  display: inline-flex;
  align-items: center;
  margin-top: 2.5rem;

  span {
    margin-left: 1rem;
    margin-top: 0.4rem;
  }
`;

const HintsContainer = styled.div`
  margin-top: 2rem;

  p {
    margin-top: 0.5rem;
  }
`;

const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${getColor('sliderRail', 0.1)};
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const AmenitiesTitleH3 = styled(TitleH3)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    margin-top: 0.4rem;
  }
`;

const ShowOnMapButton = styled(Button)`
  width: 3.4rem;
  height: 3.4rem;
  min-width: 3.4rem !important;
  border-radius: 50% !important;
`;

const PaymentAccordion = styled(Accordion)`
  &.MuiPaper-root.MuiAccordion-root {
    background: transparent;
    margin-bottom: -2.5rem;

    &::before {
      opacity: 0;
    }

    svg {
      color: ${getColor('text')};
    }
  }
`;

const PaymentAccordionDetails = styled(AccordionDetails)`
  margin-bottom: 1.5rem;
  margin-top: -2rem;
`;

const ProviderContainer = styled.div`
  margin-top: 2rem;

  span {
    font-size: 1.5rem;
    color: ${getColor('textLightest')};
  }
`;

const OperatorLink = styled.a`
  &[href] {
    color: ${getColor('primary')};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const CapabilitiesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledLink = styled.a`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const StyledDetailsInfo = {
  DetailsInfoContainer,
  TitleH3,
  BlueHours,
  BlueChip,
  HintsContainer,
  SeparatorLine,
  AmenitiesTitleH3,
  ShowOnMapButton,
  PaymentAccordion,
  PaymentAccordionDetails,
  ProviderContainer,
  OperatorLink,
  CapabilitiesContainer,
  StyledLink,
};
