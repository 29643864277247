import styled from '@emotion/styled';
import Select from '@material-ui/core/Select';

const LanguageSelect = styled(Select)`
  & .MuiSelect-select {
    background: none !important;
    padding-bottom: 0 !important;
    font-size: 1.2rem;
    font-weight: 700;
  }

  &::before,
  &::after {
    border: none !important;
  }
`;

export const StyledLanguageSwitcher = {
  LanguageSelect,
};
