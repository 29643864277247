/*************************************************  Imports  *************************************************/
import styled from '@emotion/styled';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { getColor } from '../../../theme/colors/colors';
import { getFont } from '../../../theme/fonts/fonts';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/*************************************************  Styles  *************************************************/
const ContentContainer = styled.div`
  position: relative;

  &.destination {
    margin-top: 1rem;
  }

  &.origin {
    margin-bottom: 1rem;
  }

  & > .currentLocationButtonContainer {
    display: none;
  }

  &:focus-within,
  &:active {
    & > .currentLocationButtonContainer {
      display: block;
    }
  }
`;

const CustomInput = styled.div`
  padding: 1rem 1.5rem;
  box-shadow: inset 0px 0px 0px 1px ${getColor('primaryDisabled')};
  border: 1px solid ${getColor('primary', 0)};
  border-radius: ${getVar('borderRadius')};
  display: flex;
  flex: 1;
  align-items: center;
  translate: 0.2s;

  &:hover {
    border-color: ${getColor('primary')};
  }

  &:focus-within {
    border-color: ${getColor('primary')};
    box-shadow: inset 0px 0px 0px 1px ${getColor('primary')};
  }
`;

const InputIcon = styled.img`
  margin-right: 1.5rem;
`;

const SuggestionItem = styled.button`
  border-bottom: 1px solid ${getColor('suggestionDivider')};
  padding: 1.5rem calc(${getVar('sidebarPadding')} + 1.5rem);
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${getColor('text', 0)};
  transition: 0.3s;
  width: calc(100% + (${getVar('sidebarPadding')} * 2));
  margin-left: calc(${getVar('sidebarPadding')} * -1);
  text-align: left;
  color: ${getColor('text')};
  font-family: ${getFont('mainFont')};

  &:hover {
    background-color: ${getColor('text', 0.05)};
  }
`;

const SuggestionItemIcon = styled.img`
  height: 2.3rem;
  margin-right: 1.5rem;
  margin-left: 0.25rem;
`;

const SuggestionItemTextContainer = styled.div`
  width: 100%;
`;

const SuggestionItemTextMain = styled.h4``;

const SuggestionItemTextSecondary = styled.p`
  font-size: 1.4rem;
  text-overflow: ellipsis;
`;

const CustomIconButton = styled(IconButton)`
  margin-left: 1rem !important;
  padding: 2px !important;
`;

const CustomCloseIcon = styled(CloseIcon)`
  color: ${getColor('text')};
  width: 0.8em !important;
  height: 0.8em !important;
`;

const CurrentLocationButton = styled.a`
  border-bottom: 1px solid ${getColor('suggestionDivider')};
  padding: 1.5rem calc(${getVar('sidebarPadding')} + 1.5rem);
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${getColor('text', 0)};
  transition: 0.3s;
  margin: 0 calc(${getVar('sidebarPadding')} * -1);

  &:hover {
    background-color: ${getColor('text', 0.05)};
  }
`;

const CurrentLocationIcon = styled.img`
  margin-right: 1.5rem;
`;

const CurrentLocationText = styled.h4`
  margin-top: 0.5rem;
  color: ${getColor('primary')};
`;

/*************************************************  Export  *************************************************/
export const GeolocationInputStyles = {
  ContentContainer,
  CustomInput,
  InputIcon,
  SuggestionItem,
  SuggestionItemIcon,
  SuggestionItemTextContainer,
  SuggestionItemTextMain,
  SuggestionItemTextSecondary,
  CustomIconButton,
  CustomCloseIcon,
  CurrentLocationButton,
  CurrentLocationIcon,
  CurrentLocationText,
};
