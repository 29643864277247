import { FunctionComponent } from 'react';
import { getWeatherIcon } from 'core/util/weather.util';
import { StyledRouteLocationList } from '../../RouteLocationList.styles';
import { TripLegModel } from 'core/models/planner/trip-leg.model';

interface WeatherInfoProps {
  tripLeg: TripLegModel;
}

export const WeatherInfo: FunctionComponent<WeatherInfoProps> = ({ tripLeg }) => {
  const weatherDataAvailable =
    tripLeg.weather_start_location?.weather_conditions?.length ||
    tripLeg.weather_start_location?.temperature;

  return weatherDataAvailable ? (
    <StyledRouteLocationList.WeatherContainer>
      {tripLeg.weather_start_location?.weather_conditions?.length ? (
        <StyledRouteLocationList.WeatherIcon>
          <img
            src={getWeatherIcon(tripLeg.weather_start_location, tripLeg.time_start_location)}
            alt="weather-icon"
          />
        </StyledRouteLocationList.WeatherIcon>
      ) : null}
      {tripLeg.weather_start_location?.temperature ? (
        <StyledRouteLocationList.WeatherTemperature
          className={
            !tripLeg.weather_start_location?.weather_conditions?.length
              ? 'temperature-bottom-margin'
              : ''
          }
        >
          {tripLeg.weather_start_location?.temperature + '°C'}
        </StyledRouteLocationList.WeatherTemperature>
      ) : null}
    </StyledRouteLocationList.WeatherContainer>
  ) : null;
};
