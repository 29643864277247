/*************************************************  Imports  *************************************************/
import styled from '@emotion/styled';

/*************************************************  Styles  *************************************************/
const CarHeading = styled.h1`
  margin-bottom: 1rem;
`;

/*************************************************  Export  *************************************************/
export const CarSelectionStyles = { CarHeading };
