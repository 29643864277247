import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { axiosData } from '../../../api/axios-data';
import { ChargingPointOperatorModel } from '../../models/locations-full/charging-point-operator.model';
import { chargingStationService } from '../../../api/api';

export const getChargingPointOperators = (): Promise<ChargingPointOperatorModel[]> => {
  return axiosData<ChargingPointOperatorModel[]>(
    (): AxiosObservable<ChargingPointOperatorModel[]> => {
      return chargingStationService.get('/charging-point-operators');
    }
  )().toPromise();
};
