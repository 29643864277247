import {
  REVIEW_OPTIONS,
  REVIEW_OPTIONS_DESCRIPTIVE,
} from '../../../../../util/review-options.util';
import { getElapsedTime } from '../../../../../util/elapsed-time.util';
import { RatingCommentModel } from '../../../../../models/community-tab/rating-comment.model';
import { StyledReviewWithTime } from './ReviewWithTime.styles';

export const ReviewWithTime = (review: RatingCommentModel) => {
  const getReviewOption = (reviewOption: RatingCommentModel) => {
    if (checkIfReviewOptionHasDescription(reviewOption.option)) {
      return (
        <div key={reviewOption.option}>
          <StyledReviewWithTime.StyledTime>
            {getElapsedTime(review?.date_submitted)}
          </StyledReviewWithTime.StyledTime>
          <StyledReviewWithTime.StyledReview>
            {
              REVIEW_OPTIONS_DESCRIPTIVE[
                reviewOption.option as keyof typeof REVIEW_OPTIONS_DESCRIPTIVE
              ]
            }
          </StyledReviewWithTime.StyledReview>
        </div>
      );
    } else {
      return '';
    }
  };

  const checkIfReviewOptionHasDescription = (reviewOption: string) => {
    const phraseWithoutDescription = [
      REVIEW_OPTIONS.NO_CARD,
      REVIEW_OPTIONS.PRIVATE,
      REVIEW_OPTIONS.SEMI_PRIVATE,
      REVIEW_OPTIONS.PUBLIC,
    ];
    return !phraseWithoutDescription.includes(reviewOption as REVIEW_OPTIONS);
  };

  return <>{review?.option && getReviewOption(review)}</>;
};
