import styled from '@emotion/styled';
import FlagIcon from '../../../../../assets/images/flag.svg';
import { getColor } from '../../../../theme/colors/colors';

const DestinationMarker = styled.div`
  position: relative;
  width: 2.6rem;
  height: 3rem;
  background-image: url(${FlagIcon});
  background-size: 3.6rem 5rem;
  background-position: center;
  background-repeat: no-repeat;
  transform: translate(calc(-50% + 0.2rem), calc(-50% - 1.4rem));
  z-index: 1000;
  pointer-events: none;
`;

const MarkerTooltip = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 1rem);
  transform: translateY(-50%);
  background: ${getColor('primary')};
  color: ${getColor('primaryContrast')};
  max-width: 30rem;
  width: max-content;
  padding: 0.5rem 1.5rem;
  border-radius: 0.8rem;
  pointer-events: none;

  &.safari {
    width: auto;
  }
`;

export const StyledDestinationMarker = {
  DestinationMarker,
  MarkerTooltip,
};
