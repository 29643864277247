import { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import styled from '@emotion/styled';
import { useAppAnalytics } from 'analytics';
import { AppContext, AppContextModel } from 'core/app-context';
import { ReactComponent as SwitchOrderActiveIcon } from 'assets/images/switch-order-active.svg';
import { ReactComponent as SwitchOrderInactiveIcon } from 'assets/images/switch-order-inactive.svg';
import { useRouteStops } from '../useRouteStops';

export const OriginAndDestinationOrderToggle = () => {
  const { trackEvent } = useAppAnalytics();
  const { appContextValue, setAppContextValue } = useContext(AppContext);
  const origin = appContextValue.origin;
  const destination = appContextValue.destination;
  const totalResults = appContextValue.totalResults;
  const { stops } = useRouteStops();

  const switchOriginAndDestination = () => {
    const oldOrigin = { ...appContextValue.origin };
    const oldDestination = { ...appContextValue.destination };

    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      origin: oldDestination,
      destination: oldOrigin,
    }));

    trackEvent({
      category: 'routeCalculation',
      action: 'Origin and destination switched',
    });
  };

  const activeInput = appContextValue.activeInput;

  const hidden =
    (totalResults && totalResults > 0) ||
    (activeInput === 'origin' && !origin) ||
    (activeInput === 'destination' && !destination);
  const disabled = !appContextValue.origin || !appContextValue.destination;

  // in case there are 1+ stops between start and destination, we don't render the toggle at all for better UX
  if (stops.length > 0) {
    return null;
  }

  return (
    <StyledIconButton hidden={hidden} disabled={disabled} onClick={switchOriginAndDestination}>
      {!appContextValue.origin || !appContextValue.destination ? (
        <SwitchOrderInactiveIcon />
      ) : (
        <SwitchOrderActiveIcon />
      )}
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)`
  align-self: center;
  margin-right: -8px; // per design, we want the icon aligned to the right side, not the icon container
  padding: 6.5px;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;
