import Axios from 'axios-observable';
import { environment } from '../environment';

const createApi = (baseURL: string) => {
  return Axios.create({
    baseURL,
    headers: { 'x-api-key': environment.xApiKey },
  });
};

export const chargingStationService = createApi(environment.services.chargingStationService);

export const plannerService = createApi(environment.services.plannerService);

export const geocodingService = createApi(environment.services.geocodingService);

export const vehicleService = createApi(environment.services.vehicleService);
