import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Box } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { memo, useContext, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { AppContext } from 'core/app-context';
import { RouteStopInput } from './RouteStopInput';
import styled from '@emotion/styled';
import { getColor } from 'core/theme/colors/colors';
import { useRouteStops } from '../useRouteStops';

// helper function from react-beautiful-dnd examples to reorder the trip stops array
const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const RouteStopInputs = memo(() => {
  const [isLoading, setIsLoading] = useState(false);
  const { stops, setStops } = useRouteStops();
  const { appContextValue } = useContext(AppContext);
  const totalResults = appContextValue.totalResults;
  const shouldRenderDragHandle = stops.length >= 2;
  const draggingDisabled = (totalResults && totalResults > 0) || isLoading; // prevent dragging whole input with search results

  const onDragEnd = (result: DropResult) => {
    // `destination` is `undefined` if the item was dropped outside the list
    // In this case, do nothing
    if (!result.destination) {
      return;
    }

    const newStops = reorder(
      stops,
      result.source.index,
      (result.destination?.index as unknown) as number
    );

    setStops(newStops);
  };

  if (stops.length === 0) {
    return null;
  }

  return (
    <>
      {/* Prevents "0.5rem" glitch/jump on dropping the item back to Droppable area */}
      <Box marginTop="-0.5rem" />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {stops.map((stop, index) => (
                <Draggable
                  key={stop.id}
                  draggableId={stop.id}
                  index={index}
                  isDragDisabled={draggingDisabled}
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <DragContainer isDragging={snapshot.isDragging}>
                        {shouldRenderDragHandle ? (
                          <DragHandleContainer
                            {...provided.dragHandleProps}
                            isDisabled={draggingDisabled}
                          >
                            <DragIndicatorIcon />
                          </DragHandleContainer>
                        ) : null}

                        <Box marginLeft={shouldRenderDragHandle ? 4 : 0}>
                          <RouteStopInput
                            stop={stop}
                            stopIndex={index}
                            onLoadingChange={setIsLoading}
                          />
                        </Box>
                      </DragContainer>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* Prevents "0.5rem" glitch/jump on dropping the item back to Droppable area */}
      <Box marginBottom="-0.5rem" />
    </>
  );
});

const DragHandleContainer = styled.div<{ isDisabled: boolean }>`
  position: absolute;
  top: 21px;
  left: -4px;
  color: ${(props) => (props.isDisabled ? getColor('primaryDisabled') : 'unset')};
`;

const DragContainer = styled(Box)<{ isDragging: boolean }>`
  position: relative;
  background-color: ${(props) => (props.isDragging ? getColor('surface') : 'unset')};
  opacity: ${(props) => (props.isDragging ? 0.7 : 1)};
  border-radius: 14px;
  padding-bottom: 0.5rem; // keep padding, margin causes strange glitch on drop
  padding-top: 0.5rem; // keep padding, margin causes strange glitch on drop
`;
