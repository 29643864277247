/*************************************************  Imports  *************************************************/
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';
import { useAppAnalytics } from 'analytics';
import { MapStyles } from './MapStyles';
import { CurrentLocationMarker } from './markers/current-location-marker/CurrentLocationMarker';
import { LocationRequestModel } from '../../models/locations/location-request.model';
import { getAllChargingStations } from '../../services/http/get-all-charging-stations';
import { ClusterModel } from '../../models/locations/cluster.model';
import { LocationModel } from '../../models/locations/location.model';
import { ClusterMarker } from './markers/cluster-marker/ClusterMarker';
import { LocationMarker } from './markers/location-marker/LocationMarker';
import { AppContext, AppContextModel } from '../../app-context';
import { environment } from '../../../environment';
import { OriginMarker } from './markers/OriginMarker';
import { DestinationMarker } from './markers/destination-marker/DestinationMarker';
import { locationPinInfoService } from '../../services/location-dialog.service';
import { getLocationById } from '../../services/http/get-location-by-id';
import { LocationPinPopupModel } from '../../models/locations-full/location-pin-popup.model';
import { LocationFullModel } from '../../models/locations-full/location-full.model';
import { errorService } from '../../services/error.service';
import { routeFiltersLocalStorageService } from '../../services/local-storage/route-filters-ls.service';

import EuropeImage from '../../../assets/images/europe.jpg';
import { StyledMapView } from './MapView.styles';
import { TripStopModel } from 'core/models/planner/trip-stop.model';
import { useRouteStops } from '../sidebar-view/origin-and-destination/useRouteStops';

/*************************************************  Component  *************************************************/
export const MapView = (props: any) => {
  /*************************************************  State  *************************************************/
  // ***** Context
  const { appContextValue, setAppContextValue } = useContext(AppContext);
  const { stops } = useRouteStops();
  const { trackEvent } = useAppAnalytics();
  const { t } = useTranslation();

  // ***** Map
  const [mapConfig] = useState({
    key: environment.googleMapsKey,
    center: {
      lat: 49.508742,
      lng: 15.9326171,
    },
    zoom: 5,
    options: {
      minZoom: 3,
      disableDefaultUI: true,
      zoomControl: false,
      backgroundColor: '#c3d1e4',
      styles: MapStyles,
      restriction: {
        latLngBounds: {
          // This value (85.05115) restricts user from 'panning out' from map into the 'gray area' vertically
          // https://stackoverflow.com/questions/11849636/maximum-lat-and-long-bounds-for-the-world-google-maps-api-latlngbounds
          north: 85.05115,
          south: -85.05115,
          east: 180,
          west: -180,
        },
      },
    },
  });
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);

  // ***** Locations and clusters
  const [locationsAndClusters, setLocationsAndClusters] = useState<{
    clusters: ClusterModel[];
    locations: LocationModel[];
  } | null>(null);

  const [selectedLocation, setSelectedLocation] = useState('');

  // ***** Polyline
  const [allPolylines, setAllPolylines] = useState<any[]>([]);
  const [hasRenderedPolyline, setHasRenderedPolyline] = useState(false);

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  useEffect  *************************************************/
  useEffect(() => {
    if (!appContextValue.usersLocation) {
      // This will not happen in SAFARI
      // Safari doesn't have 'navigator.permissions'
      if (navigator.permissions) {
        // Render users current location on the map if they have already allowed access earlier
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state === 'granted') {
            getGeolocation();
          }
        });
      }
    }

    if (appContextValue.route && !hasRenderedPolyline) {
      resetLocationPopup();
      renderPolyline();
    }

    if (!appContextValue.route && hasRenderedPolyline) {
      resetLocationPopup();
      removePolyline();
    }
  }, [
    appContextValue.usersLocation,
    appContextValue.map,
    appContextValue.route,
    appContextValue.googleMapsTC,
  ]);

  useEffect(() => {
    const sub = locationPinInfoService.locationPinInfo.subscribe(
      (locationInfo: LocationPinPopupModel | null) => {
        if (locationInfo === null) {
          setSelectedLocation('');
        } else {
          setSelectedLocation(locationInfo.location.id);
        }
      }
    );
    return () => {
      sub.unsubscribe();
    };
  }, [appContextValue.routeSelectedStationId]);

  // ***** useLayoutEffect()
  useLayoutEffect(() => {
    if (!appContextValue.origin && !appContextValue.destination && appContextValue.map) {
      appContextValue.map.setCenter(mapConfig.center);
      appContextValue.map.setZoom(mapConfig.zoom);
      setMapRef(appContextValue.map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContextValue.origin, appContextValue.destination]);

  /*************************************************  Logic  *************************************************/
  const getGeolocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      updateContext('usersLocation', {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  // ***** Calculating coordinates
  const calcLng = (lng: number) => {
    const floor = Math.floor(Math.abs(lng) / 180);
    const adding = floor % 2 ? 180 : 0;
    const isOverMax = lng > 180 ? -1 : 1;

    return 180 * isOverMax * floor + lng + adding * isOverMax;
  };

  const calcLat = (lat: number) => {
    const floor = Math.floor(Math.abs(lat) / 90);
    const adding = floor % 2 ? 90 : 0;
    const isOverMax = lat > 90 ? -1 : 1;

    return 90 * isOverMax * floor + lat + adding * isOverMax;
  };

  const getUuids = (arr: any[]) => {
    return arr?.reduce((filtered: any[] = [], option: any) => {
      if (option.isChecked && option.isVisible) {
        filtered.push(option.uuid);
      }
      return filtered;
    }, []);
  };

  // ***** Map change and reference
  const onMapsChange = (props: ChangeEventValue) => {
    if (!hasRenderedPolyline) {
      (async () => {
        try {
          fetchChargingStations({
            northeast: {
              latitude: calcLat(props.bounds.ne.lat),
              longitude: calcLng(props.bounds.ne.lng),
            },
            southwest: {
              latitude: calcLat(props.bounds.sw.lat),
              longitude: calcLng(props.bounds.sw.lng),
            },
            zoom: props.zoom,
            filter_payment_services:
              getUuids(routeFiltersLocalStorageService.getPaymentProviders()) || null,
            filter_charging_point_operators:
              getUuids(routeFiltersLocalStorageService.getChargingNetworks()) || null,
            filter_power_type: routeFiltersLocalStorageService.getPowerType() || null,
          });
        } catch (error) {
          errorService.showError('map.errorLoadingAllChargingStations');
        }
      })();
    }
  };

  const fetchChargingStations = useCallback(async (body: LocationRequestModel) => {
    try {
      const chargingStations = await getAllChargingStations({
        requestBody: body,
      });
      setLocationsAndClusters(chargingStations);
    } catch (error) {
      errorService.showError('map.errorLoadingAllChargingStations');
    }
  }, []);

  const handleGoogleApiLoaded = ({ map }: any) => {
    if (appContextValue.map === null) {
      setMapRef(map);

      updateContext('map', map);
    }
  };

  /* Reload charging stations on the map after filter change (Payment Providers, Charging Networks, Charging Speed) */
  useEffect(() => {
    if (
      !hasRenderedPolyline &&
      (appContextValue.filterUpdated?.length || appContextValue.filterPowerTypeUpdated)
    ) {
      (async () => {
        try {
          fetchChargingStations({
            northeast: {
              latitude: calcLat(
                mapRef?.getBounds()?.getNorthEast().lat() ||
                  appContextValue.map.getBounds().getNorthEast().lat()
              ),
              longitude: calcLng(
                mapRef?.getBounds()?.getNorthEast().lng() ||
                  appContextValue.map.getBounds().getNorthEast().lng()
              ),
            },
            southwest: {
              latitude: calcLat(
                mapRef?.getBounds()?.getSouthWest().lat() ||
                  appContextValue.map.getBounds().getSouthWest().lat()
              ),
              longitude: calcLng(
                mapRef?.getBounds()?.getSouthWest().lng() ||
                  appContextValue.map.getBounds().getSouthWest().lng()
              ),
            },
            zoom: mapRef?.getZoom() || appContextValue.map.zoom,
            filter_payment_services:
              getUuids(routeFiltersLocalStorageService.getPaymentProviders()) || null,
            filter_charging_point_operators:
              getUuids(routeFiltersLocalStorageService.getChargingNetworks()) || null,
            filter_power_type: routeFiltersLocalStorageService.getPowerType() || null,
          });
        } catch (error) {
          errorService.showError('map.errorLoadingAllChargingStations');
        }
      })();
    }
  }, [appContextValue.filterUpdated, appContextValue.filterPowerTypeUpdated]);

  /*************************************************  Rendering logic  *************************************************/
  const renderUserCurrentLocation = () => {
    if (appContextValue.usersLocation) {
      return (
        <CurrentLocationMarker
          lat={appContextValue.usersLocation.lat}
          lng={appContextValue.usersLocation.lng}
        ></CurrentLocationMarker>
      );
    }
  };

  const renderOriginMarker = () => {
    if (appContextValue.origin) {
      return (
        <OriginMarker
          name={t(appContextValue.origin.name)} // TODO: use flag to mark this is current location { lat, lng, name, isCurrentLocation } instead of translating any incoming string
          lat={appContextValue.origin.lat}
          lng={appContextValue.origin.lng}
        ></OriginMarker>
      );
    }
  };

  const renderDestinationMarker = () => {
    if (appContextValue.destination) {
      // TODO: Replace with Stop Marker once design is ready
      return (
        <DestinationMarker
          name={t(appContextValue.destination.name)} // TODO: use flag to mark this is current location { lat, lng, name, isCurrentLocation } instead of translating any incoming string
          lat={appContextValue.destination.lat}
          lng={appContextValue.destination.lng}
        ></DestinationMarker>
      );
    }
  };

  const renderRouteStopMarkers = () => {
    return stops.map((stop) => {
      // TODO: Use non-initialized temporary stop in context instead of pushing an empty stop to 'stops' array
      // !stop.lat must not be used as a check for validity since latitude and longitude can be 0, which are valid values
      if (typeof stop.lat !== 'number' || typeof stop.lng !== 'number') {
        return null;
      }

      // TODO: Replace with Trip Stop Marker icon once design is ready
      return <DestinationMarker name={stop.name} lat={stop.lat} lng={stop.lng}></DestinationMarker>;
    });
  };

  const renderClusters = () => {
    if (!hasRenderedPolyline) {
      const clusters: any[] = [];

      locationsAndClusters?.clusters.map((cluster: ClusterModel) => {
        clusters.push(
          <ClusterMarker
            key={cluster.lat + '' + cluster.lng}
            lat={cluster.lat}
            lng={cluster.lng}
            numberOfStations={cluster.number}
          />
        );
      });

      return clusters;
    }
  };

  const renderLocations = () => {
    if (!hasRenderedPolyline) {
      const locations: any[] = [];

      locationsAndClusters?.locations.map((location: LocationModel) => {
        locations.push(
          <LocationMarker
            key={location.id}
            lat={location.lat}
            lng={location.lng}
            id={location.id}
            isClicked={location.id === selectedLocation}
            toggleSelectedLocation={toggleSelectedLocation}
          />
        );
      });

      return locations;
    }
  };

  // ***** Location popup logic
  const resetLocationPopup = (locationId?: string) => {
    setSelectedLocation('');
    locationPinInfoService.closeLocationInfoPopup();

    if (locationId) {
      // trigger card deselection in the RouteLocationList component
      updateContext('selectedMarker', { locationId: locationId, show: false });
    }
  };

  const fetchLocationInfoById = useCallback(
    async (locationId: string) => {
      locationPinInfoService.closeLocationInfoPopup();

      try {
        const response = await getLocationById(locationId);
        if (response.length) {
          trackEvent({
            category: 'chargingStations',
            action: 'Charging station without route - ' + response[0]?.name,
            name: response[0]?.id,
          });
        }
        setSelectedLocation(locationId);
        locationPinInfoService.showLocationInfoPopup({ location: response[0] });
      } catch (error) {
        errorService.showError('map.errorLoadingChargingStationDetails');
      }
    },
    [trackEvent]
  );

  const openRouteLocation = (locationId: string) => {
    locationPinInfoService.closeLocationInfoPopup();

    setSelectedLocation(locationId);

    // NOTE: charging_station can be null if the stop is a simple location selected on the map
    const clickedLocation = appContextValue.route.trip_stops.filter(
      (ts: any) => ts.charging_station?.id === locationId
    );

    if (clickedLocation.length && clickedLocation[0].charging_station) {
      const chargingStation = clickedLocation[0]?.charging_station;
      trackEvent({
        action: 'Charging station within the route - ' + chargingStation?.name,
        name: chargingStation?.id,
        category: 'chargingStations',
      });
    }

    locationPinInfoService.showLocationInfoPopup({
      location: clickedLocation[0].charging_station,
    });

    // trigger card selection in the RouteLocationList component
    updateContext('selectedMarker', { locationId: locationId, show: true });
  };

  const toggleSelectedLocation = (locationId: string) => {
    locationId === selectedLocation
      ? resetLocationPopup(locationId)
      : hasRenderedPolyline
      ? openRouteLocation(locationId)
      : fetchLocationInfoById(locationId);
  };

  /*************************************************  Polyline Logic  *************************************************/
  const renderPolyline = useCallback(() => {
    if (appContextValue.route && !hasRenderedPolyline) {
      setHasRenderedPolyline(true);
      const pathComplete: google.maps.LatLng[] = [];
      const polylines: google.maps.Polyline[] = [];

      const bounds = new google.maps.LatLngBounds();

      appContextValue.route.trip_legs.forEach(function (leg: any) {
        leg.steps.forEach(function (step: any) {
          const path = google.maps.geometry.encoding.decodePath(step.polyline);
          if (!pathComplete.length) {
            pathComplete.push(...google.maps.geometry.encoding.decodePath(step.polyline));
          } else {
            path.forEach(function (item: any) {
              pathComplete.push(item);
            });
          }

          const outlinePoly = new google.maps.Polyline({
            path: path,
            zIndex: 50,
            strokeColor: '#002699',
            strokeOpacity: 1.0,
            strokeWeight: 5,
            clickable: false,
          });

          const polyline = new google.maps.Polyline({
            path: path,
            zIndex: 51,
            strokeColor: '#0040FF',
            strokeOpacity: 1.0,
            strokeWeight: 3,
            clickable: false,
          });

          polylines.push(polyline);
          polylines.push(outlinePoly);
        });

        bounds.extend({
          lat: leg.start_location.latitude,
          lng: leg.start_location.longitude,
        });
      });

      let timeout = 1000.0 / polylines.length;

      for (let i = 0; i < polylines.length; i++) {
        setTimeout(
          function (polyline: google.maps.Polyline) {
            polyline.setMap(mapRef);

            if (i === polylines.length - 1) {
              setAllPolylines(polylines);
            }
          },
          i * timeout,
          polylines[i],
          i
        );
      }

      // bounds.extend({
      //   lat: appContextValue.origin.lat,
      //   lng: appContextValue.origin.lng,
      // });
      bounds.extend({
        lat: appContextValue.destination.lat,
        lng: appContextValue.destination.lng,
      });
      mapRef?.fitBounds(bounds);
    }
  }, [appContextValue.route]);

  const renderRouteLocations = () => {
    if (appContextValue.route && hasRenderedPolyline) {
      const locations: any[] = [];

      appContextValue.route.trip_stops
        .filter((tripStop: TripStopModel) => !!tripStop.charging_station) // only render trip stops which contain charging station data
        .forEach((location: TripStopModel) => {
          locations.push(
            <LocationMarker
              key={location.charging_station.id}
              lat={location.charging_station.coordinates.latitude}
              lng={location.charging_station.coordinates.longitude}
              id={location.charging_station.id}
              isClicked={location.charging_station.id === selectedLocation}
              toggleSelectedLocation={toggleSelectedLocation}
            />
          );
        });

      return locations;
    }
  };

  const removePolyline = () => {
    allPolylines.forEach((poly: any) => {
      poly.setMap(null);
    });

    setHasRenderedPolyline(false);
  };

  // ****************************** MOOVILITY STATION LOGIC
  const isMoovilityStationSet = useRef(false);

  useEffect(() => {
    if (!isMoovilityStationSet.current && appContextValue.map && appContextValue.moovilityStation) {
      const { map, moovilityStation } = appContextValue;
      const stationCoordinated = {
        lat: moovilityStation.coordinates.latitude,
        lng: moovilityStation.coordinates.longitude,
      };

      // Open 'LocationPinPopup' for moovility station
      locationPinInfoService.showLocationInfoPopup({
        location: moovilityStation,
      });

      // If user has allowed acces to their location
      if (appContextValue.usersLocation) {
        // Make map bounds from origin & destination
        const bounds = new google.maps.LatLngBounds();

        const locations = [
          {
            lat: appContextValue.usersLocation.lat,
            lng: appContextValue.usersLocation.lng,
          },
          stationCoordinated,
        ];

        locations.forEach((location) => {
          const position = new google.maps.LatLng(location.lat, location.lng);
          bounds.extend(position);
        });

        // Fit map to bounds
        map.fitBounds(bounds);

        // Set Origin and Destination in context
        updateBothOriginAndDestinationInContext(
          appContextValue.usersLocation,
          appContextValue.moovilityStation
        );
      } else {
        // If user didn't allow location (or is first time in the app)
        // Zoom in and center to the station
        map.setZoom(10);
        map.setCenter(stationCoordinated);

        // Set destination in context
        updateContext('destination', {
          ...stationCoordinated,
          name: moovilityStation.name,
        });
      }

      setSelectedLocation(moovilityStation.id);

      isMoovilityStationSet.current = true;
    }
  }, [appContextValue]);

  const updateBothOriginAndDestinationInContext = (user: any, station: LocationFullModel) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      origin: {
        name: 'sidebar.yourCurrentPosition',
        lat: user.lat,
        lng: user.lng,
      },
      destination: {
        name: station.name,
        lat: station.coordinates.latitude,
        lng: station.coordinates.longitude,
      },
    }));
  };

  /*************************************************  Template  *************************************************/
  return (
    <StyledMapView.MapContainer>
      {appContextValue.googleMapsTC ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapConfig.key, libraries: 'geometry' }}
          defaultCenter={mapConfig.center}
          defaultZoom={mapConfig.zoom}
          options={mapConfig.options}
          onChange={onMapsChange}
          hoverDistance={0}
          onGoogleApiLoaded={handleGoogleApiLoaded}
          // onTilesLoaded={setMapReferenceInContext}
          yesIWantToUseGoogleMapApiInternals={true}
        >
          {renderClusters()}
          {renderLocations()}
          {renderUserCurrentLocation()}

          {renderOriginMarker()}
          {renderRouteStopMarkers()}
          {renderDestinationMarker()}

          {renderRouteLocations()}
        </GoogleMapReact>
      ) : (
        <StyledMapView.MapBackgroundContainer>
          <img src={EuropeImage} />
        </StyledMapView.MapBackgroundContainer>
      )}
    </StyledMapView.MapContainer>
  );
};
