import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox, Dialog, IconButton, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { filterPopupService } from '../../../../services/filter-popup.service';
import SearchIcon from '@material-ui/icons/Search';
import { AppContext, AppContextModel } from '../../../../app-context';
import { useTranslation } from 'react-i18next';
import { FILTER_TYPES } from '../../../../util/filters.util';
import { routeFiltersLocalStorageService } from '../../../../services/local-storage/route-filters-ls.service';
import SmileyIcon from '../../../../../assets/images/smiley.svg';
import { StyledFilterPopup } from './FilterPopup.styles';

export const FilterPopup = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<string>('');
  const [items, setItems] = useState<any | null>(null);
  const [filteredItems, setFilteredItems] = useState<any | null>(null);
  const [controls, setControls] = useState<any[] | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [buttonName, setButtonName] = useState<string>('CLEAR_ALL');
  const [filterString, setFilterString] = useState<string>('');

  const { appContextValue, setAppContextValue } = useContext(AppContext);

  const { t } = useTranslation();

  /*************************************************  setContext  *************************************************/
  const updateFilterContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
      filterUpdated: value,
    }));
  };

  /*************************************************  Logic  *************************************************/
  const itemsRef = React.useRef(items);
  const setMyItems = (data: any) => {
    itemsRef.current = data;
    setItems(data);
  };

  const handleClose = useCallback(() => {
    filterPopupService.closeFilterPopup();
  }, []);

  useEffect(() => {
    const sub = filterPopupService.controls.subscribe((data: any) => {
      setIsOpen(!!data);
      setFilterString('');

      if (data && data.items) {
        setFilterName(data.filterName);

        const clonedItems = data.items.map((obj: any) => ({ ...obj }));

        setMyItems(clonedItems);
        setFilteredItems(clonedItems);
        createControls(clonedItems);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  });

  useEffect(() => {
    if (items) {
      // enable/disable OK button every time items array changes
      const checkedItems = items.filter((item: any) => item.isChecked);
      !checkedItems.length ? setButtonName('SELECT_ALL') : setButtonName('CLEAR_ALL');
      setIsButtonDisabled(!checkedItems.length);
    }
  }, [items, setItems]);

  const createControls = (elements: any[]) => {
    const controlArray = elements.map((el: any) => {
      return (
        <StyledFilterPopup.ItemContainer
          key={el.uuid}
          style={!el.isVisible ? { display: 'none' } : { display: 'block' }}
        >
          <StyledFilterPopup.CheckboxAndNameContainer>
            {el.name}
            <Checkbox
              checked={el.isChecked}
              onChange={() => changeHandler(el.uuid, elements)}
              color="primary"
            />
          </StyledFilterPopup.CheckboxAndNameContainer>
          {el.provider ? (
            <StyledFilterPopup.ProviderContainer>{el.provider}</StyledFilterPopup.ProviderContainer>
          ) : null}
        </StyledFilterPopup.ItemContainer>
      );
    });
    setControls(controlArray);
  };

  const changeHandler = (uuid: string, passedItems: any[]) => {
    if (passedItems) {
      const clonedPassedItems = passedItems.map((obj: any) => ({ ...obj }));
      clonedPassedItems.forEach((el: any) => {
        if (el.uuid === uuid) {
          el.isChecked = !el.isChecked;
        }
      });
      createControls(clonedPassedItems);

      const clonedItems = itemsRef.current.map((obj: any) => ({ ...obj }));
      clonedItems.forEach((item: any) => {
        if (item.uuid === uuid) {
          item.isChecked = !item.isChecked;
        }
      });
      setMyItems(clonedItems);
    }
  };

  const filterItems = (event: any) => {
    const searchString = event.target.value.trim().toLowerCase();
    setFilterString(searchString);

    let filtered = [];
    if (searchString) {
      if (items) {
        filtered = items.filter((el: any) => {
          if (filterName === FILTER_TYPES.PAYMENT_SERVICES) {
            return (
              el.name.toLowerCase().indexOf(searchString) !== -1 ||
              el.provider.toLowerCase().indexOf(searchString) !== -1
            );
          } else {
            return el.name.toLowerCase().indexOf(searchString) !== -1;
          }
        });
        setFilteredItems(filtered);
        createControls(filtered);
      }
    } else {
      if (items && controls && items.length !== controls.length) {
        // reset filtered items and reset controls
        setFilteredItems(items);
        createControls(items);
      }
    }
  };

  const toggleSelectAll = () => {
    const clonedFilteredItems = filteredItems.map((obj: any) => ({ ...obj }));
    let clonedItems: any = [];
    if (buttonName === 'CLEAR_ALL') {
      clonedFilteredItems.forEach((item: any) => {
        item.isChecked = false;
      });
      setButtonName('SELECT_ALL');

      // update isChecked in items array for all checkboxes
      clonedItems = items.map((obj: any) => ({ ...obj }));
      clonedItems.forEach((item: any) => {
        item.isChecked = false;
      });
    } else {
      clonedFilteredItems.forEach((item: any) => {
        item.isChecked = true;
      });
      setButtonName('CLEAR_ALL');

      // update isChecked in items array for all checkboxes
      clonedItems = items.map((obj: any) => ({ ...obj }));
      clonedItems.forEach((item: any) => {
        item.isChecked = true;
      });
    }
    createControls(clonedFilteredItems);

    setMyItems(clonedItems);
  };

  const confirmSelectionHandler = () => {
    if (filterName === FILTER_TYPES.PAYMENT_SERVICES) {
      updateFilterContext('filterPaymentServices', items);

      routeFiltersLocalStorageService.setPaymentProviders(items);
    } else {
      updateFilterContext('filterChargingPointOperators', items);

      routeFiltersLocalStorageService.setChargingNetworks(items);
    }
    handleClose();
  };

  const deleteFilterStringHandler = () => {
    setFilterString('');
    setFilteredItems(items);
    createControls(items);
  };

  return (
    <Dialog
      open={isOpen}
      className="filterDialog"
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableBackdropClick={true}
    >
      <StyledFilterPopup.PopupHeader>
        <StyledFilterPopup.StyledH2>{t(filterName)}</StyledFilterPopup.StyledH2>
        <IconButton size="small" onClick={handleClose} data-cy="filters-dialog-close-button">
          <StyledFilterPopup.StyledCloseIcon />
        </IconButton>
      </StyledFilterPopup.PopupHeader>

      <StyledFilterPopup.InputContainer>
        <StyledFilterPopup.StyledInput
          placeholder={t('vehicleConfigurator.filters.search')}
          value={filterString}
          onChange={filterItems}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            filterString ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={deleteFilterStringHandler}
                  data-cy="filters-dialog-search-clear-button"
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }
          data-cy="filters-dialog-search"
          id="filters-dialog-search-input"
        />
      </StyledFilterPopup.InputContainer>

      <StyledFilterPopup.ControlsContainer>
        {controls?.length ? (
          controls
        ) : (
          <StyledFilterPopup.NoResultsContainer data-cy="filters-dialog-no-result">
            <img src={SmileyIcon} />
            <StyledFilterPopup.NoResultsText>
              {filterName === FILTER_TYPES.PAYMENT_SERVICES
                ? t('vehicleConfigurator.filters.noResultsProviders')
                : t('vehicleConfigurator.filters.noResultsNetworks')}
            </StyledFilterPopup.NoResultsText>
          </StyledFilterPopup.NoResultsContainer>
        )}
      </StyledFilterPopup.ControlsContainer>

      <StyledFilterPopup.ButtonsContainer>
        <StyledFilterPopup.StyledTextButton
          variant="outlined"
          onClick={toggleSelectAll}
          disabled={!!filterString}
          data-cy="filters-dialog-toggle-button"
        >
          {t(buttonName)}
        </StyledFilterPopup.StyledTextButton>
        <StyledFilterPopup.StyledPrimaryButton
          color="primary"
          variant="contained"
          onClick={confirmSelectionHandler}
          disabled={isButtonDisabled}
          data-cy="filters-dialog-okay-button"
        >
          {t('general.okay')}
        </StyledFilterPopup.StyledPrimaryButton>
      </StyledFilterPopup.ButtonsContainer>
    </Dialog>
  );
};
