/*************************************************  Imports  *************************************************/
import { useTranslation } from 'react-i18next';
import { checkSafari } from '../location-pin-popup/details/amenities/checkSafari';
import { StyledOriginalMarker } from './OriginalMarker.styles';

/*************************************************  Component  *************************************************/
export const OriginMarker = (props: { name: string; lat: Number; lng: Number }) => {
  const { t } = useTranslation();
  /*************************************************  Template  *************************************************/
  // className is only for finding this element easy in "InspectElement"
  return (
    <StyledOriginalMarker.OriginMarker className="originMarker">
      <StyledOriginalMarker.MarkerTooltip className={checkSafari() ? 'safari' : 'not-safari'}>
        <h5>{t(props.name)}</h5>
      </StyledOriginalMarker.MarkerTooltip>
    </StyledOriginalMarker.OriginMarker>
  );
};
