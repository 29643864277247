import styled from '@emotion/styled';
import { getVar } from '../../../theme/ui-variables/ui-variables';
import { Button, Tab, Tabs } from '@material-ui/core';
import { getColor } from '../../../theme/colors/colors';
import CloseIcon from '@material-ui/icons/Close';
import { getFont } from '../../../theme/fonts/fonts';

const OperatorHeading = styled.p`
  margin: 0 ${getVar('locationSpacing')};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${getVar('locationSpacing')};
  height: ${getVar('locationHeaderHeight')};
`;

const StyledH4 = styled.h4`
  width: 25rem;
  padding-right: 1.5rem;
  font-size: 1.8rem;
`;

const StyledCloseButton = styled(Button)`
  position: absolute !important;
  top: ${getVar('locationSpacing')};
  right: ${getVar('locationSpacing')};
  width: 4.5rem;
  height: 4.5rem;
  min-width: unset !important;
  background: ${getColor('surface')} !important;
  border-radius: ${getVar('borderRadius')} !important;
  z-index: 2;
  box-shadow: 0px 4px 12px ${getColor('shadow', 0.1)} !important;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${getColor('text')};
  width: 2rem !important;
  height: 2rem !important;
`;

const CoverPhotoContainer = styled.a`
  width: 100%;
  height: ${getVar('locationCoverPhotoWidth')};
  overflow: hidden;
  object-fit: cover;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.noPointer {
    cursor: default;
  }
`;

const ProfilePhotoContainer = styled.a`
  width: ${getVar('locationProfilePictureSize')};
  height: ${getVar('locationProfilePictureSize')};
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid ${getColor('surface')};
  box-shadow: 0px 4px 12px ${getColor('shadow', 0.1)};
  overflow: hidden;
  position: absolute;
  top: calc(
    ${getVar('locationCoverPhotoWidth')} - (${getVar('locationProfilePictureSize')} / 2 + 1px)
  );
  left: ${getVar('locationSpacing')};
  cursor: pointer;

  &.noPointer {
    cursor: default;
  }
`;

const Photo = styled.img`
  width: 100%;
`;
const ProfilePhoto = styled(Photo)`
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - ${getVar('locationCoverPhotoWidth')});
  padding-top: calc((${getVar('locationProfilePictureSize')} / 2 + 1px) + 1.5rem);
`;

const StyledTabs = styled(Tabs)`
  width: calc(${getVar('locationDialogWidth')} - (${getVar('locationSpacing')} * 2));
  margin: 2rem ${getVar('locationSpacing')} 0;

  & .MuiTabs-indicator {
    height: 4px;
    border-radius: 4px 4px 0 0;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiButtonBase-root.MuiTab-root {
    min-width: calc((${getVar('locationDialogWidth')} - (${getVar('locationSpacing')} * 2)) / 3);
    max-width: calc((${getVar('locationDialogWidth')} - (${getVar('locationSpacing')} * 2)) / 3);
    width: calc((${getVar('locationDialogWidth')} - (${getVar('locationSpacing')} * 2)) / 3);
    font-weight: 800;
    font-size: 1.4rem;
    text-transform: none;
    color: ${getColor('primary')};
  }
`;

const StyledTabPanel = styled.div`
  padding: 3rem ${getVar('locationSpacing')} 0;
  // 4.8rem -- default height of tabs -------- 3.5rem margin of  StyledTabs
  height: calc(100% - ${getVar('locationHeaderHeight')} - 4.8rem - 3.5rem);
  background-color: ${getColor('tabPanel')};
  overflow-x: hidden;
  overflow-y: auto;

  &:after {
    content: '';
    height: 3rem;
    width: 100%;
    display: block;
  }

  &.details {
    display: flex;
    flex-direction: column;

    &::after {
      display: none;
    }
  }
`;

const NumberOfStars = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 30px;
  color: ${getColor('text')};
  text-align: right;
`;

const NumberOfReviews = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  text-align: right;
`;

export const StyledLocationPinPopup = {
  OperatorHeading,
  Header,
  StyledH4,
  StyledCloseButton,
  StyledCloseIcon,
  CoverPhotoContainer,
  ProfilePhotoContainer,
  Photo,
  ContentContainer,
  StyledTabs,
  StyledTab,
  StyledTabPanel,
  NumberOfStars,
  NumberOfReviews,
  ProfilePhoto,
};
