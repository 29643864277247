import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';

const StyledHr = styled.hr`
  margin-top: 32px;
  border-top: 0.86px solid ${getColor('statusUnknownBackground')};
  border-bottom: 0;
`;

const StyledNoReviews = styled.h3`
  margin-top: 3rem;
  text-align: center;
`;

const WarningMessage = styled.h3`
  &.warning-message {
    color: ${getColor('errorMessage')};
    padding-bottom: 32px;
  }
`;

export const StyledCommunityInfo = { StyledHr, StyledNoReviews, WarningMessage };
