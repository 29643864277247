/*************************************************  Imports  *************************************************/
import WhiteLocationIcon from '../../../../../assets/images/marker/marker-white.svg';
import BlueLocationIcon from '../../../../../assets/images/marker/marker-blue.svg';
import { StyledLocationMarker } from './LocationMarker.styles';

/*************************************************  Component  *************************************************/
export const LocationMarker = (props: {
  lat: Number | undefined;
  lng: Number | undefined;
  id: string;
  isClicked: boolean;
  toggleSelectedLocation: any;
}) => {
  /*************************************************  Template  *************************************************/
  // className is only for finding this element easy in "InspectElement"
  return (
    <StyledLocationMarker.LocationContainer
      className="locationMarker"
      onClick={() => {
        props.toggleSelectedLocation(props.id);
      }}
    >
      <StyledLocationMarker.StyledImage
        src={props.isClicked ? BlueLocationIcon : WhiteLocationIcon}
        alt="White Location Marker Icon"
      />
    </StyledLocationMarker.LocationContainer>
  );
};
