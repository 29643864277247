import { useCallback } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useDataTrackingEnabled } from 'core/hooks/useDataTrackingEnabled';

type AnalyticsEventCategory =
  | 'routeCalculation'
  | 'carAndRouteSettings'
  | 'carAndRouteSettings-granular'
  | 'chargingStations'
  | 'general';

interface AnalyticsEventParams {
  name?: string;
  category: AnalyticsEventCategory;
  action: string;
}

export const useAppAnalytics = () => {
  const { trackEvent: externalLibraryTrackEvent } = useMatomo();
  const [dataTrackingEnabled] = useDataTrackingEnabled();

  const trackEvent = useCallback(
    ({ name, category, action }: AnalyticsEventParams) => {
      if (!dataTrackingEnabled) {
        return;
      }

      externalLibraryTrackEvent({
        name,
        category,
        action,
      });
    },
    [externalLibraryTrackEvent, dataTrackingEnabled]
  );

  return {
    trackEvent,
  };
};
