/*************************************************  Imports  *************************************************/
import styled from '@emotion/styled';

import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

/*************************************************  Styles  *************************************************/
const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${getColor('surface')};
  padding-bottom: ${getVar('sidebarPadding')};
  position: absolute;
  transition: 0.2s;
  left: -100%;
  top: 0;

  &.opened {
    left: 0;
  }
`;

const OverlayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${getVar('sidebarHeaderHeight')};
  padding: 0 ${getVar('sidebarPadding')};
`;

const LogoIcon = styled.img`
  height: 3rem;
  user-select: none;
`;

const OverlayContent = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 ${getVar('sidebarPadding')};
  height: calc(100% - ${getVar('sidebarHeaderHeight')});
`;

/*************************************************  Export  *************************************************/
export const ConfiguratorStyles = {
  OverlayContainer,
  OverlayHeader,
  LogoIcon,
  OverlayContent,
};
