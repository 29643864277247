import { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppAnalytics } from 'analytics';
import { getVar } from 'core/theme/ui-variables/ui-variables';
import { useRouteStops } from '../useRouteStops';

const StyledButton = styled(Button)`
  margin-top: -3rem; /* TODO: restructure parent to avoid negative margin */
  border-radius: ${getVar('borderRadius')};
  font-weight: 700;
  font-size: 1.6rem;
  height: 5.6rem;
`;

export const AddStopButton = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAppAnalytics();
  const { addEmptyStop } = useRouteStops();

  const handleClick = useCallback(() => {
    addEmptyStop();
    trackEvent({ category: 'routeCalculation', action: 'Add Trip Stop button pressed' });
  }, [trackEvent, addEmptyStop]);

  return (
    <StyledButton variant="text" size="large" color="primary" fullWidth onClick={handleClick}>
      {t('stops.addStopButtonText')}
    </StyledButton>
  );
};
