import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { getColor } from '../../../../theme/colors/colors';
import { Button, Input } from '@material-ui/core';
import { getVar } from '../../../../theme/ui-variables/ui-variables';

const PopupHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-right: 0.6rem;
`;

const StyledH2 = styled.h2`
  width: 100%;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${getColor('textLightest')};
  width: 2rem !important;
  height: 2rem !important;
`;

const ControlsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const ItemContainer = styled.div`
  &:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

const CheckboxAndNameContainer = styled.div`
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  justify-content: space-between;

  & .MuiCheckbox-root {
    padding: 0.3rem;
  }
`;

const InputContainer = styled.div`
  padding-right: 1.2rem;
`;

const StyledInput = styled(Input)`
  &.MuiInput-root.MuiInput-underline {
    width: 100%;
    min-height: 56px;
    border: 1px solid ${getColor('primaryDisabled')};
    padding-left: 12px;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-radius: 1.8rem;
    font-size: 1.4rem;

    & .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
      & .MuiIconButton-sizeSmall {
        margin-right: 1rem;

        svg {
          width: 0.8em;
          height: 0.8em;
          color: ${getColor('text')};
        }
      }
    }

    &:hover {
      border: 1px solid ${getColor('primary')};
    }
    &:active {
      border: 1px solid ${getColor('primary')};
    }
    &:focus {
      border: 1px solid ${getColor('primary')};
    }
    &:before {
      border-bottom: 0;
    }
    &:after {
      border-bottom: 0;
    }
    & .MuiInputBase-input {
      padding: 0;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: 0;
    }
    svg {
      color: ${getColor('textLightest')};
    }
    input {
      &::placeholder {
        color: ${getColor('textLightest')};
      }
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.2rem;
  padding-right: 1.2rem;
`;

const StyledTextButton = styled(Button)`
  &.MuiButtonBase-root.MuiButton-outlined {
    border: 1px solid ${getColor('primaryContrast')};
    background-color: ${getColor('primaryContrast')};
    border-radius: 1.8rem;
    width: 48%;
    height: 48px;
    justify-content: center;
    color: ${getColor('text')};
    text-transform: capitalize;
    font-size: 1.6rem;
    font-weight: 700;
    text-decoration: underline;

    &:disabled {
      color: ${getColor('textLight')} !important;
    }
  }
`;

const StyledPrimaryButton = styled(Button)`
  &.MuiButtonBase-root.MuiButton-contained {
    border-radius: ${getVar('borderRadius')} !important;
    padding: 1.5rem !important;
    width: 48%;
    transition: 0.3s;
    width: 48%;
    height: 48px;
    text-transform: none;
    font-size: 1.6rem;
    font-weight: 700;
    box-shadow: none;

    &:disabled {
      background-color: ${getColor('primaryDisabled')} !important;
      color: ${getColor('primaryContrast')} !important;
    }
  }
`;

const ProviderContainer = styled.div`
  font-size: 12px;
  color: ${getColor('textLightest')};
`;

const NoResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 60px 54px;
`;

const NoResultsText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: ${getColor('text')};
  margin-top: 50px;
`;

export const StyledFilterPopup = {
  PopupHeader,
  StyledH2,
  StyledCloseIcon,
  ControlsContainer,
  ItemContainer,
  CheckboxAndNameContainer,
  InputContainer,
  StyledInput,
  ButtonsContainer,
  StyledTextButton,
  StyledPrimaryButton,
  ProviderContainer,
  NoResultsContainer,
  NoResultsText,
};
