import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { getVar } from '../../theme/ui-variables/ui-variables';

const StyledH2 = styled.h2`
  margin-top: 3rem;
`;

const StyledText = styled.p`
  font-size: 1.5rem;
  margin-top: 1rem;
  text-align: justify;
`;

const StyledButton = styled(Button)`
  border-radius: ${getVar('borderRadius')} !important;
  padding: 1.5rem 3.5rem !important;
  box-shadow: none !important;
  margin-top: 3rem !important;
  max-width: 32rem;
`;

export const StyledGoogleMapsTCDialog = {
  StyledH2,
  StyledText,
  StyledButton,
};
