import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { axiosData } from '../../../api/axios-data';
import { LocationResponseModel } from '../../models/locations/location-response.model';
import { chargingStationService } from '../../../api/api';

export const getAllChargingStations = (args: any): Promise<LocationResponseModel> => {
  return axiosData<LocationResponseModel>(
    (): AxiosObservable<LocationResponseModel> => {
      let params = null;
      if (args.connector) {
        params = new URLSearchParams([['connector', args.connector]]);
      }
      return chargingStationService.post('/charging-stations', args.requestBody, {
        params,
      });
    }
  )().toPromise();
};
