import { Subject, Observable } from 'rxjs';

const alert = new Subject();

const showDialog = (): void => {
  alert.next(true);
};

export const blockedLocationService: {
  blockedDialog: Observable<boolean>;
  showDialog(): void;
} = {
  blockedDialog: alert.asObservable() as Observable<boolean>,
  showDialog,
};
