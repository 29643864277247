import React, { useContext, useEffect } from 'react';
import { AppContext, AppContextModel } from '../../../app-context';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import moment from 'moment';
import * as _ from 'lodash';
import { Box } from '@material-ui/core';
import { toKilometers } from 'core/util/to-kilometers';
import { toKilowatts } from 'core/util/to-kilowatts';
import { getColor } from '../../../theme/colors/colors';
import POI from '../../../../assets/images/poi.svg';
import { TripStopModel } from '../../../models/planner/trip-stop.model';
import { DurationFormatUtil } from '../../../util/duration-format.util';
import { getWeatherIcon } from '../../../util/weather.util';
import { getAmenityIconByCategory } from '../../../util/amenity.util';
import Auxiliary from '../../../util/hoc/Auxiliary';
import { locationPinInfoService } from '../../../services/location-dialog.service';
import { StyledRouteLocationList } from './RouteLocationList.styles';
import BoltBlackIcon from '../../../../assets/images/BoltBlack.svg';
import { CarbonFootprintLabel } from './carbon-footprint-label/CarbonFootprintLabel';
import { GeoStopListItem } from './route-location-list-item/GeoStopListItem';
import { WeatherInfo } from './route-location-list-item/weather-info/WeatherInfo';

const createBolts = (numberOfBolts: number) => {
  return _.times(numberOfBolts).map((value: number) => {
    return <StyledRouteLocationList.StyledBolt key={value} src={BoltBlackIcon} />;
  });
};

export const RouteLocationList = () => {
  const { appContextValue, setAppContextValue } = useContext(AppContext);

  const { t } = useTranslation();

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  Logic  *************************************************/
  useEffect(() => {
    let card;
    const allCards: any[] = Array.from(document.getElementsByClassName('location-card'));
    if (appContextValue.selectedMarker?.locationId) {
      card = document.getElementById(appContextValue.selectedMarker.locationId);

      if (appContextValue.selectedMarker.show) {
        // select card
        if (card) {
          card.style.borderColor = getColor('primary');
        }

        // deselect all others
        const filtered = allCards.filter(
          (card) => card.id !== appContextValue.selectedMarker.locationId
        );
        filtered.forEach((card) => {
          card.style.borderColor = getColor('statusUnknownBackground');
        });
      } else {
        // deselect card
        if (card) {
          card.style.borderColor = getColor('statusUnknownBackground');
        }
      }
    }
  }, [appContextValue.selectedMarker]);

  const cardClickHandler = (locationId: string) => {
    let selectedCard = document.getElementById(locationId);
    if (selectedCard) {
      if (selectedCard.style.borderColor === getColor('primary')) {
        // if it's already selected, deselect it
        locationPinInfoService.closeLocationInfoPopup();

        selectedCard.style.borderColor = getColor('statusUnknownBackground');
      } else {
        // if it's not selected, select it
        locationPinInfoService.closeLocationInfoPopup();

        // NOTE: charging_station can be null if trip stop is a simple geo position
        const clickedLocation = appContextValue.route.trip_stops.find(
          (ts: any) => ts.charging_station?.id === locationId
        );

        if (clickedLocation) {
          updateContext('routeSelectedStationId', locationId);
          locationPinInfoService.showLocationInfoPopup({
            location: clickedLocation.charging_station,
          });
        }

        const allCards: any[] = Array.from(document.getElementsByClassName('location-card'));
        // select card
        if (selectedCard) {
          selectedCard.style.borderColor = getColor('primary');
        }

        // deselect all others
        const filtered = allCards.filter((card) => card.id !== locationId);
        filtered.forEach((card) => {
          card.style.borderColor = getColor('statusUnknownBackground');
        });
      }
    }
  };

  const chargingStations = appContextValue.route.trip_stops.map(
    (stop: TripStopModel, index: number) => {
      if (!stop.charging_station) {
        // if no charging station is available, this trip stop is a user-selected arbitrary point on the map
        return (
          <GeoStopListItem
            tripStop={stop}
            tripLeg={appContextValue.route?.trip_legs[index + 1]}
            index={index}
            onClick={(id) => cardClickHandler(id)}
          />
        );
      }

      let amenities = null;
      let unique;
      let theRest = 0;
      if (stop.charging_station?.amenities && stop.charging_station?.amenities.length) {
        unique = Array.from(new Set(stop.charging_station?.amenities.map((item) => item.category)));
        amenities = unique.map((category: string) => {
          return (
            <StyledRouteLocationList.AmenityImage
              src={getAmenityIconByCategory(category)}
              key={category}
            />
          );
        });

        if (amenities.length > 7) {
          amenities = amenities.slice(0, 7);
          theRest = unique.length - amenities.length;
        }
      }

      return (
        <Auxiliary key={stop.charging_station.id}>
          <StyledRouteLocationList.LocationCard
            onClick={() => cardClickHandler(stop.charging_station.id)}
            className="location-card"
            id={stop.charging_station.id}
            data-cy={'route-list-location-card-' + stop.charging_station.id}
          >
            <StyledRouteLocationList.ImageAndNameContainer>
              <img src={POI} alt="charging-station-icon" />
              <StyledRouteLocationList.StyledH5>
                {stop.charging_station.charging_point_operator.name}
              </StyledRouteLocationList.StyledH5>
            </StyledRouteLocationList.ImageAndNameContainer>
            <StyledRouteLocationList.AddressContainer>
              {stop.charging_station.address},&nbsp;
              {stop.charging_station.city}
              {/* ,&nbsp; */}
              {/* {stop.charging_station.country} */}
            </StyledRouteLocationList.AddressContainer>
            <StyledRouteLocationList.LocationListInfoSoc>
              <StyledRouteLocationList.StyledDark>
                {round(stop.soc_start) + '%'}
              </StyledRouteLocationList.StyledDark>
              <StyledRouteLocationList.StyledDark>
                {t('routeLocationList.to')}
              </StyledRouteLocationList.StyledDark>
              <StyledRouteLocationList.StyledGreen>
                {round(stop.soc_end) + '%'}
              </StyledRouteLocationList.StyledGreen>
              <StyledRouteLocationList.StyledDark>
                {t('routeLocationList.in')}
              </StyledRouteLocationList.StyledDark>
              <StyledRouteLocationList.StyledDark>
                {DurationFormatUtil.formatTime(stop.charging_time)}
              </StyledRouteLocationList.StyledDark>
              <StyledRouteLocationList.ChargingSpeed>
                {toKilowatts(stop.charging_max_power) < 50
                  ? createBolts(1)
                  : toKilowatts(stop.charging_max_power) > 99
                  ? createBolts(3)
                  : createBolts(2)}
                {toKilowatts(stop.charging_max_power) + ' kW'}
              </StyledRouteLocationList.ChargingSpeed>
            </StyledRouteLocationList.LocationListInfoSoc>
            {amenities && amenities.length ? (
              <StyledRouteLocationList.AmenityList>
                {amenities}
                {theRest ? (
                  <StyledRouteLocationList.FakeAmenity>
                    <span>{'+' + theRest}</span>
                  </StyledRouteLocationList.FakeAmenity>
                ) : null}
              </StyledRouteLocationList.AmenityList>
            ) : null}
          </StyledRouteLocationList.LocationCard>
          <StyledRouteLocationList.Location>
            <StyledRouteLocationList.LocationNoCard>
              <WeatherInfo tripLeg={appContextValue.route?.trip_legs[index + 1]} />
              <StyledRouteLocationList.LocationListInfoWrapper>
                <StyledRouteLocationList.LocationListInfo>
                  {toKilometers(appContextValue.route?.trip_legs[index + 1].distance) + ' km'}
                </StyledRouteLocationList.LocationListInfo>
                <StyledRouteLocationList.HorizontalDotSeparator />
                <StyledRouteLocationList.LocationListInfo>
                  {DurationFormatUtil.formatTime(
                    appContextValue.route?.trip_legs[index + 1].duration
                  )}
                </StyledRouteLocationList.LocationListInfo>
              </StyledRouteLocationList.LocationListInfoWrapper>
              <StyledRouteLocationList.LocationListInfo>
                {round(appContextValue.route.trip_legs[index + 1].soc_start) + '%'}
                {t('routeLocationList.to')}
                {round(appContextValue.route.trip_legs[index + 1].soc_end) + '%'}
              </StyledRouteLocationList.LocationListInfo>
            </StyledRouteLocationList.LocationNoCard>
          </StyledRouteLocationList.Location>
        </Auxiliary>
      );
    }
  );

  return (
    <StyledRouteLocationList.ListContainer>
      <StyledRouteLocationList.ListHeader>
        <h1>
          {t('routeLocationList.title', {
            destination: appContextValue.destination?.name,
          })}
        </h1>
        <h5>
          {t('routeLocationList.totalTime', {
            totalTime: DurationFormatUtil.formatTime(
              appContextValue.route?.trip_stats.total_duration
            ),
          })}
        </h5>
        <StyledRouteLocationList.LocationListInfoWrapper>
          <StyledRouteLocationList.LocationListInfoHeader>
            {t('routeLocationList.driving', {
              drivingTime: DurationFormatUtil.subtractAndFormatTime(
                appContextValue.route?.trip_stats.total_duration,
                appContextValue.route?.trip_stats.total_charge_duration
              ),
            })}
          </StyledRouteLocationList.LocationListInfoHeader>
          <StyledRouteLocationList.HorizontalDotSeparator />
          <StyledRouteLocationList.LocationListInfoHeader>
            {t('routeLocationList.distance', {
              distance: toKilometers(appContextValue.route?.trip_stats.total_distance),
            })}
          </StyledRouteLocationList.LocationListInfoHeader>
        </StyledRouteLocationList.LocationListInfoWrapper>
        <StyledRouteLocationList.LocationListInfoWrapper>
          <StyledRouteLocationList.LocationListInfoHeader>
            {t('routeLocationList.charging', {
              chargingTime: DurationFormatUtil.formatTime(
                appContextValue.route?.trip_stats.total_charge_duration
              ),
            })}
          </StyledRouteLocationList.LocationListInfoHeader>
          <StyledRouteLocationList.HorizontalDotSeparator />
          <StyledRouteLocationList.LocationListInfoHeader>
            {t('routeLocationList.stops', {
              stops: appContextValue.route?.trip_stops.length,
            })}
          </StyledRouteLocationList.LocationListInfoHeader>
        </StyledRouteLocationList.LocationListInfoWrapper>

        <Box mt={3}>
          <CarbonFootprintLabel
            carbonSavingsGrams={appContextValue?.route?.trip_stats.total_co2_savings_g}
          />
        </Box>
      </StyledRouteLocationList.ListHeader>

      <StyledRouteLocationList.ListBody>
        <StyledRouteLocationList.LocationList>
          <StyledRouteLocationList.Location className="departure">
            <h3>
              {t('routeLocationList.departure')}&nbsp;
              <span>
                {moment
                  .utc(appContextValue.route?.trip_legs[0].time_start_location)
                  .local()
                  .format('HH:mm')}
              </span>
            </h3>
            <StyledRouteLocationList.LocationListInfo>
              {t(appContextValue.origin?.name)}
            </StyledRouteLocationList.LocationListInfo>
            <StyledRouteLocationList.YetAnotherContainer>
              {appContextValue.route?.trip_legs[0].weather_start_location?.weather_conditions
                ?.length ||
              appContextValue.route?.trip_legs[0].weather_start_location?.temperature ? (
                <StyledRouteLocationList.WeatherContainer>
                  {appContextValue.route?.trip_legs[0].weather_start_location?.weather_conditions
                    ?.length ? (
                    <StyledRouteLocationList.WeatherIcon>
                      <img
                        src={getWeatherIcon(
                          appContextValue.route?.trip_legs[0].weather_start_location,
                          appContextValue.route?.trip_legs[0].time_start_location
                        )}
                        alt="weather-icon"
                      />
                    </StyledRouteLocationList.WeatherIcon>
                  ) : null}
                  {appContextValue.route?.trip_legs[0].weather_start_location?.temperature ? (
                    <StyledRouteLocationList.WeatherTemperature
                      className={
                        !appContextValue.route?.trip_legs[0].weather_start_location
                          ?.weather_conditions?.length
                          ? 'temperature-bottom-margin'
                          : ''
                      }
                    >
                      {appContextValue.route?.trip_legs[0].weather_start_location?.temperature +
                        '°C'}
                    </StyledRouteLocationList.WeatherTemperature>
                  ) : null}
                </StyledRouteLocationList.WeatherContainer>
              ) : null}
              <StyledRouteLocationList.LocationListInfoOuterWrapper>
                <StyledRouteLocationList.LocationListInfoWrapper>
                  <StyledRouteLocationList.LocationListInfo>
                    {DurationFormatUtil.formatTime(appContextValue.route?.trip_legs[0].duration)}
                  </StyledRouteLocationList.LocationListInfo>
                  <StyledRouteLocationList.HorizontalDotSeparator />
                  <StyledRouteLocationList.LocationListInfo>
                    {t('routeLocationList.distance', {
                      distance: toKilometers(appContextValue.route?.trip_legs[0].distance),
                    })}
                  </StyledRouteLocationList.LocationListInfo>
                </StyledRouteLocationList.LocationListInfoWrapper>
              </StyledRouteLocationList.LocationListInfoOuterWrapper>
              <StyledRouteLocationList.LocationListInfo>
                {round(appContextValue.route?.trip_legs[0].soc_start) + '%'}
                {t('routeLocationList.to')}
                {round(appContextValue.route?.trip_legs[0].soc_end) + '%'}
              </StyledRouteLocationList.LocationListInfo>
            </StyledRouteLocationList.YetAnotherContainer>
          </StyledRouteLocationList.Location>

          {chargingStations}

          <StyledRouteLocationList.Location className="arrival">
            <h3>
              {t('routeLocationList.arrival')}&nbsp;
              <span>
                {moment
                  .utc(
                    appContextValue.route?.trip_legs[appContextValue.route?.trip_legs.length - 1]
                      .time_end_location
                  )
                  .local()
                  .format('HH:mm')}
              </span>
            </h3>
            <StyledRouteLocationList.LocationListInfo>
              {appContextValue.destination?.name}
            </StyledRouteLocationList.LocationListInfo>
          </StyledRouteLocationList.Location>
        </StyledRouteLocationList.LocationList>
      </StyledRouteLocationList.ListBody>
    </StyledRouteLocationList.ListContainer>
  );
};
