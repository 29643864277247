import { onlyUnique } from '../../../../util/onlyUnique.util';

/*************************************************  Types  *************************************************/
export interface SocSelectionRangeValueModel {
  min: number;
  max: number;
  step: number;
  default: number;
}

interface SocSelectionRangeModel {
  CHARGE_MIN: SocSelectionRangeValueModel;
  CHARGE_MAX: SocSelectionRangeValueModel;
  CHARGE_PENALTY: SocSelectionRangeValueModel;
  SOC_ORIGIN: SocSelectionRangeValueModel;
  SOC_DESTINATION: SocSelectionRangeValueModel;
}

export type SocControlNameModel =
  | 'charge_min'
  | 'charge_max'
  | 'charge_penalty'
  | 'soc_origin'
  | 'soc_destination';

/*************************************************  Default / Range Values  *************************************************/
export const SOC_SELECTION_RANGE_VALUES: SocSelectionRangeModel = {
  CHARGE_MIN: {
    min: 5,
    max: 80,
    step: 5,
    default: 20,
  },
  CHARGE_MAX: {
    min: 20,
    max: 100,
    step: 5,
    default: 80,
  },
  CHARGE_PENALTY: {
    min: 5,
    max: 30,
    step: 5,
    default: 5,
  },
  SOC_ORIGIN: {
    min: 5,
    max: 100,
    step: 1,
    default: 80,
  },
  SOC_DESTINATION: {
    min: 5,
    max: 100,
    step: 1,
    default: 20,
  },
};

/*************************************************  Get option values - DROPDOWN  *************************************************/
export const getSocDropdownOptions = (
  defaultValues: SocSelectionRangeValueModel,
  addedOptimalValue?: number | null
): number[] => {
  const optionsArray: number[] = [];

  for (let i = defaultValues.min; i <= defaultValues.max; i = i + defaultValues.step) {
    optionsArray.push(i);
  }

  if (addedOptimalValue) {
    optionsArray.push(addedOptimalValue);

    optionsArray.sort((a, b) => a - b);
  }

  return optionsArray.filter(onlyUnique);
};

/*************************************************  Disabled options filter  *************************************************/
export const filterChargeMinOptionDisabled = (value: number, selectedChargeMaxValue: number) => {
  return !(value < selectedChargeMaxValue);
};

export const filterChargeMaxOptionDisabled = (value: number, selectedChargeMinValue: number) => {
  return !(value > selectedChargeMinValue);
};
