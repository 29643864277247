import { useState, useEffect } from 'react';

// Resource: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export default function useDebounce(value: string, delay: number, doACall: boolean) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (doACall) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  return debouncedValue;
}
