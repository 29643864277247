import { LocationFullModel } from './models/locations-full/location-full.model';
import { PlannerResponseModel } from './models/planner/planner-response.model';
import { createContext } from 'react';
import { FilterPowerTypeModel } from './models/power-type/filter-power-type.model';

export interface AppContextModel {
  map: any;
  origin: {
    name: string;
    lat: number;
    lng: number;
  } | null;
  destination: {
    name: string;
    lat: number;
    lng: number;
  } | null;
  route: PlannerResponseModel | null;
  usersLocation: {
    lat: number;
    lng: number;
  } | null;
  filterChargingPointOperators: any[] | null;
  filterPaymentServices: any[] | null;
  filterUpdated: any[] | null;
  filterPowerType: FilterPowerTypeModel | null;
  filterPowerTypeUpdated: FilterPowerTypeModel | null;
  googleMapsTC: boolean;
  moovilityStation: LocationFullModel | null;
  manufacturer: string | '';
  routeSelectedStationId: string | '';
  selectedMarker: any | null;
}

export const AppContext = createContext<any>(null);
