/*************************************************  Imports  *************************************************/
import { useContext, useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Lottie, { Options } from 'react-lottie';
import animationData from '../../../assets/lotties/googleMapsDialogAnimation.json';

import { AppContext, AppContextModel } from '../../app-context';
import { agreedToGoogleMapsLocalStorageService } from '../../services/local-storage/google-maps-ls.service';
import { StyledGoogleMapsTCDialog } from './GoogleMapsTCDialog.styles';

/*************************************************  Component  *************************************************/
export const GoogleMapsTCDialog = () => {
  /*************************************************  State  *************************************************/
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [t] = useTranslation();

  const { appContextValue, setAppContextValue } = useContext(AppContext);

  const animationOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  Logic  *************************************************/
  const handleClose = () => {
    agreedToGoogleMapsLocalStorageService.setAgreedToGoogleMaps();
    setIsOpen(false);
    updateContext('googleMapsTC', true);
  };

  /*************************************************  Use Effect  *************************************************/
  useEffect(() => {
    if (!agreedToGoogleMapsLocalStorageService.isAgreedToGoogleMapsSet()) {
      setIsOpen(true);
    }
  });

  /*************************************************  Template  *************************************************/
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="googleMapsTCDialog"
      disableBackdropClick={true}
    >
      <Lottie options={animationOptions} height={100} width={100} />

      <StyledGoogleMapsTCDialog.StyledH2>
        {t('googleDialog.title')}
      </StyledGoogleMapsTCDialog.StyledH2>
      <StyledGoogleMapsTCDialog.StyledText>
        {t('googleDialog.message1')}
      </StyledGoogleMapsTCDialog.StyledText>
      <StyledGoogleMapsTCDialog.StyledText>
        {t('googleDialog.message2')}
      </StyledGoogleMapsTCDialog.StyledText>
      <StyledGoogleMapsTCDialog.StyledButton
        color="primary"
        variant="contained"
        onClick={handleClose}
        fullWidth
        data-cy="google-tc-agree-button"
      >
        <h4>{t('googleDialog.iAgree')}</h4>
      </StyledGoogleMapsTCDialog.StyledButton>
    </Dialog>
  );
};
