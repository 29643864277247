import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { getColor } from '../../../theme/colors/colors';

const PopupHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const StyledH4 = styled.h4`
  width: 100%;
  padding-right: 1.5rem;
  margin: 0.5rem 0 1rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${getColor('text')};
  width: 2rem !important;
  height: 2rem !important;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${getColor('label')};
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const StyledLabel = styled.div`
  color: ${getColor('text')};
  width: 40%;
  font-weight: 600;
`;

const StyledValue = styled.div`
  width: 60%;
`;

export const StyledDevStatsPopup = {
  PopupHeader,
  StyledH4,
  StyledCloseIcon,
  StyledRow,
  StyledLabel,
  StyledValue,
};
