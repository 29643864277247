import React from 'react';
import PhoneIcon from '../../../../../../assets/images/phone.svg';
import EmailIcon from '../../../../../../assets/images/mail.svg';
import ExternalLinkIcon from '../../../../../../assets/images/external-link.svg';
import { useAppAnalytics } from 'analytics';
import { StyledImprint } from '../Imprint.styles';

const ImprintEn = () => {
  const { trackEvent } = useAppAnalytics();

  const performTrackEvent = (currentEventName: string) => {
    trackEvent({ action: 'Imprint - ' + currentEventName, category: 'general' });
  };
  return (
    <StyledImprint.StyledDiv>
      <StyledImprint.SubtitleContainer>
        <h4>Information according to § 5 para. 1 of the Telemedia Act:</h4>
      </StyledImprint.SubtitleContainer>
      <StyledImprint.Divider />
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Provider name:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>ElectricRoutes GmbH</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Address:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>
          <div>Heilbronner Str. 86</div>
          <div>70191 Stuttgart</div>
          <div>Deutschland</div>
        </StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>
          Authorized representative managing director:
        </StyledImprint.StyledH5>
        <StyledImprint.StyledText>Andreas Bisenius</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Registry Court:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>Amtsgericht Stuttgart</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Register number:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>HRB 780686</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Contact details:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>
          <StyledImprint.StyledMiniRow>
            <StyledImprint.StyledIcon src={EmailIcon} />
            <StyledImprint.StyledLink
              href={'mailto:contact@electricroutes.com'}
              onClick={() => performTrackEvent('mailto:contact@electricroutes.com')}
            >
              contact@electricroutes.com
            </StyledImprint.StyledLink>
          </StyledImprint.StyledMiniRow>
          <StyledImprint.StyledMiniRow>
            <StyledImprint.StyledIcon src={PhoneIcon} />
            <StyledImprint.StyledLink
              href={'tel:+49 711 252 749-0'}
              onClick={() => performTrackEvent('tel:+49 711 252 749-0')}
            >
              +49 711 252 749-0
            </StyledImprint.StyledLink>
          </StyledImprint.StyledMiniRow>
          <StyledImprint.StyledMiniRow>
            <StyledImprint.StyledIcon src={ExternalLinkIcon} />
            <StyledImprint.StyledLink
              href={'https://www.electricroutes.com'}
              target="_blank"
              onClick={() => performTrackEvent('https://www.electricroutes.com')}
              rel="noopener noreferrer"
            >
              https://www.electricroutes.com
            </StyledImprint.StyledLink>
          </StyledImprint.StyledMiniRow>
        </StyledImprint.StyledText>
      </StyledImprint.Row>

      <StyledImprint.SubtitleContainer>
        <h4>Consumer Dispute Resolution</h4>
      </StyledImprint.SubtitleContainer>
      <StyledImprint.Divider />
      <StyledImprint.StyledText2>
        <p>
          The European Commission provides an online platform for online dispute resolution, which
          can be found at&nbsp;
          <StyledImprint.StyledLink
            href={'http://ec.europa.eu/consumers/odr/'}
            onClick={() => performTrackEvent('http://ec.europa.eu/consumers/odr/')}
            target="_blank"
            rel="noopener noreferrer"
          >
            http://ec.europa.eu/consumers/odr/.
          </StyledImprint.StyledLink>
        </p>
        <br />
        <p>
          ElectricRoutes GmbH is neither willing nor obliged to participate in dispute resolution
          proceedings before a consumer arbitration board.
        </p>
        <br />
        <p>
          Additional information on professional liability insurance pursuant to Section 2 (1) No.
          11 of the Ordinance on Information Duties for Service Providers (DL-InfoV):
        </p>
      </StyledImprint.StyledText2>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Name and address of the insurer:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>
          <div>AIG Europe S.A.</div>
          <div>Direktion für Deutschland</div>
          <div>Neue Mainzer Straße 46 – 50</div>
          <div>60311 Frankfurt</div>
        </StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Spatial scope:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>Worldwide insurance coverage</StyledImprint.StyledText>
      </StyledImprint.Row>

      <StyledImprint.SubtitleContainer>
        <h4>Copyright notice</h4>
      </StyledImprint.SubtitleContainer>
      <StyledImprint.Divider />
      <StyledImprint.StyledText2>
        <p>
          The content available on our website is generally subject to copyright protection. Any
          unauthorized use (in particular copying, editing or distribution) of this
          copyright-protected content is therefore prohibited.
        </p>
      </StyledImprint.StyledText2>
    </StyledImprint.StyledDiv>
  );
};

export default ImprintEn;
