import { Subject, Observable } from 'rxjs';
import { LocationPinPopupModel } from '../models/locations-full/location-pin-popup.model';

const alert = new Subject();

const showLocationInfoPopup = (info: LocationPinPopupModel): void => {
  alert.next(info);
};

const closeLocationInfoPopup = (): void => {
  alert.next(null);
};

export const locationPinInfoService: {
  locationPinInfo: Observable<LocationPinPopupModel | null>;
  showLocationInfoPopup(info: LocationPinPopupModel): void;
  closeLocationInfoPopup(): void;
} = {
  locationPinInfo: alert.asObservable() as Observable<LocationPinPopupModel | null>,
  showLocationInfoPopup,
  closeLocationInfoPopup,
};
