import { FunctionComponent } from 'react';
import { ReactComponent as LeafIcon } from 'assets/images/leaf.svg';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { getColor } from 'core/theme/colors/colors';

const OutlinedContainer = styled.div`
  padding: 0.5rem 1.1rem; // subtract 1px from padding specified by design to accommodate borders
  border: 1px solid ${getColor('carbonFootprintLabel')}; // outline + radius not supported on Safari, stick to border
  color: ${getColor('carbonFootprintLabel')};
  border-radius: 1.2rem;
  display: inline-flex;
`;

const InfoText = styled.div`
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 0.4rem;
`;

interface CarbonFootprintLabelProps {
  carbonSavingsGrams: number;
}

export const CarbonFootprintLabel: FunctionComponent<CarbonFootprintLabelProps> = ({
  carbonSavingsGrams,
}) => {
  const { t } = useTranslation();
  const decimalPlaces = carbonSavingsGrams < 1000 ? 1 : 0; // super short trips may save less than 1kg, so show 0.6kg, otherwise round to, e.g. 57kg
  const totalKilograms = (carbonSavingsGrams / 1000).toFixed(decimalPlaces);

  return (
    <OutlinedContainer>
      <LeafIcon />

      <InfoText>
        {t('routeLocationList.carbonFootprintLabel', {
          totalKilograms,
        })}
      </InfoText>
    </OutlinedContainer>
  );
};
