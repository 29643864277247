import { Subject, Observable } from 'rxjs';
import { PlannerResponseModel } from '../models/planner/planner-response.model';

const alert = new Subject();

const showDevStatsPopup = (info: PlannerResponseModel): void => {
  alert.next(info);
};

const closeDevStatsPopup = (): void => {
  alert.next(null);
};

export const devStatsPopupService: {
  routeInfo: Observable<PlannerResponseModel | null>;
  showDevStatsPopup(info: PlannerResponseModel): void;
  closeDevStatsPopup(): void;
} = {
  routeInfo: alert.asObservable() as Observable<PlannerResponseModel | null>,
  showDevStatsPopup,
  closeDevStatsPopup,
};
