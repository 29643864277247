export enum REVIEW_OPTIONS {
  CLEAN = 'CLEAN',
  NOT_CLEAN = 'NOT_CLEAN',
  EASY = 'EASY',
  NOT_EASY = 'NOT_EASY',
  NO_FUNCTIONALITY = 'NO_FUNCTIONALITY',
  RECOMMENDED = 'RECOMMENDED',
  NOT_RECOMMENDED = 'NOT_RECOMMENDED',
  GOOD = 'GOOD',
  NOT_GOOD = 'NOT_GOOD',
  NO_CARD = 'NO_CARD',
  AMENITIES = 'AMENITIES',
  NO_AMENITIES = 'NO_AMENITIES',
  CABLE = 'CABLE',
  NO_CABLE = 'NO_CABLE',
  PRIVATE = 'PRIVATE',
  SEMI_PRIVATE = 'SEMI_PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum REVIEW_OPTIONS_DESCRIPTIVE {
  CLEAN = 'Very clean 👌',
  NOT_CLEAN = 'Very dirty 😤',
  EASY = 'Easy to use 😎',
  NOT_EASY = 'Confusing behaviour 🙄',
  NO_FUNCTIONALITY = 'Problem reported ❌',
  RECOMMENDED = 'Would charge again here 🤗',
  NOT_RECOMMENDED = 'Can’t recommend 😡',
  GOOD = 'Great station 😃',
  NOT_GOOD = 'Poor station 😐',
  NO_CARD = '\\',
  AMENITIES = 'Lots of amenities 🎉',
  NO_AMENITIES = 'Boring around here 🙄',
  CABLE = 'All cables attached ☑️',
  NO_CABLE = 'No charging cable 🙄',
  PRIVATE = '\\',
  SEMI_PRIVATE = '\\',
  PUBLIC = '\\',
}
