import React, { useCallback, useEffect, useState } from 'react';
import { getChargingPointOperators } from '../../../services/http/get-cpos';
import { getPaymentServices } from '../../../services/http/get-payment-services';
import { useTranslation } from 'react-i18next';
import { loaderService } from '../../../services/loader.service';
import { errorService } from '../../../services/error.service';
import { ChargingPointOperatorModel } from '../../../models/locations-full/charging-point-operator.model';
import { PaymentServiceModel } from '../../../models/locations-full/payment-service.model';
import Filter from './filter/Filter';
import { FILTER_TYPES } from '../../../util/filters.util';
import { StyledFilters } from './PaymentProvidersAndChargingNetworksFilters.styles';

export const PaymentProvidersAndChargingNetworksFilters = () => {
  const [paymentServices, setPaymentServices] = useState<PaymentServiceModel[]>([]);
  const [chargingPointOperators, setChargingPointOperators] = useState<
    ChargingPointOperatorModel[]
  >([]);

  const { t } = useTranslation();

  const fetchPaymentServices = useCallback(async () => {
    loaderService.showLoader();
    try {
      const response = await getPaymentServices();
      const clonedResponse = response.map((obj) => ({
        ...obj,
        isChecked: true,
        isVisible: true,
      }));
      setPaymentServices(clonedResponse);
      loaderService.closeLoader();
    } catch (error) {
      errorService.showError('vehicleConfigurator.power-type.errorLoadingPaymentServices');
      loaderService.closeLoader();
    }
  }, []);

  const fetchChargingPointOperators = useCallback(async () => {
    loaderService.showLoader();
    try {
      const response = await getChargingPointOperators();
      const clonedResponse = response.map((obj) => ({
        ...obj,
        isChecked: true,
        isVisible: true,
      }));
      setChargingPointOperators(clonedResponse);
      loaderService.closeLoader();
    } catch (error) {
      errorService.showError('vehicleConfigurator.power-type.errorLoadingCPOs');
      loaderService.closeLoader();
    }
  }, []);

  useEffect(() => {
    if (!paymentServices.length) {
      (async () => {
        try {
          fetchPaymentServices();
        } catch (error) {
          errorService.showError('vehicleConfigurator.power-type.errorLoadingPaymentServices');
        }
      })();
    }
  }, [fetchPaymentServices, paymentServices]);

  useEffect(() => {
    if (!chargingPointOperators.length) {
      (async () => {
        try {
          fetchChargingPointOperators();
        } catch (error) {
          errorService.showError('vehicleConfigurator.power-type.errorLoadingCPOs');
        }
      })();
    }
  }, [fetchChargingPointOperators, chargingPointOperators]);

  return (
    <StyledFilters.Container>
      <Filter items={paymentServices} filterName={FILTER_TYPES.PAYMENT_SERVICES} />
      <Filter items={chargingPointOperators} filterName={FILTER_TYPES.CHARGING_NETWORKS} />
    </StyledFilters.Container>
  );
};
