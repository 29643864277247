import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';

const StyledImage = styled.img`
  height: 10rem;
  user-select: none;
`;

const StyledH2 = styled.h2`
  color: ${getColor('primaryContrast')};
  margin-top: 2rem;
  text-align: center;
  user-select: none;
`;

export const StyledLoader = {
  StyledImage,
  StyledH2,
};
