/*************************************************  Imports  *************************************************/
import { useTranslation } from 'react-i18next';

import CardIcon from '../../../../../../assets/images/card.svg';
import ChipCardIcon from '../../../../../../assets/images/chip-card.svg';
import ContactlessCardIcon from '../../../../../../assets/images/contactless-card.svg';
import MobileIcon from '../../../../../../assets/images/mobile.svg';
import DefaultIcon from '../../../../../../assets/images/default-amenity.svg';
import UnlockIcon from '../../../../../../assets/images/unlock.svg';
import TokenIcon from '../../../../../../assets/images/token.svg';
import { StyledCapabilityListItem } from './CapabilityListItem.styles';

/*************************************************  Component  *************************************************/
export const CapabilityListItem = (props: { capability: string }) => {
  const { t } = useTranslation();

  /*************************************************  Logic  *************************************************/
  //TODO: Extend this when we get new icons
  const capabilityIcons: { [key: string]: string } = {
    CREDIT_CARD_PAYABLE: CardIcon,
    DEBIT_CARD_PAYABLE: CardIcon,
    REMOTE_START_STOP_CAPABLE: MobileIcon,
    RFID_READER: CardIcon,
    CHIP_CARD_SUPPORT: ChipCardIcon,
    CONTACTLESS_CARD_SUPPORT: ContactlessCardIcon,
    UNLOCK_CAPABLE: UnlockIcon,
    TOKEN_GROUP_CAPABLE: TokenIcon,
  };

  const getCapabilityIcon = () => {
    return capabilityIcons[props.capability] || DefaultIcon;
  };

  /*************************************************  Template  *************************************************/
  return (
    <StyledCapabilityListItem.CapabilityListItemContainer>
      <img src={getCapabilityIcon()} />
      <span>{t(props.capability)}</span>
    </StyledCapabilityListItem.CapabilityListItemContainer>
  );
};

// All 'Capabilities' ENUM
/*
"CREDIT_CARD_PAYABLE",
"DEBIT_CARD_PAYABLE",
"REMOTE_START_STOP_CAPABLE",
"RFID_READER",
"CHIP_CARD_SUPPORT",
"CONTACTLESS_CARD_SUPPORT",
"UNLOCK_CAPABLE",
"TOKEN_GROUP_CAPABLE",
----------------------------
"PED_TERMINAL",
"RESERVABLE",
"CIT_REFER_TO_COUNTER"
"CIT_SMS",
"CHARGING_PREFERENCES_CAPABLE",
"CHARGING_PROFILE_CAPABLE",
*/
