import { IconButton } from '@material-ui/core';
import styled from '@emotion/styled';
import { ReactComponent as RemoveStopIcon } from 'assets/images/remove-trip-stop.svg';
import { FunctionComponent } from 'react';

export const StyledIconButton = styled(IconButton)`
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 1px;
  align-self: center;
`;

export const RemoveStopButton: FunctionComponent<{ onClick: () => void; id?: string }> = ({
  onClick,
  id,
}) => {
  return (
    <StyledIconButton onClick={onClick} id={id}>
      <RemoveStopIcon />
    </StyledIconButton>
  );
};
