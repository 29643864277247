import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useAppAnalytics } from 'analytics';
import { StyledPrivacyPolicy } from '../PrivacyPolicy.styles';
import { DataTrackingPolicyRow } from '../DataTrackingPolicyRow';

const PrivacyPolicyDe = () => {
  const { trackEvent } = useAppAnalytics();

  const performTrackEvent = (currentEventName: string) => {
    trackEvent({ action: 'Privacy policy - ' + currentEventName, category: 'general' });
  };

  return (
    <>
      <div className="noAccordion">
        <p className="footerLinksDialogParagraph">
          Die ElectricRoutes GmbH ("ElectricRoutes“ oder „wir“) stellt Ihnen „ElectricRoutes“ auf
          der Website{' '}
          <StyledPrivacyPolicy.StyledLink
            href={'https://www.electricroutes.com'}
            onClick={() => performTrackEvent('https://www.electricroutes.com')}
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.electricroutes.com
          </StyledPrivacyPolicy.StyledLink>{' '}
          („Website“) zur Verfügung.
        </p>
        <p className="footerLinksDialogParagraph">
          Mit der folgenden Datenschutzerklärung für die Website erläutern wir Ihnen, welche
          personenbezogenen Daten wir erheben, zu welchen Zwecken wir diese verarbeiten und welche
          Rechte Ihnen zustehen, wenn Sie die Website besuchen und den Dienst „ElectricRoutes“ über
          die Website nutzen. Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges
          Anliegen.
        </p>
        <p className="footerLinksDialogParagraph">
          Nach der EU-Datenschutz-Grundverordnung („DSGVO“) sind personenbezogene Daten alle
          Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person
          („betroffene Person”) beziehen. Als identifizierbar wird eine natürliche Person angesehen,
          die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
          zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren
          besonderen persönlichen Merkmalen identifiziert werden kann. Damit sind sowohl Ihre
          persönlichen Daten, wie z.B. Ihr Name, Ihre Postanschrift oder Ihre E-Mail-Adresse, als
          auch die bei Nutzung der Website entstehenden bzw. verarbeiteten technischen Daten, die
          Ihnen zugeordnet werden können, wie z.B. die IP-Adresse, das Betriebssystem, der Browser
          Ihres Endgeräts oder Ihr Nutzerverhalten, personenbezogene Daten.
        </p>
      </div>

      <StyledPrivacyPolicy.StyledDiv>
        {/* ------------------- HEADING 1 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-cy="privacy-accordion-1"
          >
            {'1. Datenschutzrechtlich Verantwortlicher'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Datenschutzrechtlich verantwortlich für die Erhebung und Verarbeitung Ihrer
              personenbezogenen Daten bei Besuch und Nutzung der Website ist die
            </p>
            <p className="footerLinksDialogParagraph">
              <span>ElectricRoutes GmbH</span>
              <span>Heilbronner Straße 86</span>
              <span>70191 Stuttgart</span>
            </p>
            <p className="footerLinksDialogParagraph">
              <span>
                E-Mail:&nbsp;
                <StyledPrivacyPolicy.StyledLink
                  href={'mailto:contact@electricroutes.com'}
                  onClick={() => performTrackEvent('mailto:contact@electricroutes.com')}
                >
                  contact@electricroutes.com
                </StyledPrivacyPolicy.StyledLink>
              </span>
              <span>
                Telefon:&nbsp;
                <StyledPrivacyPolicy.StyledLink
                  href={'tel:+49 711 252 749-0'}
                  onClick={() => performTrackEvent('tel:+49 711 252 749-0')}
                >
                  +49 711 252 749-0
                </StyledPrivacyPolicy.StyledLink>
              </span>
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 2 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            data-cy="privacy-accordion-2"
          >
            {'2. Datenschutzbeauftragter'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Sofern Sie Fragen, Anliegen oder Anregungen zum Datenschutz betreffend unsere Website
              haben, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden:
            </p>

            <p className="footerLinksDialogParagraph">
              <span>SICODA GmbH</span>
              <span>Oliver Gönner c/o DSB – P3 group GmbH</span>
            </p>

            <p className="footerLinksDialogParagraph">
              <span>Rochusstraße 198</span>
              <span>53123 Bonn</span>
            </p>

            <p className="footerLinksDialogParagraph">
              <span>
                E-Mail:&nbsp;
                <StyledPrivacyPolicy.StyledLink
                  href={'mailto:datenschutz.global@p3-group.com'}
                  onClick={() => performTrackEvent('mailto:datenschutz.global@p3-group.com')}
                >
                  datenschutz.global@p3-group.com
                </StyledPrivacyPolicy.StyledLink>
              </span>
              <span>
                Telefon:&nbsp;
                <StyledPrivacyPolicy.StyledLink
                  href={'tel:+49 228 286 140 60'}
                  onClick={() => performTrackEvent('tel:+49 228 286 140 60')}
                >
                  +49 228 286 140 60
                </StyledPrivacyPolicy.StyledLink>
              </span>
            </p>

            <p className="footerLinksDialogParagraph">
              Bei unserem Datenschutzbeauftragten können Sie auch Ihre Betroffenenrechte geltend
              machen. Nähere Informationen dazu finden Sie im Abschnitt 8.
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 3 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            {'3. Zwecke und Rechtsgrundlagen der Datenverarbeitung – Automatisch erhobene Daten'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Der Besuch und die rein informatorische Nutzung unserer Website sind möglich, ohne
              dass Sie selbst (etwa durch Registrierung oder Übermittlung in Online-Formularen)
              persönliche Daten, wie z.B. Ihren Namen, Ihre Postanschrift oder Ihre E-Mail-Adresse,
              angeben müssen oder wir solche persönlichen Daten erheben.
            </p>
            <p className="footerLinksDialogParagraph">
              Damit Sie unsere Website betrachten sowie benutzerfreundlich, effektiv, stabil und
              sicher nutzen können, erheben und verarbeiten wir jedoch automatisch in Zusammenwirken
              mit Ihrem Endgerät und Ihrem Browser die hierfür erforderlichen (pseudonymen)
              technischen Daten.
            </p>

            <h5>3.1 Automatische Datenerhebung und –verarbeitung durch Webserver</h5>

            <p className="footerLinksDialogParagraph">
              Um Ihnen die einzelnen zu unserer Website gehörenden Webseiten anzeigen zu können,
              werden von unseren Webservern automatisch folgende personenbezogenen Daten erhoben und
              verarbeitet, die von Ihrem Browser übermittelt werden:
            </p>

            <ul>
              <li>IP-Adresse Ihres zugreifenden Endgeräts,</li>
              <li>Name und URL der abgerufenen Datei,</li>
              <li>Datum und Uhrzeit des Zugriffs,</li>
              <li>übertragene Datenmenge,</li>
              <li>Meldung, ob der Abruf der Datei erfolgreich war,</li>
              <li>
                Erkennungsdaten (Typ, Version und Sprache) des verwendeten Browsers und
                Betriebssystems Ihres zugreifenden Endgeräts,
              </li>
              <li>
                URL der verweisenden Webseite, wenn Zugriff über Link erfolgte, und zusätzlich
                Suchbegriff, wenn Zugriff über Suchmaschine erfolgte, und
              </li>
              <li>Name Ihres Internet-Zugangs-Providers</li>
            </ul>

            <p className="footerLinksDialogParagraph">
              Rechtsgrundlage für diese Datenverarbeitungen ist Art. 6 Abs. 1 Satz 1 lit. b) DSGVO.
              Die Datenverarbeitungen sind zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre
              Anfrage erfolgen, erforderlich, da zu solchen vorvertraglichen Maßnahmen auch
              Website-Besuche gehören.
            </p>

            <p className="footerLinksDialogParagraph">
              Die zur Ermöglichung der Nutzung der Website (Verbindungsaufbau) erhobenen und
              verarbeiteten (pseudonymen) technischen Kommunikationsdaten werden von unseren
              Webservern temporär in Protokolldateien, sog. Server-Logfiles, gespeichert. Wir nutzen
              die Server-Logfiles zu internen systembezogenen Zwecken, insbesondere zur technischen
              Administration und zur Gewährleistung der Stabilität und Sicherheit unserer Webserver
              und Website, z.B. um über die gespeicherte IP-Adresse unzulässige oder missbräuchliche
              Zugriffsversuche auf unsere Webserver zu identifizieren und nachzuverfolgen. Wir
              verwenden die Server-Logfiles ferner zu statistischen Zwecken, um die Zugriffe auf
              unsere Website und deren Inhalte pseudonymisiert erfassen und auswerten sowie auf
              dieser Basis unsere Website bedarfsgerechter, benutzerfreundlicher und effektiver
              gestalten und optimieren zu können. Rechtsgrundlage für diese Datenverarbeitungen der
              Server-Logfiles ist Art. 6 Abs. 1 Satz 1 lit. f) DSGVO, der die Verarbeitung von
              personenbezogenen Daten im Rahmen unserer „berechtigten Interessen“ gestattet, soweit
              nicht Ihre Grundrechte, Grundfreiheiten oder Interessen überwiegen. Unsere
              berechtigten Interessen bestehen in der leichten und sicheren Administration unserer
              Website bzw. in der Messung, Analyse und Verbesserung unserer Website.
            </p>

            <h5>3.2 Kartendarstellung durch „Google Maps“</h5>

            <p className="footerLinksDialogParagraph">
              Mit Ihrer Einwilligung wird Ihre persönliche Route bei der Nutzung von
              „ElectricRoutes“ innerhalb des Kartendienstes „Google Maps“ angezeigt. „Google Maps“
              ist ein Angebot der Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04
              E5W5, Irland (“Google”).
            </p>
            <p className="footerLinksDialogParagraph">
              Rechtsgrundlage für die Nutzung von „Google Maps“ auf unserer Website ist Art. 6 Abs.
              1 Satz 1 lit. a{')'} DSGVO, der die Verarbeitung von personenbezogenen Daten mit der
              Einwilligung der betroffenen Person gestattet.
            </p>
            <p className="footerLinksDialogParagraph">
              Im Rahmen von ElectricRoutes haben Sie die Möglichkeit, „Google Maps“ zu aktivieren,
              indem Sie die folgende Einwilligungserklärung abgeben:
            </p>

            <p className="footerLinksDialogParagraph quote">
              Aktivierung des Kartendienstes Google Maps
            </p>
            <p className="footerLinksDialogParagraph quote">
              Um Ihre Route in Google Maps anzeigen zu können, ist Ihre Zustimmung erforderlich. Mit
              einem Klick auf die Schaltfläche „Zustimmen“ willigen Sie ein, dass Google Maps
              aktiviert wird und Daten über Ihre Nutzung von „ElectricRoutes“ an Google übertragen
              werden. Bitte beachten Sie, dass es bei der Nutzung von Google Maps auch zu
              Datenübertragungen an Server von Google in den USA kommen kann und somit in ein Land
              außerhalb der Europäischen Union ohne vergleichbares Datenschutzniveau.
            </p>
            <p className="footerLinksDialogParagraph quote">
              Weitere Informationen über die Art und die Zwecke der Datenverarbeitung im Rahmen von
              Google Maps sowie über Ihre Rechte finden Sie in der Datenschutzerklärung (
              <StyledPrivacyPolicy.StyledLink
                href={'https://policies.google.com/privacy?hl=de'}
                onClick={() => performTrackEvent('https://policies.google.com/privacy?hl=de')}
                rel="noopener noreferrer"
                target="_blank"
              >
                https://policies.google.com/privacy?hl=de
              </StyledPrivacyPolicy.StyledLink>
              ).
            </p>

            <p className="footerLinksDialogParagraph">
              Falls Sie diese Einwilligung abgeben, erhält Google die Information, dass Sie die
              betroffene Website aufgerufen haben. Ferner erhält Google Zugriff auf die im Abschnitt
              3.1 genannten (pseudonymen) technischen Kommunikationsdaten und ggf. weitere
              personenbezogene Daten wie z.B. Ihre Standortdaten oder Daten über die Nutzung
              weiterer Seiten dieser Website. Die Datenübermittlung an Google erfolgt unabhängig
              davon, ob Sie bei Google ein Nutzerkonto haben, über das Sie eingeloggt sind, oder
              nicht.{' '}
            </p>
            <p className="footerLinksDialogParagraph">
              Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet.
              Wenn Sie die Zuordnung zu Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor
              Aufruf von Seiten mit Google Maps ausloggen. Google speichert Ihre Daten als
              Nutzungsprofile und nutzt sie für Zwecke der bedarfsgerechten Gestaltung seiner
              Angebote, der Werbung und/oder der Marktforschung. Eine solche Auswertung Ihrer Daten
              insbesondere zur Erbringung von bedarfsgerechter Werbung erfolgt auf pseudonymer Basis
              selbst dann, wenn Sie nicht bei Google eingeloggt sind oder gar kein Google
              Nutzerkonto haben. Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser
              (pseudonymen) Nutzerprofile zu, wobei Sie sich zu dessen Ausübung an Google richten
              müssen.{' '}
            </p>
            <p className="footerLinksDialogParagraph">
              Wir haben keinen Einfluss auf die von Google vorgenommenen Datenerhebungen und
              verarbeitungen und sind dafür auch nicht verantwortlich. Uns sind weder diese
              Datenverarbeitungen noch deren sämtliche Zwecke sowie Speicherfristen bekannt. Bitte
              beachten Sie, dass es bei der Nutzung von „Google Maps“ auch zu Datenübertragungen an
              Server der Google LLC in den USA kommen kann, einem Drittland außerhalb der
              Europäischen Union und des Europäischen Wirtschaftsraums. Wenn Sie die
              Datenübermittlung an Google nicht wünschen, sollten Sie der Nutzung von „Google Maps“
              nicht zustimmen. Die Anzeige Ihrer persönlichen Route durch „ElectricRoutes“ ist in
              diesem Fall nicht möglich.
            </p>
            <p className="footerLinksDialogParagraph">
              Die Nutzungsbedingungen von Google finden Sie unter&nbsp;
              <StyledPrivacyPolicy.StyledLink
                href={'https://www.google.de/intl/de/policies/terms/regional.html'}
                onClick={() =>
                  performTrackEvent('https://www.google.de/intl/de/policies/terms/regional.html')
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.google.de/intl/de/policies/terms/regional.html
              </StyledPrivacyPolicy.StyledLink>
              , die zusätzlichen Nutzungsbedingungen für Google Maps finden Sie unter&nbsp;
              <StyledPrivacyPolicy.StyledLink
                href={'https://www.google.com/intl/de_US/help/terms_maps.html'}
                onClick={() =>
                  performTrackEvent('https://www.google.com/intl/de_US/help/terms_maps.html')
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.google.com/intl/de_US/help/terms_maps.html
              </StyledPrivacyPolicy.StyledLink>
              . Ausführliche Informationen zum Datenschutz im Zusammenhang mit der Verwendung von
              Google Maps finden Sie unter&nbsp;
              <StyledPrivacyPolicy.StyledLink
                href={'https://www.google.de/intl/de/policies/privacy/'}
                onClick={() => performTrackEvent('https://www.google.de/intl/de/policies/privacy/')}
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.google.de/intl/de/policies/privacy/
              </StyledPrivacyPolicy.StyledLink>
              . Möchten Sie einer Datenerhebung und verarbeitung durch Google widersprechen, können
              Sie dies unter&nbsp;{' '}
              <StyledPrivacyPolicy.StyledLink
                href={'https://adssettings.google.com/authenticated'}
                onClick={() => performTrackEvent('https://adssettings.google.com/authenticated')}
                rel="noopener noreferrer"
                target="_blank"
              >
                https://adssettings.google.com/authenticated
              </StyledPrivacyPolicy.StyledLink>
              &nbsp;einstellen.
            </p>

            <h5>3.3 Standortabfrage durch Browser</h5>

            <p className="footerLinksDialogParagraph">
              Über das entsprechende Dialogfeld in Ihrem Browser haben Sie die Möglichkeit, die
              Nutzung Ihres Standortes durch „ElectricRoutes“ freizugeben. In diesem Fall sendet Ihr
              Browser uns auf Abfrage automatisch Informationen über ihren ungefähren Standort. Wir
              verarbeiten Ihre Standortdaten ausschließlich für die Navigation und die
              Standortanzeige im Rahmen von „ElectricRoutes“.
            </p>
            <p className="footerLinksDialogParagraph">
              Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem
              Sie die Freigabe Ihres Standorts für die Website in den Einstellungen Ihres Browsers
              deaktivieren.
            </p>
            <p className="footerLinksDialogParagraph">
              Rechtsgrundlage für die automatische Erhebung und Nutzung Ihrer Standortdaten auf
              unserer Website ist Art. 6 Abs. 1 Satz 1 lit. a{')'}
              DSGVO, der die Verarbeitung von personenbezogenen Daten mit der Einwilligung der
              betroffenen Person gestattet.
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 4 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            {
              '4. Zwecke und Rechtsgrundlagen der Datenverarbeitung – Von Ihnen bereitgestellte Daten '
            }
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Die Nutzung bestimmter Funktionen und Angebote unserer Website, insbesondere die
              Routenplanung mit „ElectricRoutes“, ist nur auf der Grundlage personenbezogener Daten
              möglich. Wir verarbeiten solche Daten nur, wenn Sie uns diese selbst mitteilen oder
              der Erhebung dieser Daten ausdrücklich zustimmen
            </p>

            <h5>4.1 Datenverarbeitung im Rahmen von „ElectricRoutes“</h5>
            <p className="footerLinksDialogParagraph">
              Für die Routenplanung mit „ElectricRoutes“ verarbeiten wir die Daten, die Sie uns in
              den entsprechenden Eingabefeldern zur Verfügung stellen, nämlich
            </p>

            <ul>
              <li>Start- und Zielstandorte,</li>
              <li>Fahrzeugtyp (Marke und Modell),</li>
              <li>bevorzugte Ladekriterien der Batterie,</li>
              <li>Akkuladung (State of Charge, SoC),</li>
              <li>Kriterien zur Auswahl der Ladestationen.</li>
            </ul>

            <p className="footerLinksDialogParagraph">
              Die von Ihnen eingegebenen Daten sowie – falls Sie über Ihren Browser eingewilligt
              haben – die automatisiert erhobenen Standortdaten werden von uns verarbeitet, um eine
              Route zu berechnen und Ihnen eine individualisierte Navigation anbieten zu können.
              Dabei berechnet unser Dienst basierend auf Ihren Angaben eine optimierte Route
              inklusive Ladestopps, die auf die Ladekurve Ihres Fahrzeugs abgestimmt sind. Wir haben
              keine Möglichkeit diese Daten Ihrer Person zuzuordnen.
            </p>
            <p className="footerLinksDialogParagraph">
              Rechtsgrundlage für die Datenverarbeitungen zur Routenberechnung und Navigation im
              Rahmen von „ElectricRoutes“ ist Art. 6 Abs. 1 Satz 1 lit. b{')'} DSGVO. Die
              Verarbeitung Ihrer personenbezogenen Daten ist erforderlich, um Ihnen den Dienst
              „ElectricRoutes“ zur Verfügung stellen zu können. Soweit Sie Ihre Einwilligung in die
              Standortabfrage durch Ihren Browser erteilt haben, verarbeiten wir Ihre Standortdaten
              auf der Grundlage von Art. 6 Abs. 1 Satz 1 lit. a{')'} DSGVO, der die Verarbeitung von
              personenbezogenen Daten mit der Einwilligung der betroffenen Person gestattet.
            </p>
            <p className="footerLinksDialogParagraph">
              Die bei der Nutzung von „ElectricRoutes“ anfallenden Daten speichern wir so
              anonymisiert, dass kein Personenbezug mehr hergestellt werden kann. Wir führen
              statistische Auswertungen dieser anonymisierten Daten durch, um mehr über die Nutzung
              von E Mobilität zu erfahren und um unseren Dienst verbessern zu können. Die
              Anonymisierung der Daten basiert auf unserem berechtigten Interesse nach Art. 6 Abs. 1
              Satz 1 lit. f{')'} DSGVO, die Daten in anonymisierter Form statistisch auswerten zu
              können.
            </p>
            <p className="footerLinksDialogParagraph">
              Sie haben im Rahmen der „ElectricRoutes Community“ ferner die Möglichkeit,
              Ladestationen nach vordefinierten Kriterien zu bewerten. Wir speichern Ihre Bewertung
              und stellen sie anderen „ElectricRoutes“-Nutzern bei den Statusinformationen für die
              betroffene Ladestation zum Abruf zur Verfügung. Ihre Bewertung bleibt dabei anonym –
              anderen Nutzern werden weder Ihr Name noch sonstige identifizierende Merkmale
              angezeigt. Wir behalten uns vor, über die Verarbeitung (pseudonymer) Merkmale wie
              beispielsweise der Geräte-ID sicherzustellen, dass lediglich eine Bewertung pro Nutzer
              hinterlegt werden kann. Die Datenverarbeitungen im Zusammenhang mit Bewertungen
              erfolgen auf der Grundlage von Art. 6 Abs. 1 Satz 1 lit. f{')'} DSGVO, der die
              Verarbeitung von personenbezogenen Daten im Rahmen unserer „berechtigten Interessen“
              gestattet, soweit nicht Ihre Grundrechte, Grundfreiheiten oder Interessen überwiegen.
              Unsere berechtigten Interessen bestehen in der Bereitstellung eines objektiven
              Bewertungssystems für Ladesäulen im Rahmen unseres Dienstes „ElectricRoutes“.
            </p>

            <h5>4.2 Kommunikation per E-Mail</h5>

            <p className="footerLinksDialogParagraph">
              Wenn Sie per E-Mail Kontakt zu uns aufzunehmen, verarbeiten wir Ihre Anfrage sowie
              Ihre E Mail-Adresse ausschließlich zur Bearbeitung Ihres Anliegens und möglicher
              Rückfragen.
            </p>
            <p className="footerLinksDialogParagraph">
              Die Rechtsgrundlage für die Verarbeitung Ihrer Mitteilungen und Ihrer E-Mail-Adresse
              ist Art. 6 Abs. 1 Satz 1 Buchst. b{')'} DSGVO.
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 5 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            {'5. Empfänger von personenbezogenen Daten'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Die von uns im Rahmen Ihres Besuchs auf unserer Website erhobenen personenbezogenen
              Daten werden grundsätzlich nicht an andere Empfänger weitergegeben oder in sonstiger
              Weise übermittelt, soweit dies nicht in der Datenschutzerklärung ausdrücklich
              angegeben wird. Ausgenommen hiervon sind zwingend erforderliche Übermittlungen
              personenbezogener Daten an staatliche Einrichtungen und Behörden sowie private
              Rechteinhaber auf der Grundlage gesetzlicher Vorschriften oder gerichtlicher bzw.
              behördlicher Entscheidungen sowie die erforderliche Weitergabe an staatliche
              Einrichtungen und Behörden im Fall von Angriffen auf unsere Rechtsgüter zu Zwecken der
              Rechts- oder Strafverfolgung.
            </p>
            <p className="footerLinksDialogParagraph">
              Beim Betrieb unserer Website und der Erbringung und Abwicklung einzelner
              Website-Funktionen und Angebote bedienen wir uns jedoch zum Teil externer technischer
              und sonstiger Dienstleister, die uns bei der Erbringung unserer Leistungen
              unterstützen und von uns sorgfältig ausgewählt wurden. Soweit erforderlich,
              verarbeiten diese von uns eingeschalteten Dienstleister Ihre personenbezogen Daten im
              Rahmen ihres jeweiligen Auftrags gemäß unseren Anweisungen zu den in diesen
              Datenschutzhinweisen genannten Zwecken. Sie sind vertraglich zur strikten Einhaltung
              dieser Datenschutzerklärung, der geltenden gesetzlichen Datenschutzbestimmungen und
              unserer Weisungen verpflichtet (sog. Auftragsverarbeiter gemäß Art. 28 DSGVO).
              Dasselbe gilt für etwaige Unterauftragnehmer, soweit sich unsere Auftragsverarbeiter
              mit unserer vorherigen Zustimmung solcher bedienen.
            </p>
            <p className="footerLinksDialogParagraph">
              Auftragsverarbeiter werden von uns insbesondere zum Hosting der Website und unserer
              Routennavigationsdatenbank eingesetzt.
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 6 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            {'6. Verarbeitung von personenbezogenen Daten in „Drittländern“'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Wir verarbeiten die auf der Website erhobenen personenbezogenen Daten innerhalb der
              Mitgliedstaaten der Europäischen Union. Eine Übermittlung personenbezogener Daten in
              sonstige „Drittländer“, also in Länder außerhalb der Europäischen Union und des
              Europäischen Wirtschaftsraums, findet nur statt, wenn wir Ihnen dies in dieser
              Datenschutzerklärung ausdrücklich mitteilen.
            </p>
            <p className="footerLinksDialogParagraph">
              Bei der im Abschnitt 3.2. beschriebenen Nutzung von „Google Maps“ kann es zu
              Datenübermittlungen in die USA kommen. Die Datenerhebung und –verarbeitung durch
              „Google Maps“ erfolgt ausschließlich mit Ihrer Einwilligung gemäß Art. 6 Abs. 1 Satz 1
              lit. a{')'} DSGVO in Verbindung mit Art. 49 Abs.1 lit. a{')'} DSGVO.
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 7 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            {'7. Dauer der Speicherung'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Generell speichern wir personenbezogene Daten nur solange, wie dies zur Erfüllung des
              Zwecks der jeweiligen Datenverarbeitung erforderlich ist, oder bis Sie von uns aus
              anderen berechtigen Gründen eine Löschung der personenbezogenen Daten verlangen (siehe
              Art. 17 Abs. 1 DSGVO). Nach Ablauf dieser Speicherfristen bzw. auf Ihr berechtigtes
              Löschungsverlangen hin prüfen wir jeweils, ob bestimmte personenbezogene Daten noch
              für (andere) legitime Zwecke benötigt werden und, falls nicht, ob einer Löschung der
              personenbezogenen Daten vertragliche oder gesetzliche Aufbewahrungsfristen
              entgegenstehen. In diesen Fällen werden die davon betroffenen personenbezogenen Daten
              solange, wie zur Erfüllung dieses (anderen) legitime Zweckes erforderlich, bzw. für
              die Dauer der jeweiligen vertraglichen oder gesetzlichen Aufbewahrungsfrist für diese
              Zwecke weitergespeichert. Für andere Zwecke werden sie jedoch gesperrt und dann nach
              Ablauf der jeweils letzten vertraglichen oder gesetzlichen Aufbewahrungsfrist
              endgültig gelöscht.
            </p>
            <p className="footerLinksDialogParagraph">
              Die in den Server-Logfiles enthaltenen technischen Kommunikationsdaten (siehe
              Abschnitt 3.1) werden nach spätestens 90 Tagen gelöscht.
            </p>
            <p className="footerLinksDialogParagraph">
              Die Speicherdauer der an Google übermittelten Daten können Sie der
              Datenschutzerklärung von Google entnehmen (siehe Abschnitt 3.2).
            </p>
            <p className="footerLinksDialogParagraph">
              Die bei der Nutzung von „ElectricRoutes“ anfallenden Daten speichern und verarbeiten
              wir ohne Personenbezug (siehe Abschnitt 4.1).
            </p>
            <p className="footerLinksDialogParagraph">
              Falls Sie per E-Mail Kontakt zu uns aufnehmen, speichern wir die von Ihnen gesandte
              Nachricht sowie Ihre E-Mail-Adresse nur solange, wie dies zur Bearbeitung Ihres
              Anliegens und möglicher Rückfragen erforderlich ist (siehe Abschnitt 4.2). Ihre Daten
              werden spätestens sechs Monate nach Ihrer letzten Kontaktaufnahme gelöscht, es sei
              denn, wir sind zur weiteren Speicherung Ihrer Nachricht und/oder Ihrer E-Mail-Adresse
              berechtigt oder verpflichtet.
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 8 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            {'8. Ihre Rechte (Betroffenenrechte)'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Soweit die gesetzlichen Voraussetzungen nach den Art. 15 ff. DSGVO vorliegen, haben
              Sie hinsichtlich Ihrer bei uns gespeicherten personenbezogenen Daten folgende Rechte
              (sog. Betroffenenrechte):
            </p>

            <ul>
              <li>
                Sie können jederzeit bei uns Auskunft darüber verlangen, ob personenbezogene Daten
                und welche Kategorien personenbezogener Daten über Sie bei uns gespeichert sind, zu
                welchen Zwecken diese verarbeitet werden und welche Empfänger oder Kategorien von
                Empfängern diese ggf. erhalten. Ferner können Sie die weiteren in Art. 15 DSGVO
                aufgezählten Informationen zu Ihren personenbezogenen Daten bei uns erfragen (Recht
                auf Auskunft).
              </li>
              <li>
                Gemäß den gesetzlichen Voraussetzungen haben Sie außerdem ein Recht auf Berichtigung
                (Art. 16 DSGVO), ein Recht auf Löschung (Art. 17 DSGVO) und ein Recht auf
                Einschränkung der Verarbeitung (Sperrung) (Art. 18 DSGVO) Ihrer personenbezogenen
                Daten.
              </li>
              <li>
                Sie haben weiter gemäß Art. 20 DSGVO das Recht, die Sie betreffenden
                personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
                gängigen und maschinenlesbaren Format von uns zu erhalten. Sie können diese
                personenbezogenen Daten auch an andere Verantwortliche übermitteln oder übermitteln
                lassen (Recht auf Datenübertragbarkeit).
              </li>
              <li>
                Sie haben auch Anspruch auf jederzeitigen Widerruf Ihrer erteilten Einwilligungen.
              </li>
              <li>
                <b>
                  Darüber hinaus haben Sie das Recht, bei Datenverarbeitungen, die nach Art. 6 Abs.
                  1 Satz 1 lit. f{')'} DSGVO auf den berechtigten Interessen des Verantwortlichen
                  oder eines Dritten beruhen, bei Vorliegen der gesetzlichen Voraussetzungen nach
                  Art. 21 DSGVO der Datenverarbeitung zu widersprechen.
                </b>
              </li>
            </ul>

            <p className="footerLinksDialogParagraph">
              Zur Geltendmachung Ihrer Betroffenenrechte können Sie sich jederzeit unter einer der
              in den Abschnitten 1. und 2. angegebenen Adressen an uns wenden.
            </p>
            <p className="footerLinksDialogParagraph">
              Ferner haben Sie gemäß Art. 77 Abs. 1 DSGVO das Recht auf Beschwerde bei einer
              Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres
              Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind,
              dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO
              verstößt.
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 9 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9a-content"
            id="panel9a-header"
            data-cy="privacy-accordion-9"
          >
            {'9. Datensicherheit'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Wir setzen umfangreiche technische und organisatorische Vorkehrungen ein, um Ihre bei
              uns gespeicherten und verarbeiteten personenbezogenen Daten gegen Missbrauch,
              zufällige oder vorsätzliche Manipulationen, Verlust, den Zugriff unberechtigter
              Personen und gegen sonstige Datensicherheitsrisiken zu schützen. Unsere Vorkehrungen
              zur Datensicherheit werden entsprechend der technologischen Entwicklung fortlaufend
              verbessert. Die Übermittlung von personenbezogenen Daten erfolgt verschlüsselt nach
              aktuellem Stand der Technik
            </p>
            <p className="footerLinksDialogParagraph">
              Unsere Mitarbeiter sind von uns zur Wahrung der Vertraulichkeit und zur Einhaltung der
              maßgeblichen datenschutzrechtlichen Vorschriften verpflichtet.
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 10 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
          >
            {'10. Änderung der Datenschutzerklärung'}
          </AccordionSummary>
          <AccordionDetails>
            <p className="footerLinksDialogParagraph">
              Wir können diese Datenschutzhinweise jederzeit an veränderte tatsächliche oder
              rechtliche Gegebenheiten anpassen und werden jeweils die neueste Version dieser
              Datenschutzhinweise an dieser Stelle veröffentlichen. Wann diese Datenschutzhinweise
              zuletzt geändert wurden, können Sie an der Datumsangabe („Zuletzt aktualisiert“) am
              Anfang dieser Datenschutzhinweise erkennen
            </p>
          </AccordionDetails>
        </Accordion>

        {/* ------------------- HEADING 11 ------------------- */}
        <Accordion>
          <AccordionSummary
            expandIcon={null}
            aria-controls="panel11a-content"
            id="panel11a-header"
            style={{ paddingRight: 0 }} // TODO: Extract style
          >
            <DataTrackingPolicyRow heading={'11. Datentracking'} />
          </AccordionSummary>
        </Accordion>
      </StyledPrivacyPolicy.StyledDiv>
    </>
  );
};

export default PrivacyPolicyDe;
