/*************************************************  Imports  *************************************************/
import { checkSafari } from '../../location-pin-popup/details/amenities/checkSafari';
import { StyledDestinationMarker } from './DestinationMarker.styles';

/*************************************************  Component  *************************************************/
export const DestinationMarker = (props: {
  name: string | undefined;
  lat: Number | undefined;
  lng: Number | undefined;
}) => {
  /*************************************************  Template  *************************************************/
  // className is only for finding this element easy in "InspectElement"
  return (
    <StyledDestinationMarker.DestinationMarker className="destinationMarker">
      <StyledDestinationMarker.MarkerTooltip className={checkSafari() ? 'safari' : 'not-safari'}>
        <h5>{props.name}</h5>
      </StyledDestinationMarker.MarkerTooltip>
    </StyledDestinationMarker.DestinationMarker>
  );
};
