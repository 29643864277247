import { useContext, useRef } from 'react';
import { AppContext, AppContextModel } from 'core/app-context';
import { RouteStop } from 'core/models/planner/route-stop.model';

// TODO: Eventually replace hook with using Redux field for 'stops' and Redux reducers + thunks to add/remove stops
// use custom selector to calculate validity of stops
export const useRouteStops = () => {
  const currentStopId = useRef(0);
  const { appContextValue, setAppContextValue } = useContext(AppContext);
  const stops: RouteStop[] = appContextValue.stops || [];
  const stopsValid =
    stops.filter((stop) => typeof stop.lat === 'number' && typeof stop.lng === 'number').length ===
    stops.length;

  const setStops = (newStops: RouteStop[]) =>
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      stops: newStops,
    }));

  const addEmptyStop = () => {
    const newStops = [
      ...stops,
      {
        lat: (undefined as unknown) as number, // TODO: Typechecking strategy for stops - create temporary input which does not rely on 'stops' context field
        lng: (undefined as unknown) as number,
        name: '',
        id: `${currentStopId.current}`,
      },
    ];

    currentStopId.current = currentStopId.current + 1;
    setStops(newStops);
  };

  const addStopAtIndex = ({
    name,
    lat,
    lng,
    stopIndex,
  }: {
    name: string;
    lat: number;
    lng: number;
    stopIndex: number;
  }) => {
    const newStops = [...stops];
    newStops[stopIndex] = {
      name,
      lat,
      lng,
      id: stops[stopIndex].id,
    };

    setStops(newStops);
  };

  const removeStopAtIndex = (stopIndex: number) => {
    const newStops = [...stops];
    newStops.splice(stopIndex, 1);
    setStops(newStops);
  };

  const clearStop = (stopIndex: number) => {
    const newStops = [...stops];
    newStops[stopIndex] = {
      lat: (undefined as unknown) as number,
      lng: (undefined as unknown) as number,
      name: '',
      id: stops[stopIndex].id,
    };
  };

  return {
    stops,
    stopsValid,
    setStops,
    addEmptyStop,
    removeStopAtIndex,
    clearStop,
    addStopAtIndex,
  };
};
