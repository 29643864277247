import styled from '@emotion/styled';
import { getColor } from '../../../../../theme/colors/colors';
import { getFont } from '../../../../../theme/fonts/fonts';

const AmenityContainer = styled.button`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  box-shadow: -3rem 0 0 ${getColor('surface', 0)}, 3rem 0 0 ${getColor('surface', 0)};
  background: ${getColor('surface', 0)};
  transition: 0.5s;

  &:hover,
  &.active {
    box-shadow: -3rem 0 0 ${getColor('surface', 1)}, 3rem 0 0 ${getColor('surface', 1)};
    background: ${getColor('surface', 1)};
  }

  &:hover {
    filter: drop-shadow(0px 6px 6px ${getColor('shadow', 0.1)});
    z-index: 2;
  }

  &.active {
    filter: drop-shadow(0px 2px 0px ${getColor('shadow', 0.1)});
  }
`;

const IconAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2rem;

  span {
    margin-left: 1rem;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;

const WifiAndDistanceContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MTSpan = styled.span`
  margin-top: 0.4rem;
  font-size: 1.6rem;
  font-family: ${getFont('mainFont')};
`;

const HasWifiImage = styled.img`
  display: inline-block;
  margin-right: 1rem;
`;

export const StyledAmenityListItem = {
  AmenityContainer,
  IconAndNameContainer,
  MTSpan,
  WifiAndDistanceContainer,
  HasWifiImage,
};
