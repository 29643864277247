import { AmenityModel } from '../models/locations-full/amenity.model';
import { LocationFullModel } from '../models/locations-full/location-full.model';
import { checkSafari } from '../components/map-view/location-pin-popup/details/amenities/checkSafari';

// **************************** ICONS
import CafeIcon from '../../assets/images/amenities/coffee.svg';
import DefaultIcon from '../../assets/images/default-amenity.svg';
import DrinksIcon from '../../assets/images/amenities/drink.svg';
import FastFoodIcon from '../../assets/images/amenities/fast-food.svg';
import GroceryShoppingIcon from '../../assets/images/amenities/groc-shopping.svg';
import LeisureIcon from '../../assets/images/amenities/leisure.svg';
import RestaurantIcon from '../../assets/images/amenities/restaurant.svg';
import ShoppingIcon from '../../assets/images/amenities/shopping.svg';
import SightseeingIcon from '../../assets/images/amenities/sightseeing.svg';
import HotelIcon from '../../assets/images/amenities/hotel.svg';
import TransportIcon from '../../assets/images/amenities/transport.svg';
import ParkingIcon from '../../assets/images/amenities/parking.svg';
import BikeSharingIcon from '../../assets/images/amenities/bike-sharing.svg';
import TaxiIcon from '../../assets/images/amenities/taxi.svg';
import AirportIcon from '../../assets/images/amenities/airport.svg';
import FuelStationIcon from '../../assets/images/amenities/fuel-station.svg';
import WifiIcon from '../../assets/images/amenities/wifi.svg';
import ToiletIcon from '../../assets/images/amenities/wc.svg';

const amenityIcons: { [key: string]: string } = {
  CAFE: CafeIcon,
  DRINKS: DrinksIcon,
  FAST_FOOD: FastFoodIcon,
  GROCERY_SHOPPING: GroceryShoppingIcon,
  SUPERMARKET: GroceryShoppingIcon,
  SHOPPING: ShoppingIcon,
  MALL: ShoppingIcon,
  SIGHTSEEING: SightseeingIcon,
  MUSEUM: SightseeingIcon,
  LEISURE: LeisureIcon,
  SPORT: LeisureIcon,
  RECREATION_AREA: LeisureIcon,
  NATURE: LeisureIcon,
  RESTAURANT: RestaurantIcon,
  HOTEL: HotelIcon,
  BUS_STOP: TransportIcon,
  TRAM_STOP: TransportIcon,
  METRO_STATION: TransportIcon,
  TRAIN_STATION: TransportIcon,
  BIKE_SHARING: BikeSharingIcon,
  TAXI_STAND: TaxiIcon,
  AIRPORT: AirportIcon,
  PARKING_LOT: ParkingIcon,
  CARPOOL_PARKING: ParkingIcon,
  FUEL_STATION: FuelStationIcon,
  WIFI: WifiIcon,
  TOILETS: ToiletIcon,
};

export const getAmenityIcon = (amenity: AmenityModel) => {
  if (amenityIcons[amenity.category]) {
    return amenityIcons[amenity.category];
  }

  return DefaultIcon;
};

export const getAmenityIconByCategory = (category: string) => {
  switch (category) {
    case 'CAFE':
      return CafeIcon;
    case 'DRINKS':
      return DrinksIcon;
    case 'FAST_FOOD':
      return FastFoodIcon;
    case 'GROCERY_SHOPPING':
    case 'SUPERMARKET':
      return GroceryShoppingIcon;
    case 'SHOPPING':
    case 'MALL':
      return ShoppingIcon;
    case 'SIGHTSEEING':
    case 'MUSEUM':
      return SightseeingIcon;
    case 'LEISURE':
    case 'SPORT':
    case 'RECREATION_AREA':
    case 'NATURE':
      return LeisureIcon;
    case 'RESTAURANT':
      return RestaurantIcon;
    case 'HOTEL':
      return HotelIcon;
    case 'BUS_STOP':
    case 'TRAM_STOP':
    case 'METRO_STATION':
    case 'TRAIN_STATION':
      return TransportIcon;
    case 'BIKE_SHARING':
      return BikeSharingIcon;
    case 'TAXI_STAND':
      return TaxiIcon;
    case 'AIRPORT':
      return AirportIcon;
    case 'PARKING_LOT':
    case 'CARPOOL_PARKING':
      return ParkingIcon;
    case 'FUEL_STATION':
      return FuelStationIcon;
    case 'WIFI':
      return WifiIcon;
    case 'TOILETS':
      return ToiletIcon;
    default:
      return DefaultIcon;
  }
};

// **************************** MARKERS
// Show markers on map
export const showOnMap = (location: LocationFullModel, map: google.maps.Map) => {
  const markers = [];

  for (let i = 0; i < location.amenities.length; i++) {
    const marker = new google.maps.Marker({
      position: {
        lat: location.amenities[i].coordinates.latitude,
        lng: location.amenities[i].coordinates.longitude,
      },
      label: {
        text: location.amenities[i].name
          ? location.amenities[i].name
          : getReadableLabel(location.amenities[i].category),
        className: 'amenityMarkerTooltip',
      },
      icon: {
        scaledSize: new google.maps.Size(30, 30),
        url: getAmenityIcon(location.amenities[i]),
      },
      map: map,
      cursor: 'none',
      clickable: false,
    });

    markers.push(marker);
  }

  return markers;
};

const getReadableLabel = (label: string) => {
  const lowercased = label.replaceAll('_', ' ').toLowerCase();

  return lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
};

// Remove Markers
export const removeMarkers = (amenityMarkers: google.maps.Marker[]) => {
  for (let i = 0; i < amenityMarkers.length; i++) {
    amenityMarkers[i].setMap(null);
  }

  return [];
};

// Show marker and render polyline
export const renderAmenityPolylineAndPin = (amenity: AmenityModel, map: google.maps.Map) => {
  const amenityMarker = new google.maps.Marker({
    position: {
      lat: amenity.coordinates.latitude,
      lng: amenity.coordinates.longitude,
    },
    label: {
      text: amenity.name ? amenity.name : getReadableLabel(amenity.category),
      className: 'amenityMarkerTooltip',
    },
    icon: {
      scaledSize: new google.maps.Size(30, 30),
      url: getAmenityIcon(amenity),
    },
    map: map,
    cursor: 'none',
    clickable: false,
  });

  // Safari doesn't support icons as path (in our case 'dots')
  // So if Browser is Safari, then we render normal polyline
  if (checkSafari()) {
    const amenityPolyline = new google.maps.Polyline({
      path: google.maps.geometry.encoding.decodePath(amenity.polyline),
      zIndex: 52,
      clickable: false,
      strokeColor: '#33CCFF',
      strokeOpacity: 1.0,
      strokeWeight: 5,
      map: map,
    });

    return {
      marker: [amenityMarker],
      polyline: amenityPolyline,
    };
  } else {
    var lineSymbol: google.maps.Symbol = {
      path: 'M 0,0 0,0',
      strokeOpacity: 1,
      scale: 10,
      strokeColor: '#4C97FF',
    };
    const amenityPolyline = new google.maps.Polyline({
      path: google.maps.geometry.encoding.decodePath(amenity.polyline),
      zIndex: 52,
      strokeOpacity: 0,
      clickable: false,
      icons: [
        {
          icon: lineSymbol,
          offset: '0',
          repeat: '20px',
        },
      ],
      map: map,
    });

    return {
      marker: [amenityMarker],
      polyline: amenityPolyline,
    };
  }
};

export const removeAmenityPolyline = (polyline: google.maps.Polyline) => {
  polyline.setMap(null);
};
