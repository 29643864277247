import styled from '@emotion/styled';
import { getVar } from '../../theme/ui-variables/ui-variables';
import { getColor } from '../../theme/colors/colors';
import { Button } from '@material-ui/core';

const SidebarContainer = styled.div`
  width: ${getVar('sidebarWidth')};
  height: 100%;
  background-color: ${getColor('surface')};
  padding: 0;
  position: relative;
  box-shadow: 4px 0px 32px ${getColor('shadow', 0.1)};
  z-index: 4;

  @media screen and (max-width: 1100px) {
    position: absolute;
    top: 0;
    left: calc(-1 * ${getVar('sidebarWidth')});
    transition: 0.5s;

    &.opened {
      left: 0;
    }
  }

  @media screen and (max-width: 550px) {
    left: 0;
    top: 0;
    height: ${getVar('sidebarHeaderHeight')};
    box-shadow: 0px 4px 32px ${getColor('shadow', 0.1)};
    overflow: hidden;
    transition: 0.5s;

    &.opened {
      height: 100%;
      overflow: unset;
    }
  }
`;

const LogoAndLanguageContainer = styled.div`
  width: 100%;
  height: ${getVar('sidebarHeaderHeight')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${getVar('sidebarPadding')};
`;

const FullLogo = styled.img`
  height: 4rem;
  user-select: none;
`;

const SidebarContent = styled.div`
  width: 100%;
  height: calc(100% - ${getVar('sidebarHeaderHeight')});
  padding: ${getVar('sidebarPadding')};
  padding-top: 5.5rem;
  padding-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    padding-top: ${getVar('sidebarPadding')};
    height: calc(100% - ${getVar('sidebarHeaderHeight')} - ${getVar('sidebarFooterHeight')});
  }
`;

const IntroText = styled.p`
  width: 60%;
  margin-top: 0.8rem;
`;

const FromAndToContainer = styled.div`
  margin: 3rem 0;
  flex: 1;
`;

const StyledConfigButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;
  padding: 1.5rem 1.8rem 1.5rem 1.9rem !important;
  border-radius: ${getVar('borderRadius')} !important;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 18px;
  }
`;

const StyledConfigText = styled.p`
  margin-left: 1.5rem;
  margin-top: 0.5rem;
`;

const SidebarFooter = styled.div`
  width: 100%;
  height: ${getVar('sidebarFooterHeight')};
  min-height: ${getVar('sidebarFooterHeight')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    margin-top: 6rem;
  }
`;

const BacklinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    display: flex;
    align-items: center;
    color: ${getColor('label')};
    font-size: 1.4rem;

    &:hover {
      color: ${getColor('primary')};
      text-decoration: underline;
    }

    svg {
      width: 1.8rem;
      height: 1.8rem;
      margin-bottom: 0.4rem;
      margin-left: 0.5rem;
    }
  }
`;

const LegalContainer = styled.div`
  min-height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const FooterLink = styled.a`
  font-size: 1.2rem;
  color: ${getColor('textLight')};
  margin-right: 1rem;
  cursor: pointer;
`;

const SidebarToggleButton = styled(Button)`
  display: none !important;

  @media screen and (max-width: 1100px) {
    display: flex !important;
  }

  @media screen and (max-width: 550px) {
    display: none !important;
  }
`;

/* ----------- TABLET ----------- */
const TabletSidebar = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 7rem;
  height: 100%;
  background-color: ${getColor('surface')};
  padding: 0;
  box-shadow: 4px 0px 32px ${getColor('shadow', 0.1)};
  z-index: 3;

  @media screen and (max-width: 1100px) {
    display: block;
  }

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

const LogoIconImage = styled.img`
  display: block;
  width: 3rem;
  margin: 2.5rem auto 3rem;
`;

const TabletSidebarContent = styled.div`
  width: 100%;
  height: calc(100% - ${getVar('sidebarHeaderHeight')} - 6rem);
  padding: 16rem 0.75rem 0;

  @media screen and (max-height: 700px) {
    padding: 1rem 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const InputFocusButton = styled(Button)`
  width: 5.5rem;
  height: 5.5rem;
  min-width: unset !important;
  border-radius: ${getVar('borderRadius')} !important;
  box-shadow: inset 0px 0px 0px 1px ${getColor('primaryDisabled')};
  margin-bottom: 1rem !important;
`;

const StepsButton = styled(InputFocusButton)`
  position: absolute !important;
  top: calc(50% - 3rem);
  transform: translateY(-50%);

  &.no-border {
    box-shadow: unset;
  }

  @media screen and (max-height: 700px) {
    position: relative !important;
    top: unset;
    transform: unset;
  }
`;

const TabletConfigButton = styled(InputFocusButton)`
  box-shadow: none;
  margin-top: 2rem !important;

  img {
    width: 18px;
    height: 18px;
  }
`;

const MobileToggle = styled.div`
  display: none;
  width: 100%;
  height: 6rem;
  background: ${getColor('surface')};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  box-shadow: 0px -4px 32px ${getColor('shadow', 0.1)};
  padding: 1rem 2rem;
  align-items: center;
  //TODO: Switch to 'space-between' if we get 'Open in APP button'
  justify-content: center;

  @media screen and (max-width: 550px) {
    display: flex;
  }
`;

const MobileToggleButton = styled(Button)`
  width: 48%;
  height: 100%;
  border-radius: 12px !important;
  border: 1px solid ${getColor('primary')} !important;

  h5 {
    height: 2rem;
    margin-left: 0.5rem;
  }
`;

export const StyledSidebar = {
  SidebarContainer,
  LogoAndLanguageContainer,
  FullLogo,
  SidebarContent,
  IntroText,
  FromAndToContainer,
  StyledConfigButton,
  IconAndTextContainer,
  StyledConfigText,
  SidebarFooter,
  BacklinkContainer,
  LegalContainer,
  LinksContainer,
  FooterLink,
  SidebarToggleButton,
  TabletSidebar,
  LogoIconImage,
  TabletSidebarContent,
  StepsButton,
  TabletConfigButton,
  MobileToggle,
  MobileToggleButton,
  InputFocusButton,
};
