import BrokenCloudsDayIcon from '../../assets/images/weather/broken-clouds-day.svg';
import BrokenCloudsNightIcon from '../../assets/images/weather/broken-clouds-night.svg';
import ClearSkyDayIcon from '../../assets/images/weather/clear-sky-day.svg';
import ClearSkyNightIcon from '../../assets/images/weather/clear-sky-night.svg';
import FewCloudsDayIcon from '../../assets/images/weather/few-clouds-day.svg';
import FewCloudsNightIcon from '../../assets/images/weather/few-clouds-night.svg';
import MistIcon from '../../assets/images/weather/mist.svg';
import RainDayIcon from '../../assets/images/weather/rain-day.svg';
import RainNightIcon from '../../assets/images/weather/rain-night.svg';
import ScatteredCloudsIcon from '../../assets/images/weather/scattered-clouds.svg';
import ShowerRainDayIcon from '../../assets/images/weather/shower-rain-day.svg';
import ShowerRainNightIcon from '../../assets/images/weather/shower-rain-night.svg';
import SnowIcon from '../../assets/images/weather/snow.svg';
import ThunderstormDayIcon from '../../assets/images/weather/thunderstorm-day.svg';
import ThunderstormNightIcon from '../../assets/images/weather/thunderstorm-night.svg';
import { WeatherModel } from '../models/planner/weather.model';
import moment from 'moment';

export const getWeatherIcon = (weather: WeatherModel, date: any) => {
  if (weather && weather.weather_conditions && weather.weather_conditions.length) {
    const momentTimeString = moment(date).format('HH:mm:ss');
    const momentTime = moment(momentTimeString, 'HH:mm:ss');
    const startTime = moment('06:00:00', 'HH:mm:ss');
    const endTime = moment('20:00:00', 'HH:mm:ss');
    const isDay = momentTime.isBetween(startTime, endTime);

    switch (weather.weather_conditions[0]) {
      case 'BROKEN_CLOUDS':
        return isDay ? BrokenCloudsDayIcon : BrokenCloudsNightIcon;
      case 'CLEAR_SKY':
        return isDay ? ClearSkyDayIcon : ClearSkyNightIcon;
      case 'FEW_CLOUDS':
        return isDay ? FewCloudsDayIcon : FewCloudsNightIcon;
      case 'MIST':
        return MistIcon;
      case 'RAIN':
        return isDay ? RainDayIcon : RainNightIcon;
      case 'SCATTERED_CLOUDS':
        return ScatteredCloudsIcon;
      case 'SHOWER_RAIN':
        return isDay ? ShowerRainDayIcon : ShowerRainNightIcon;
      case 'SNOW':
        return SnowIcon;
      case 'THUNDERSTORM':
        return isDay ? ThunderstormDayIcon : ThunderstormNightIcon;
    }
  }

  return undefined;
};
