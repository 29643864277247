/*************************************************  Imports  *************************************************/
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfiguratorDropdownModel } from '../../../../models/car-config/configurator-dropdown.model';

/*************************************************  Component  *************************************************/
export const ConfiguratorDropdown = ({
  label,
  values,
  selected,
  onValueChange,
  disabledOptionFilter,
  suffix,
  dataCy,
  additionalSuffix,
}: ConfiguratorDropdownModel) => {
  const [selectedValue, setSelectedValue] = useState(selected);

  const { t } = useTranslation();

  const handleValueChange = (event: any) => {
    setSelectedValue(event.target.value);
    onValueChange(event.target.value);
  };

  useEffect(() => {
    if (selected) {
      setSelectedValue(selected);
    } else {
      setSelectedValue(values[0]);
    }
  }, [values, selected]);

  const checkIfSelectedValueIsValid = () => {
    return values.indexOf(selectedValue) === -1 ? values[0] : selectedValue;
  };
  /*************************************************  Template  *************************************************/
  return (
    <>
      {values.length ? (
        <FormControl fullWidth={true} variant={'outlined'}>
          <InputLabel id={`label${label}`} className="customSelect">
            {t(label)}
          </InputLabel>
          <Select
            labelId={`label${label}`}
            id={`id${label}`}
            value={checkIfSelectedValueIsValid()}
            defaultValue={values[0]}
            onChange={handleValueChange}
            variant="outlined"
            color="primary"
            data-cy={dataCy}
          >
            {values.map((val) => {
              return (
                <MenuItem
                  key={val}
                  value={val}
                  disabled={disabledOptionFilter && disabledOptionFilter(val)}
                  data-cy={dataCy + '-option'}
                >
                  {val}
                  {suffix ? suffix : null}
                  {additionalSuffix && val === additionalSuffix.conditionValueForSuffix
                    ? ` ${additionalSuffix.text}`
                    : null}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
    </>
  );
};
