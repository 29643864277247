export enum TYPES {
  DOMESTIC_F = 'DOMESTIC_F',
  IEC_62196_T1 = 'IEC_62196_T1',
  IEC_62196_T1_COMBO = 'IEC_62196_T1_COMBO',
  IEC_62196_T2 = 'IEC_62196_T2',
  IEC_62196_T2_COMBO = 'IEC_62196_T2_COMBO',
  IEC_62196_T3A = 'IEC_62196_T3A',
  IEC_62196_T3C = 'IEC_62196_T3C',
  CHADEMO = 'CHADEMO',
}

export enum EVSE_STATUSES {
  AVAILABLE = 'AVAILABLE',
  BLOCKED = 'BLOCKED',
  CHARGING = 'CHARGING',
  INOPERATIVE = 'INOPERATIVE',
  OUTOFORDER = 'OUTOFORDER',
  PLANNED = 'PLANNED',
  REMOVED = 'REMOVED',
  RESERVED = 'RESERVED',
  UNKNOWN = 'UNKNOWN',
}
