import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { axiosData } from '../../../api/axios-data';
import { PaymentServiceModel } from '../../models/locations-full/payment-service.model';
import { chargingStationService } from '../../../api/api';

export const getPaymentServices = (): Promise<PaymentServiceModel[]> => {
  return axiosData<PaymentServiceModel[]>(
    (): AxiosObservable<PaymentServiceModel[]> => {
      return chargingStationService.get('/payment-services');
    }
  )().toPromise();
};
