/*************************************************  Imports  *************************************************/
import { useContext, useEffect, useState } from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Button } from '@material-ui/core';

import fullLogo from '../../../assets/images/logo/logo-beta.svg';
import settingsIcon from '../../../assets/images/settings.svg';

import { useTranslation } from 'react-i18next';
import { GeolocationInput } from './origin-and-destination/GeolocationInput';

import { LanguageSwitcher } from './language-switcher/LanguageSwitcher';
import { FooterLinksDialog } from './footer-links-dialog/FooterLinksDialog';
import { CalculateRouteButton } from './calculate-route-button/CalculateRouteButton';
import { CarConfigModel } from '../../models/car-config/car-config.model';
import { RouteLocationListOverlay } from './route-location-list-overlay/RouteLocationListOverlay';
import { AppContext } from '../../app-context';

import ArrowRightIcon from '../../../assets/images/arrow-right.svg';
import LogoIcon from '../../../assets/images/logo/logo-icon.svg';
import OriginIcon from '../../../assets/images/pin.svg';
import DestinationIcon from '../../../assets/images/flag.svg';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ListIcon from '@material-ui/icons/List';
import ArrowLeftIcon from '../../../assets/images/arrow-left.svg';
import { Configurator } from './configurator/Configurator';
import { useAppAnalytics } from 'analytics';
import { StyledSidebar } from './Sidebar.styles';
import { OriginAndDestinationOrderToggle } from './origin-and-destination/order-toggle/OriginAndDestinationOrderToggle';
import { AddStopButton } from './origin-and-destination/add-stop/AddStopButton';
import { RouteStopInputs } from './origin-and-destination/add-stop/RouteStopInputs';

/*************************************************  Component  *************************************************/
export const Sidebar = () => {
  /*************************************************  State  *************************************************/
  const [isConfiguratorOpened, setIsConfiguratorOpened] = useState(false);

  const { t } = useTranslation();

  const [dialogData, setDialogData] = useState({
    isOpen: false,
    content: '',
  });

  const [carConfig, setCarConfig] = useState<CarConfigModel | null>(null);

  const { appContextValue } = useContext(AppContext);

  // Used for toggling on tablet and mobiles
  const [isSidebarClosed, setIsSidebarClosed] = useState(true);
  const [originFocus, setOriginFocus] = useState(false);
  const [destinationFocus, setDestinationFocus] = useState(false);

  const { trackEvent } = useAppAnalytics();

  useEffect(() => {}, [t]);

  /*************************************************  Logic  *************************************************/
  const openDialogHandler = (dialogName: string) => {
    if (dialogName === 'privacy') {
      trackEvent({ action: 'PrivacyPolicyIsOpened', category: 'general' });
      setDialogData({ isOpen: true, content: 'privacy' });
    } else {
      trackEvent({ action: 'ImprintIsOpened', category: 'general' });
      setDialogData({ isOpen: true, content: 'imprint' });
    }
  };

  const closeDialogHandler = () => {
    setDialogData({ isOpen: false, content: '' });
  };

  const onConfiguratorSave = (config: CarConfigModel) => {
    setCarConfig(config);
  };

  /* --------------- Tablet Logic --------------- */
  const resetSidebar = () => {
    setIsSidebarClosed(true);
    setOriginFocus(false);
    setDestinationFocus(false);
  };

  const openMenuAndFocusOrigin = () => {
    setIsSidebarClosed(false);
    setOriginFocus(true);
  };

  const openMenuAndFocusDestination = () => {
    setIsSidebarClosed(false);
    setDestinationFocus(true);
  };

  const openMenuAndConfigurator = () => {
    setIsSidebarClosed(false);
    setIsConfiguratorOpened(true);
  };

  /* --------------- Mobile Logic --------------- */
  const renderMobileButtonContent = () => {
    if (isSidebarClosed) {
      if (appContextValue.route) {
        return (
          <>
            <ListIcon />
            <h5>{t('sidebar.steps')}</h5>
          </>
        );
      } else {
        return (
          <>
            <MenuIcon />
            <h5>{t('sidebar.menu')}</h5>
          </>
        );
      }
    } else {
      return (
        <>
          <MapOutlinedIcon />
          <h5>{t('sidebar.map')}</h5>
        </>
      );
    }
  };

  /*************************************************  Template  *************************************************/
  return (
    <>
      {/* ---------------------------------------- TABLET SIDEBAR ---------------------------------------- */}
      <StyledSidebar.TabletSidebar>
        <StyledSidebar.LogoIconImage src={LogoIcon} alt="Electric Routes Logo Icon" />

        <StyledSidebar.TabletSidebarContent>
          {appContextValue.route ? (
            <StyledSidebar.StepsButton
              onClick={() => setIsSidebarClosed(false)}
              data-cy="sidebar-tablet-route"
            >
              <ListIcon color="primary" />
            </StyledSidebar.StepsButton>
          ) : !isConfiguratorOpened ? (
            <>
              <StyledSidebar.InputFocusButton
                onClick={openMenuAndFocusOrigin}
                data-cy="sidebar-tablet-origin"
              >
                <img src={OriginIcon} />
              </StyledSidebar.InputFocusButton>

              <StyledSidebar.InputFocusButton
                onClick={openMenuAndFocusDestination}
                data-cy="sidebar-tablet-destination"
              >
                <img src={DestinationIcon} />
              </StyledSidebar.InputFocusButton>

              <StyledSidebar.TabletConfigButton
                onClick={openMenuAndConfigurator}
                data-cy="sidebar-tablet-configuration"
              >
                <img src={settingsIcon} />
              </StyledSidebar.TabletConfigButton>
            </>
          ) : (
            <StyledSidebar.StepsButton
              className="no-border"
              onClick={openMenuAndConfigurator}
              data-cy="sidebar-tablet-configuration"
            >
              <img src={settingsIcon} />
            </StyledSidebar.StepsButton>
          )}
        </StyledSidebar.TabletSidebarContent>

        <Button
          className="toggleSidebarButton"
          size="medium"
          onClick={() => {
            setIsSidebarClosed(false);
          }}
          data-cy="sidebar-tablet-show"
        >
          <img src={ArrowRightIcon} />
        </Button>
      </StyledSidebar.TabletSidebar>

      {/* ---------------------------------------- DEFAULT SIDEBAR ---------------------------------------- */}
      <StyledSidebar.SidebarContainer className={isSidebarClosed ? '' : 'opened'}>
        <StyledSidebar.LogoAndLanguageContainer>
          <StyledSidebar.FullLogo src={fullLogo} alt="Electric Routes Logo" />

          <LanguageSwitcher />
        </StyledSidebar.LogoAndLanguageContainer>

        <StyledSidebar.SidebarContent>
          <div>
            <h1>{t('sidebar.title')}</h1>
            <StyledSidebar.IntroText className="small">
              {t('sidebar.subtitle')}
            </StyledSidebar.IntroText>

            <Box display="flex">
              <StyledSidebar.FromAndToContainer>
                <GeolocationInput inputFocus={originFocus} variant="origin" />
                <RouteStopInputs />
                <GeolocationInput inputFocus={destinationFocus} variant="destination" />
              </StyledSidebar.FromAndToContainer>
              <OriginAndDestinationOrderToggle />
            </Box>
            <AddStopButton />

            <StyledSidebar.StyledConfigButton
              onClick={() => {
                setIsConfiguratorOpened(true);
              }}
              data-cy="configuration-button"
            >
              <StyledSidebar.IconAndTextContainer>
                <img src={settingsIcon} alt="Settings Icon" />
                <StyledSidebar.StyledConfigText>
                  {t('sidebar.carAndRouteSettings')}
                </StyledSidebar.StyledConfigText>
              </StyledSidebar.IconAndTextContainer>

              <ArrowForwardIosIcon />
            </StyledSidebar.StyledConfigButton>

            <CalculateRouteButton carConfig={carConfig} />
          </div>

          <StyledSidebar.SidebarFooter>
            <StyledSidebar.BacklinkContainer>
              <a href="https://www.electricroutes.com/" target="_blank" rel="noopener noreferrer">
                <span>{t('sidebar.electricRoutesWebsite')}</span>

                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 2.5H17.5V7.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.33301 11.6667L17.4997 2.5"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </StyledSidebar.BacklinkContainer>

            <StyledSidebar.LegalContainer>
              <StyledSidebar.LinksContainer>
                <StyledSidebar.FooterLink
                  onClick={() => openDialogHandler('privacy')}
                  data-cy="sidebar-footer-link-privacy"
                >
                  {t('sidebar.privacy')}
                </StyledSidebar.FooterLink>
                <StyledSidebar.FooterLink
                  onClick={() => openDialogHandler('imprint')}
                  data-cy="sidebar-footer-link-imprint"
                >
                  {t('sidebar.imprint')}
                </StyledSidebar.FooterLink>
              </StyledSidebar.LinksContainer>

              <StyledSidebar.SidebarToggleButton
                className={
                  appContextValue.route
                    ? 'toggleSidebarButton close routeLocationList'
                    : 'toggleSidebarButton close'
                }
                size="medium"
                onClick={resetSidebar}
                data-cy="sidebar-tablet-hide"
              >
                <img src={ArrowLeftIcon} />
              </StyledSidebar.SidebarToggleButton>
            </StyledSidebar.LegalContainer>
          </StyledSidebar.SidebarFooter>
        </StyledSidebar.SidebarContent>

        <FooterLinksDialog
          open={dialogData.isOpen}
          onClose={() => closeDialogHandler()}
          content={dialogData.content}
        />

        {/* ********** Overlays ********** */}
        <Configurator
          onConfiguratorSave={onConfiguratorSave}
          setIsOverlayOpened={setIsConfiguratorOpened}
          isConfiguratorOpened={isConfiguratorOpened}
        />

        {appContextValue.route ? <RouteLocationListOverlay /> : null}
      </StyledSidebar.SidebarContainer>

      {/* ---------------------------------------- MOBILE SIDEBAR ---------------------------------------- */}
      <StyledSidebar.MobileToggle>
        <StyledSidebar.MobileToggleButton
          color="primary"
          variant="outlined"
          size="medium"
          onClick={() => {
            setIsSidebarClosed(!isSidebarClosed);
          }}
          data-cy="sidebar-mobile-toggle"
        >
          {renderMobileButtonContent()}
        </StyledSidebar.MobileToggleButton>
      </StyledSidebar.MobileToggle>
    </>
  );
};
