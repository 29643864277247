/*************************************************  Imports  *************************************************/
import React, { useContext, useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import ConnectorsInfo from './connectors-info/ConnectorsInfo';

import { useAppAnalytics } from 'analytics';
import { locationPinInfoService } from '../../../services/location-dialog.service';
import { LocationPinPopupModel } from '../../../models/locations-full/location-pin-popup.model';
import { useTranslation } from 'react-i18next';

import ProfilePicture from '../../../../assets/images/profile.svg';
import CoverPicture from '../../../../assets/images/cover.svg';
import StarGold from '../../../../assets/images/star-gold.svg';
import StarGrey from '../../../../assets/images/star-grey.svg';
import { DetailsInfo } from './details/DetailsInfo';
import { ConnectorModel } from '../../../models/locations-full/connector.model';
import { ImageGalleryModal } from './image-gallery-modal/ImageGalleryModal';
import { AppContext, AppContextModel } from '../../../app-context';
import {
  removeMarkers,
  renderAmenityPolylineAndPin,
  showOnMap,
  removeAmenityPolyline,
} from '../../../util/amenity.util';
import { AmenityModel } from '../../../models/locations-full/amenity.model';
import * as _ from 'lodash';
import { CommunityInfo } from './community-tab/CommunityInfo';
import { StyledLocationPinPopup } from './LocationPinPopup.styles';

/*************************************************  Component  *************************************************/
export const LocationPinPopup = () => {
  /*************************************************  State  *************************************************/
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);
  const [info, setInfo] = useState<LocationPinPopupModel | null>(null);
  const [connectorsInfo, setConnectorsInfo] = useState<ConnectorModel[] | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [stars, setStars] = useState<any>(null);

  // Amenities
  const [amenityMarkers, setAmenityMarkers] = useState<google.maps.Marker[]>([]);
  const [amenityPolyline, setAmenityPolyline] = useState<google.maps.Polyline | null>(null);
  const [selectedAmenity, setSelectedAmenity] = useState<string | null>(null);

  const { appContextValue, setAppContextValue } = useContext(AppContext);

  const { trackEvent } = useAppAnalytics();

  const { t } = useTranslation();

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  Logic  *************************************************/
  const handleClose = () => {
    resetAmenities();
    setSelectedAmenity(null);
    locationPinInfoService.closeLocationInfoPopup();

    if (info?.location?.id) {
      // trigger card deselection in the RouteLocationList component
      updateContext('selectedMarker', {
        locationId: info?.location.id,
        show: false,
      });
    }
  };

  const handleChange = (event: any, newValue: number) => {
    trackEvent({
      action: 'Selected tab - ' + event.currentTarget.innerText,
      category: 'chargingStations',
    });
    setSelectedTab(newValue);
  };

  const returnProfilePhoto = () => {
    const locationImages = info?.location.images;

    if (locationImages) {
      if (locationImages.length === 1) {
        return locationImages[0].url;
      } else {
        const chargerPhoto = locationImages.filter((img) => img.category === 'CHARGER');
        return chargerPhoto.length > 0 ? chargerPhoto[0].url : locationImages[0].url;
      }
    } else {
      return ProfilePicture;
    }
  };

  const returnCoverPhoto = () => {
    const locationImages = info?.location.images;

    if (locationImages) {
      if (locationImages.length === 1) {
        return locationImages[0].url;
      } else {
        const locationPhoto = locationImages.filter((img) => img.category === 'LOCATION');
        return locationPhoto.length > 0 ? locationPhoto[0].url : locationImages[0].url;
      }
    } else {
      return CoverPicture;
    }
  };

  // ****************************** Amenity Markers
  const showMarkersOnMap = () => {
    if (info?.location) {
      removeMarkers(amenityMarkers);
      setSelectedAmenity(null);

      if (amenityPolyline) {
        removeAmenityPolyline(amenityPolyline);
        setAmenityPolyline(null);
      }

      setAmenityMarkers(showOnMap(info.location, appContextValue.map));
    }
  };

  const renderAmenityPolyline = (amenity: AmenityModel) => {
    removeMarkers(amenityMarkers);
    setSelectedAmenity(amenity.id);

    if (amenityPolyline) {
      removeAmenityPolyline(amenityPolyline);
    }

    const result = renderAmenityPolylineAndPin(amenity, appContextValue.map);

    setAmenityMarkers(result.marker);
    setAmenityPolyline(result.polyline);
  };

  const resetAmenities = () => {
    setAmenityMarkers(removeMarkers(amenityMarkers));

    if (amenityPolyline) {
      removeAmenityPolyline(amenityPolyline);
      setAmenityPolyline(null);
    }
  };
  /*************************************************  useEffect  *************************************************/
  useEffect(() => {
    const sub = locationPinInfoService.locationPinInfo.subscribe(
      (locationInfo: LocationPinPopupModel | null) => {
        setIsOpen(!!locationInfo);
        setInfo(locationInfo);
        resetAmenities();

        // transform data for Connectors tab
        const updatedConnectors = [];
        if (locationInfo) {
          for (let evse of locationInfo.location.evses) {
            for (let connector of evse.connectors) {
              updatedConnectors.push({
                ...connector,
                max_electric_power_kw: Math.round(connector.max_electric_power / 1000),
                evse_uid: evse.uid,
                status: evse.status,
              });
            }
          }
          setConnectorsInfo(updatedConnectors);
        }
      }
    );
    return () => {
      sub.unsubscribe();
    };
  });

  useEffect(() => {
    if (info?.location?.rating && info?.location?.rating?.stars) {
      const gold = _.times(info.location.rating.stars).map((value: number) => {
        return <img key={'gold_' + value} src={StarGold} />;
      });

      let allStars = gold;

      if (info.location.rating.stars < 5) {
        const grey = _.times(5 - info.location.rating.stars).map((value: number) => {
          return <img key={'grey_' + value} src={StarGrey} />;
        });
        allStars = gold.concat(grey);
      }

      setStars(allStars);
    }
  }, [info?.location?.rating]);

  /*************************************************  Tab Panel  *************************************************/
  // https://material-ui.com/components/tabs  ---- Simple tabs example
  function TabPanel(props: { children?: any; index: any; value: any; isDetails?: boolean }) {
    const { children, value, index, ...other } = props;

    return (
      <StyledLocationPinPopup.StyledTabPanel
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className={value === index && props.isDetails ? 'details' : ''}
        {...other}
      >
        {value === index && <>{children}</>}
      </StyledLocationPinPopup.StyledTabPanel>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const resetGalleryState = () => {
    setIsGalleryOpen(false);
  };

  /*************************************************  Template  *************************************************/
  return (
    <Dialog
      open={isOpen}
      className="locationPinInfoDialog"
      hideBackdrop={true}
      disableEnforceFocus={true}
      disableAutoFocus={true}
    >
      <StyledLocationPinPopup.StyledCloseButton
        variant="contained"
        size="medium"
        onClick={handleClose}
        data-cy="location-popup-close-button"
      >
        <StyledLocationPinPopup.StyledCloseIcon />
      </StyledLocationPinPopup.StyledCloseButton>

      <StyledLocationPinPopup.CoverPhotoContainer
        className={!info?.location.images || !info?.location.images.length ? 'noPointer' : ''}
        onClick={() => setIsGalleryOpen(true)}
        data-cy="location-popup-cover-photo"
      >
        <StyledLocationPinPopup.Photo
          src={returnCoverPhoto()}
          alt={info?.location.name + ' photo'}
        />
      </StyledLocationPinPopup.CoverPhotoContainer>

      <StyledLocationPinPopup.ProfilePhotoContainer
        className={!info?.location.images || !info?.location.images.length ? 'noPointer' : ''}
        onClick={() => setIsGalleryOpen(true)}
        data-cy="location-popup-profile-photo"
      >
        <StyledLocationPinPopup.ProfilePhoto
          src={returnProfilePhoto()}
          alt={info?.location.name + ' photo'}
        />
      </StyledLocationPinPopup.ProfilePhotoContainer>

      <StyledLocationPinPopup.ContentContainer>
        <StyledLocationPinPopup.OperatorHeading>
          {info?.location.charging_point_operator.name}
        </StyledLocationPinPopup.OperatorHeading>
        <StyledLocationPinPopup.Header>
          <StyledLocationPinPopup.StyledH4>
            {info?.location.address + ', ' + info?.location.city}
          </StyledLocationPinPopup.StyledH4>

          <div>
            <StyledLocationPinPopup.NumberOfStars>
              {info?.location?.rating?.stars ? info?.location?.rating?.stars + '.0' : null}
            </StyledLocationPinPopup.NumberOfStars>
            {info?.location?.rating?.stars ? stars : null}
            <StyledLocationPinPopup.NumberOfReviews>
              {info?.location?.rating?.number_of_reviews
                ? '(' + info?.location?.rating?.number_of_reviews + ')'
                : null}
            </StyledLocationPinPopup.NumberOfReviews>
          </div>
        </StyledLocationPinPopup.Header>

        <StyledLocationPinPopup.StyledTabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <StyledLocationPinPopup.StyledTab
            label={t('locationModal.details')}
            {...a11yProps(0)}
            data-cy="location-popup-details-tab"
          />
          <StyledLocationPinPopup.StyledTab
            label={t('locationModal.connectors')}
            {...a11yProps(1)}
            data-cy="location-popup-connectors-tab"
          />
          <StyledLocationPinPopup.StyledTab
            label={t('locationModal.community')}
            {...a11yProps(2)}
            data-cy="location-popup-community-tab"
          />
        </StyledLocationPinPopup.StyledTabs>

        <TabPanel value={selectedTab} index={0} isDetails={true}>
          {info?.location ? (
            <DetailsInfo
              location={info?.location}
              showOnMap={showMarkersOnMap}
              renderAmenityPolyline={renderAmenityPolyline}
              selectedAmenity={selectedAmenity}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <ConnectorsInfo connectors={connectorsInfo} />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <CommunityInfo rating={info?.location?.rating} />
        </TabPanel>

        {isGalleryOpen && info?.location.images && info?.location.images.length ? (
          <ImageGalleryModal
            isModalActive={isGalleryOpen}
            resetGalleryState={resetGalleryState}
            imagesInfo={info?.location}
          />
        ) : null}
      </StyledLocationPinPopup.ContentContainer>
    </Dialog>
  );
};
