export const lightTheme = {
  primary: '#0040FF',
  primaryContrast: '#FFFFFF',
  primaryHover: '#002699',
  primaryDisabled: '#CCE6FF',

  accent: '#33CCFF',
  accentContrast: '#FFFFFF',
  accentHover: '#0BA6DA',
  accentDisabled: '#E5F9FF',

  error: '#E52600',
  errorContrast: '#FFFFFF',
  errorHover: '#992500',
  errorDisabled: '#FFB199',
  errorMessage: '#F2330D',

  success: '#20DF6C',
  successContrast: '#333A4D',
  successHover: '#00993D',
  successDisabled: '#CCFFE0',

  text: '#333A4D',
  textLight: '#C2CAD6',
  textLightest: '#98A4B3',
  label: '#627084',

  surface: '#FFFFFF',
  background: '#C9C9C9',
  shadow: '#000000',
  sliderRail: '#3c3c43',
  suggestionDivider: '#F0F0F5',
  tabPanel: '#F3F4F7',
  horizontalLine: '#E1E4EA',
  statusOccupiedBackground: '#FFD0C2',
  statusOccupiedText: '#E62600',
  statusUnknownBackground: '#E1E5EA',
  statusUnknownText: '#98A4B3',
  routeListOverlayBackground: '#F5F5F5',
  drivingStyleFilterContainer: '#F6F7F9',
  carbonFootprintLabel: '#19B357',
};

export const darkTheme = {
  primary: '#0040FF',
  primaryContrast: '#FFFFFF',
  primaryHover: '#002699',
  primaryDisabled: '#CCE6FF',

  accent: '#33CCFF',
  accentContrast: '#FFFFFF',
  accentHover: '#0BA6DA',
  accentDisabled: '#E5F9FF',

  error: '#E52600',
  errorContrast: '#FFFFFF',
  errorHover: '#992500',
  errorDisabled: '#FFB199',
  errorMessage: '#F2330D',

  success: '#20DF6C',
  successContrast: '#333A4D',
  successHover: '#00993D',
  successDisabled: '#CCFFE0',

  text: '#333A4D',
  textLight: '#C2CAD6',
  textLightest: '#98A4B3',
  label: '#627084',

  surface: '#FFFFFF',
  background: '#C9C9C9',
  shadow: '#000000',
  sliderRail: '#3c3c43',
  suggestionDivider: '#F0F0F5',
  tabPanel: '#F3F4F7',
  horizontalLine: '#E1E4EA',
  statusOccupiedBackground: '#FFD0C2',
  statusOccupiedText: '#E62600',
  statusUnknownBackground: '#E1E5EA',
  statusUnknownText: '#98A4B3',
  routeListOverlayBackground: '#F5F5F5',
  drivingStyleFilterContainer: '#F6F7F9',
  carbonFootprintLabel: '#19B357',
};
