/*************************************************  Imports  *************************************************/
import styled from '@emotion/styled';
import { Button, Dialog } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Lottie from 'react-lottie';
import animationData from '../../../../assets/lotties/sad-face.json';

import { AppContext, AppContextModel } from '../../../app-context';
import { CarConfigModel } from '../../../models/car-config/car-config.model';
import { PlannerRequestModel } from '../../../models/planner/planner-request.model';
import { loaderService } from '../../../services/loader.service';
import { planRoute } from '../../../services/http/planner';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

import { routeFiltersLocalStorageService } from '../../../services/local-storage/route-filters-ls.service';
import { useAppAnalytics } from 'analytics';
import { StyledCalculateRouteButton } from './CalculateRouteButton.styles';
import { useRouteStops } from '../origin-and-destination/useRouteStops';

/*************************************************  Component  *************************************************/
export const CalculateRouteButton = (props: { carConfig: CarConfigModel | null }) => {
  /*************************************************  State  *************************************************/
  const [plannerBody, setPlannerBody] = useState<PlannerRequestModel | null>(null);

  const [isRouteErrorDialogOpen, setIsRouteErrorDialogOpen] = useState(false);

  const { appContextValue, setAppContextValue } = useContext(AppContext);

  const { stops, stopsValid } = useRouteStops();

  const { t } = useTranslation();

  const { trackEvent } = useAppAnalytics();

  const sadFaceAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  useEffect  *************************************************/
  useEffect(() => {
    if (props.carConfig !== null && appContextValue.origin && appContextValue.destination) {
      setPlannerBody({
        ...props.carConfig,
        origin: {
          longitude: appContextValue.origin.lng,
          latitude: appContextValue.origin.lat,
        },
        destination: {
          longitude: appContextValue.destination.lng,
          latitude: appContextValue.destination.lat,
        },
        include_dev_stats: true,
        include_steps: true,
        filter_payment_services:
          getUuids(routeFiltersLocalStorageService.getPaymentProviders()) || null,
        filter_charging_point_operators:
          getUuids(routeFiltersLocalStorageService.getChargingNetworks()) || null,
        filter_power_type: routeFiltersLocalStorageService.getPowerType() || null,
      });
    }
  }, [props.carConfig, appContextValue, t]);

  /*************************************************  Logic  *************************************************/
  const getUuids = (arr: any[]) => {
    return arr?.reduce((filtered: any[] = [], option: any) => {
      if (option.isChecked && option.isVisible) {
        filtered.push(option.uuid);
      }
      return filtered;
    }, []);
  };

  const getRoute = async () => {
    if (plannerBody) {
      loaderService.showLoader();
      try {
        const response = await planRoute({
          request: plannerBody,
          stops,
        });

        trackEvent({
          category: 'routeCalculation',
          action: 'Calculate route is clicked',
          name: `Origin: ${JSON.stringify(appContextValue.origin)}, Destination: ${JSON.stringify(
            appContextValue.destination
          )}`,
        });
        updateContext('route', response);
        loaderService.closeLoader();
      } catch (error) {
        loaderService.closeLoader();
        setIsRouteErrorDialogOpen(true);
      }
    }
  };

  const handleClose = () => {
    setIsRouteErrorDialogOpen(false);
  };
  /*************************************************  Template  *************************************************/
  return (
    <>
      <StyledCalculateRouteButton.StyledCalcButton
        fullWidth
        variant="contained"
        color="primary"
        disabled={
          !appContextValue.origin ||
          !appContextValue.destination ||
          props.carConfig === null ||
          !stopsValid
        }
        onClick={getRoute}
        data-cy="calculate-route-button"
      >
        <h4>{t('sidebar.calcButton')}</h4>
      </StyledCalculateRouteButton.StyledCalcButton>

      <Dialog open={isRouteErrorDialogOpen} onClose={handleClose} className="routeErrorDialog">
        <Lottie options={sadFaceAnimationOptions} height={50} width={50} />

        <StyledCalculateRouteButton.StyledH2>
          {t('plan.errorTitle')}
        </StyledCalculateRouteButton.StyledH2>
        <StyledCalculateRouteButton.StyledText>
          {t('plan.errorMessage')}
        </StyledCalculateRouteButton.StyledText>
        <StyledCalculateRouteButton.StyledButton
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleClose}
          data-cy="failed-route-dialog-button"
        >
          <h4>{t('general.okay')}</h4>
        </StyledCalculateRouteButton.StyledButton>
      </Dialog>
    </>
  );
};
