import { LocationFullModel } from '../../models/locations-full/location-full.model';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { axiosData } from '../../../api/axios-data';
import { chargingStationService } from '../../../api/api';

export const getLocationById = (locationId: string): Promise<LocationFullModel[]> => {
  return axiosData<LocationFullModel[]>(
    (): AxiosObservable<LocationFullModel[]> => {
      return chargingStationService.get(`/charging-stations?ids=${locationId}`);
    }
  )().toPromise();
};

//162770383  -- ID for station with pictures
//302009786  -- ID for station with regular hours
// 252467677, 382719695, 144818107, 28001809, 89119844 -- IDs that won't break the app
