import styled from '@emotion/styled';

const CapabilityListItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 1rem;

  span {
    margin-left: 1rem;
  }
`;

export const StyledCapabilityListItem = { CapabilityListItemContainer };
