import styled from '@emotion/styled';
import { getVar } from '../../../theme/ui-variables/ui-variables';
import { getColor } from '../../../theme/colors/colors';
import { getFont } from '../../../theme/fonts/fonts';
import DepartureIcon from '../../../../assets/images/pin-new.svg';
import ArrivalNewIcon from '../../../../assets/images/arrival-new.svg';

const ListContainer = styled.div`
  padding: 0 20px 0 24px;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - ${getVar('sidebarHeaderHeight')});
`;

const ListHeader = styled.div`
  padding-bottom: 5rem;
`;

const StyledH5 = styled.h5`
  margin: 3px 0 0 12px;
  max-width: 275px;
  overflow-wrap: break-word;
  color: ${getColor('text')};
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
`;

const LocationListInfoOuterWrapper = styled.div``;

const LocationListInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
`;

const LocationListInfo = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${getColor('text')};
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: normal;
`;

const LocationListInfoHeader = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${getColor('label')};
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: normal;
`;

const LocationListInfoSoc = styled.div`
  font-size: 14px;
  margin: 2rem 0 0 3.2rem;
  line-height: 20px;
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: normal;
`;

const StyledDark = styled.span`
  color: ${getColor('text')};
`;

const StyledGreen = styled.span`
  color: ${getColor('successHover')};
`;

const HorizontalDotSeparator = styled.div`
  &:after {
    content: '\\00B7';
    vertical-align: -25%;
    padding: 0 6px;
  }
`;

const ListBody = styled.div``;

const LocationList = styled.ul`
  padding-left: 20px;
  display: block;
`;

const Location = styled.li`
  list-style: none;
  margin: 12px auto;
  min-height: 50px;
  border-left: 2px solid ${getColor('primary')};
  padding-left: 22px;
  position: relative;

  &:last-of-type {
    border-left: 0;
    margin-top: 30px;
    margin-bottom: 0;

    &:before {
      position: absolute;
      left: -19px;
      top: -5px;
      content: ' ';
      border-top: 2px solid ${getColor('routeListOverlayBackground')};
      border-right: 8px solid ${getColor('routeListOverlayBackground')};
      border-bottom: 8px solid ${getColor('routeListOverlayBackground')};
      border-left: 8px solid ${getColor('routeListOverlayBackground')};
      background: ${getColor('routeListOverlayBackground')};
      background-repeat: no-repeat;
      transition: all 500ms ease-in-out;
    }
  }

  &:first-of-type {
    &:before {
      position: absolute;
      left: -19px;
      top: -5px;
      content: ' ';
      border-top: 2px solid ${getColor('routeListOverlayBackground')};
      border-right: 8px solid ${getColor('routeListOverlayBackground')};
      border-bottom: 8px solid ${getColor('routeListOverlayBackground')};
      border-left: 8px solid ${getColor('routeListOverlayBackground')};
      background: ${getColor('routeListOverlayBackground')};
      background-repeat: no-repeat;
      transition: all 500ms ease-in-out;
    }
  }

  &.departure {
    :before {
      background-image: url(${DepartureIcon});
      height: 24px;
      width: 24px;
      background-color: ${getColor('routeListOverlayBackground')};
      left: -21px;
      border-bottom: 28px solid ${getColor('routeListOverlayBackground')};
    }
  }

  &.arrival {
    :before {
      background-image: url(${ArrivalNewIcon});
      height: 24px;
      width: 24px;
      background-color: ${getColor('routeListOverlayBackground')};
      left: -19px;
    }
  }
`;

const LocationCard = styled.button`
  background-color: ${getColor('surface')};
  border: 1px solid ${getColor('statusUnknownBackground')};
  border-radius: 14px;
  padding: 20px 16px 20px 12px;
  margin-left: -22px;
  box-shadow: 0px 4px 64px rgba(93, 111, 129, 0.13);
  cursor: pointer;
  width: calc(100% + 22px);
  text-align: left;

  &:hover {
    border: 1px solid ${getColor('primary')} !important;
  }
`;

const ImageAndNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Address = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${getColor('text')};
`;

const TripStopAddressContainer = styled(Address)`
  margin-left: 12px;
`;

const AddressContainer = styled(Address)`
  margin-left: 32px;
`;

const LocationNoCard = styled.div`
  padding: 25px 0 28px 0;
`;

const YetAnotherContainer = styled.div`
  padding: 3rem 0 2.5rem 0;
`;

const WeatherContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const WeatherIcon = styled.div`
  margin-right: 12px;
`;

const WeatherTemperature = styled.div`
  font-size: 14px;

  &.temperature-bottom-margin {
    margin-bottom: 10px;
  }
`;

const AmenityList = styled.div`
  margin-left: 3rem;
  margin-top: 1rem;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AmenityImage = styled.img`
  margin-right: 6px;

  &:last-of-type {
    margin-right: 7px;
  }
`;

const FakeAmenity = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50px;
  background-color: ${getColor('primary')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getColor('primaryContrast')};
  padding-top: 2px;

  & span {
    font-size: 9px;
  }
`;

const StyledBolt = styled.img`
  height: 12px;
  margin-right: 1px;

  &:last-of-type {
    margin-right: 4px;
  }
`;

const ChargingSpeed = styled.span`
  color: ${getColor('text')};
  margin-left: 1.2rem;
`;

export const StyledRouteLocationList = {
  ListContainer,
  ListHeader,
  StyledH5,
  LocationListInfoOuterWrapper,
  LocationListInfoWrapper,
  LocationListInfo,
  LocationListInfoHeader,
  LocationListInfoSoc,
  StyledDark,
  StyledGreen,
  HorizontalDotSeparator,
  ListBody,
  LocationList,
  Location,
  LocationCard,
  ImageAndNameContainer,
  Address,
  AddressContainer,
  TripStopAddressContainer,
  LocationNoCard,
  YetAnotherContainer,
  WeatherContainer,
  WeatherIcon,
  WeatherTemperature,
  AmenityList,
  AmenityImage,
  FakeAmenity,
  StyledBolt,
  ChargingSpeed,
};
