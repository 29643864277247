import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { getVar } from '../../../theme/ui-variables/ui-variables';

const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${getColor('routeListOverlayBackground')};
  padding: ${getVar('sidebarPadding')} 0;
  padding-top: 0;
  position: absolute;
  transition: 0.2s;
  left: -100%;
  top: 0;

  @media screen and (max-width: 1100px) {
    padding-bottom: 6rem;
  }
`;

const OverlayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${getVar('sidebarHeaderHeight')};
  padding: 0 ${getVar('sidebarPadding')};
`;

const LogoIcon = styled.img`
  height: 3rem;
  user-select: none;
`;

export const StyledRouteLocationListOverlay = {
  OverlayContainer,
  OverlayHeader,
  LogoIcon,
};
