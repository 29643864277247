export class DurationFormatUtil {
  static formatTime = (duration: number) => {
    let minutes: string | number = Math.floor((duration % 3600) / 60);
    let hours: string | number = Math.floor(duration / 3600);

    hours = hours === 0 ? '' : hours < 10 ? '0' + hours : hours;
    minutes = minutes === 0 ? '' : minutes < 10 ? '0' + minutes : minutes;

    let time = '';
    if (hours && minutes) {
      time = hours + ' h ' + minutes + ' min ';
    } else if (!hours && minutes) {
      time = minutes + ' min ';
    } else if (hours && !minutes) {
      time = hours + ' h ';
    }

    return time;
  };

  static subtractAndFormatTime = (totalTime: number, chargingTime: number) => {
    let drivingTime = totalTime - chargingTime;

    let drivingMinutes: any = Math.floor((drivingTime % 3600) / 60);
    let drivingHours: any = Math.floor(drivingTime / 3600);

    let totalMinutes: any = Math.floor((totalTime % 3600) / 60);

    let chargingMinutes: any = Math.floor((chargingTime % 3600) / 60);

    let diff;
    if (totalMinutes < chargingMinutes) {
      totalMinutes = totalMinutes + 60;
      diff = totalMinutes - chargingMinutes;
    } else {
      diff = totalMinutes - chargingMinutes;
    }

    if (diff !== drivingMinutes) {
      drivingMinutes = diff;
    }

    drivingHours = drivingHours === 0 ? '' : drivingHours < 10 ? '0' + drivingHours : drivingHours;
    drivingMinutes =
      drivingMinutes === 0 ? '' : drivingMinutes < 10 ? '0' + drivingMinutes : drivingMinutes;

    let time = '';
    if (drivingHours && drivingMinutes) {
      time = drivingHours + ' h ' + drivingMinutes + ' min ';
    } else if (!drivingHours && drivingMinutes) {
      time = drivingMinutes + ' min ';
    } else if (drivingHours && !drivingMinutes) {
      time = drivingHours + ' h ';
    }

    return time;
  };
}
