import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';
import { getFont } from '../../../../theme/fonts/fonts';

const SubtitleContainer = styled.div`
  margin-bottom: 24px;
  margin-top: 72px;
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 24px;
  background-color: ${getColor('textLight')};
`;

const Row = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid ${getColor('tabPanel')};

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

const StyledH5 = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: ${getColor('text')};
  width: 40%;

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

const StyledText = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${getColor('text')};
  width: 60%;

  @media screen and (max-width: 400px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

const StyledText2 = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${getColor('text')};
  border-bottom: 1px solid ${getColor('tabPanel')};
  padding: 24px 0;
`;

const StyledMiniRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

const StyledIcon = styled.img`
  margin-right: 10px;
`;

const StyledDiv = styled.div`
  padding: 0 30px 30px 30px;
`;

const StyledLink = styled.a`
  &[href] {
    color: ${getColor('primary')};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledImprint = {
  SubtitleContainer,
  Divider,
  Row,
  StyledH5,
  StyledText,
  StyledText2,
  StyledMiniRow,
  StyledIcon,
  StyledDiv,
  StyledLink,
};
