import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';

const ClusterContainer = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: ${getColor('surface')};
  color: ${getColor('primary')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 4px 12px ${getColor('text', 0.1)};
`;

export const StyledClusterMarker = {
  ClusterContainer,
};
