import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { VehicleModel } from '../../models/vehicle.model';
import { axiosData } from '../../../api/axios-data';
import { vehicleService } from '../../../api/api';

export const getVehicles = (): Promise<VehicleModel[]> => {
  return axiosData<VehicleModel[]>(
    (): AxiosObservable<VehicleModel[]> => {
      return vehicleService.get('/vehicles');
    }
  )().toPromise();
};
