/*************************************************  Imports  *************************************************/
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorService } from '../../../services/error.service';
import { StyledErrorSnackbar } from './ErrorSnackbar.styles';

/*************************************************  Component  *************************************************/
export const ErrorSnackbar = () => {
  /*************************************************  State  *************************************************/
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const [t] = useTranslation();

  /*************************************************  Logic  *************************************************/
  const handleClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsOpen(false);
  }, []);

  /*************************************************  Use Effect  *************************************************/
  useEffect(() => {
    const sub = errorService.error.subscribe((errorCode: string) => {
      setIsOpen(true);
      setMessage(errorCode);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [t]);

  /*************************************************  Template  *************************************************/
  return (
    <StyledErrorSnackbar.ErrorSnackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      color="warn"
    >
      <StyledErrorSnackbar.StyledErrorSnackbarContent>
        <StyledErrorSnackbar.StyledMessage>{t(message)}</StyledErrorSnackbar.StyledMessage>
        <StyledErrorSnackbar.StyledButton onClick={handleClose}>
          <h4>OK</h4>
        </StyledErrorSnackbar.StyledButton>
      </StyledErrorSnackbar.StyledErrorSnackbarContent>
    </StyledErrorSnackbar.ErrorSnackbar>
  );
};
