import { Subject, Observable } from 'rxjs';

const alert = new Subject();

const showFilterPopup = (info: any): void => {
  alert.next(info);
};

const closeFilterPopup = (): void => {
  alert.next(null);
};

export const filterPopupService: {
  controls: Observable<any>;
  showFilterPopup(info: any): void;
  closeFilterPopup(): void;
} = {
  controls: alert.asObservable() as Observable<any>,
  showFilterPopup,
  closeFilterPopup,
};
