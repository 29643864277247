/*************************************************  Imports  *************************************************/
import { useContext, useEffect, useState } from 'react';
import { Checkbox, Dialog, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Lottie from 'react-lottie';
import animationData from '../../../assets/lotties/warn.json';

import { AppContext, AppContextModel } from '../../app-context';
import { blockedLocationService } from '../../services/blocked-location.service';
import { blockedNavigatorLocalStorageService } from '../../services/local-storage/blocked-navigator-ls.service';
import { StyledBlockedLocationDialog } from './BlockedLocationDialog.styles';

/*************************************************  Component  *************************************************/
export const BlockedLocationDialog = () => {
  /*************************************************  State  *************************************************/
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const { appContextValue, setAppContextValue } = useContext(AppContext);
  const [t] = useTranslation();

  const warnAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  Logic  *************************************************/
  const handleClose = () => {
    if (dontShowAgain) {
      blockedNavigatorLocalStorageService.setBlockedNavigator();
      updateContext('usersLocation', null);
    }
    setIsOpen(false);
  };

  const handleChange = () => {
    setDontShowAgain(!dontShowAgain);
  };

  /*************************************************  Use Effect  *************************************************/
  useEffect(() => {
    const sub = blockedLocationService.blockedDialog.subscribe(() => {
      setIsOpen(true);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [t]);

  /*************************************************  Template  *************************************************/
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="blockedLocationDialog"
      disableBackdropClick={true}
    >
      <Lottie options={warnAnimationOptions} height={50} width={50} />
      <StyledBlockedLocationDialog.StyledH2>
        {t('blocked.title')}
      </StyledBlockedLocationDialog.StyledH2>
      <StyledBlockedLocationDialog.StyledText>
        {t('blocked.message')}
      </StyledBlockedLocationDialog.StyledText>
      <FormControlLabel
        control={
          <Checkbox
            checked={dontShowAgain}
            onChange={handleChange}
            color="primary"
            data-cy="blocked-location-checkbox"
            id="blocked-location-checkbox-input"
          />
        }
        label={t('blocked.checkbox')}
      />
      <StyledBlockedLocationDialog.StyledButton
        color="primary"
        variant="contained"
        fullWidth
        onClick={handleClose}
        data-cy="blocked-location-okay-button"
      >
        <h4>{t('general.okay')}</h4>
      </StyledBlockedLocationDialog.StyledButton>
    </Dialog>
  );
};
