import { erLocalStore } from './local-store-namespace';
import { FilterPowerTypeModel } from '../../models/power-type/filter-power-type.model';

const ROUTE_FILTERS = 'route_filters';

// Payment providers
const setPaymentProviders = (selectedPaymentProviders: any[]): void => {
  erLocalStore.set(ROUTE_FILTERS + '.payment_providers', selectedPaymentProviders);
};
const getPaymentProviders = (): string[] => {
  return erLocalStore.get(ROUTE_FILTERS + '.payment_providers');
};
const isPaymentProvidersSet = (): boolean => {
  return erLocalStore.has(ROUTE_FILTERS + '.payment_providers');
};

//Charging networks
const setChargingNetworks = (selectedChargingNetworks: any[]): void => {
  erLocalStore.set(ROUTE_FILTERS + '.charging_networks', selectedChargingNetworks);
};
const getChargingNetworks = (): string[] => {
  return erLocalStore.get(ROUTE_FILTERS + '.charging_networks');
};
const isChargingNetworksSet = (): boolean => {
  return erLocalStore.has(ROUTE_FILTERS + '.charging_networks');
};

// Power type
const setPowerType = (selectedPowerTypes: FilterPowerTypeModel): void => {
  erLocalStore.set(ROUTE_FILTERS + '.power_type', selectedPowerTypes);
};
const getPowerType = (): FilterPowerTypeModel => {
  return erLocalStore.get(ROUTE_FILTERS + '.power_type');
};
const isPowerTypeSet = (): boolean => {
  return erLocalStore.has(ROUTE_FILTERS + '.power_type');
};

export const routeFiltersLocalStorageService = {
  // Payment Providers
  setPaymentProviders,
  getPaymentProviders,
  isPaymentProvidersSet,
  // Charging Networks
  setChargingNetworks,
  getChargingNetworks,
  isChargingNetworksSet,
  // Power Type
  setPowerType,
  getPowerType,
  isPowerTypeSet,
};
