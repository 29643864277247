/*************************************************  Imports  *************************************************/
import React, { ChangeEvent, useState } from 'react';
import styled from '@emotion/styled';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import { StyledLanguageSwitcher } from './LanguageSwitcher.styles';

/*************************************************  Component  *************************************************/
export const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();

  const [lang, setLang] = useState(i18n.language);

  const languageOptions = ['en', 'de', 'sr'];

  const languageChangedHandler = (event: ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setLang(val);
    i18n.changeLanguage(val);
  };

  const menuItems = languageOptions.map((value: any) => {
    return (
      <MenuItem key={value} value={value} data-cy={'language-option-' + value}>
        {value.toString().toUpperCase()}
      </MenuItem>
    );
  });

  /*************************************************  Template  *************************************************/
  return (
    <StyledLanguageSwitcher.LanguageSelect
      id="language-select"
      value={lang}
      onChange={languageChangedHandler}
      MenuProps={{
        PaperProps: { style: { borderRadius: 0 } },
      }}
      data-cy="language-select"
    >
      {menuItems}
    </StyledLanguageSwitcher.LanguageSelect>
  );
};
