import React from 'react';
import PhoneIcon from '../../../../../../assets/images/phone.svg';
import EmailIcon from '../../../../../../assets/images/mail.svg';
import ExternalLinkIcon from '../../../../../../assets/images/external-link.svg';
import { useAppAnalytics } from 'analytics';
import { StyledImprint } from '../Imprint.styles';

const ImprintDe = () => {
  const { trackEvent } = useAppAnalytics();

  const performTrackEvent = (currentEventName: string) => {
    trackEvent({ action: 'Imprint - ' + currentEventName, category: 'general' });
  };
  return (
    <StyledImprint.StyledDiv>
      <StyledImprint.SubtitleContainer>
        <h4>Angaben gemäß § 5 Abs. 1 des Telemediengesetzes:</h4>
      </StyledImprint.SubtitleContainer>
      <StyledImprint.Divider />
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Name des Anbieters:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>ElectricRoutes GmbH</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Anschrift:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>
          <div>Heilbronner Str. 86</div>
          <div>70191 Stuttgart</div>
          <div>Deutschland</div>
        </StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Vertretungsberechtigte Geschäftsführer:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>Andreas Bisenius</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Registergericht:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>Amtsgericht Stuttgart</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Registernummer:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>HRB 780686</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Umsatzsteuer-Id.-Nr. (§ 27a UStG).:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>DE264948656</StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Kontaktdaten:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>
          <StyledImprint.StyledMiniRow>
            <StyledImprint.StyledIcon src={EmailIcon} />
            <StyledImprint.StyledLink
              href={'mailto:contact@electricroutes.com'}
              onClick={() => performTrackEvent('mailto:contact@electricroutes.com')}
            >
              contact@electricroutes.com
            </StyledImprint.StyledLink>
          </StyledImprint.StyledMiniRow>
          <StyledImprint.StyledMiniRow>
            <StyledImprint.StyledIcon src={PhoneIcon} />
            <StyledImprint.StyledLink
              href={'tel:+49 711 252 749-0'}
              onClick={() => performTrackEvent('tel:+49 711 252 749-0')}
            >
              +49 711 252 749-0
            </StyledImprint.StyledLink>
          </StyledImprint.StyledMiniRow>
          <StyledImprint.StyledMiniRow>
            <StyledImprint.StyledIcon src={ExternalLinkIcon} />
            <StyledImprint.StyledLink
              href={'https://www.electricroutes.com'}
              target="_blank"
              onClick={() => performTrackEvent('https://www.electricroutes.com')}
              rel="noopener noreferrer"
            >
              https://www.electricroutes.com
            </StyledImprint.StyledLink>
          </StyledImprint.StyledMiniRow>
        </StyledImprint.StyledText>
      </StyledImprint.Row>

      <StyledImprint.SubtitleContainer>
        <h4>Verbraucherstreitbeilegung</h4>
      </StyledImprint.SubtitleContainer>
      <StyledImprint.Divider />
      <StyledImprint.StyledText2>
        <p>
          Die Europäische Kommission stellt eine Online-Plattform zur Online-Streitbeilegung zur
          Verfügung, welche unter&nbsp;
          <StyledImprint.StyledLink
            href={'http://ec.europa.eu/consumers/odr/'}
            onClick={() => performTrackEvent('http://ec.europa.eu/consumers/odr/')}
            target="_blank"
            rel="noopener noreferrer"
          >
            http://ec.europa.eu/consumers/odr/
          </StyledImprint.StyledLink>{' '}
          zu finden ist.
        </p>
        <br />
        <p>
          Die ElectricRoutes GmbH ist weder bereit noch verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <br />
        <p>
          Zusätzliche Informationen zur Berufshaftpflichtversicherung gemäß § 2 Abs. 1 Nr. 11 der
          Verordnung über Informationspflichten für Dienstleistungserbringer (DL-InfoV):
        </p>
      </StyledImprint.StyledText2>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Name und Anschrift des Versicherers:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>
          <div>AIG Europe S.A.</div>
          <div>Direktion für Deutschland</div>
          <div>Neue Mainzer Straße 46 – 50</div>
          <div>60311 Frankfurt</div>
        </StyledImprint.StyledText>
      </StyledImprint.Row>
      <StyledImprint.Row>
        <StyledImprint.StyledH5>Räumlicher Geltungsbereich:</StyledImprint.StyledH5>
        <StyledImprint.StyledText>Weltweiter Versicherungsschutz</StyledImprint.StyledText>
      </StyledImprint.Row>

      <StyledImprint.SubtitleContainer>
        <h4>Urheberrechtshinweis</h4>
      </StyledImprint.SubtitleContainer>
      <StyledImprint.Divider />
      <StyledImprint.StyledText2>
        <p>
          Die auf unserer Internetseite vorhandenen Inhalte unterliegen in der Regel dem Schutz des
          Urheberrechts. Jede unberechtigte Verwendung (insbesondere die Vervielfältigung,
          Bearbeitung oder Verbreitung) dieser urheberrechtsgeschützten Inhalte ist daher untersagt.
        </p>
      </StyledImprint.StyledText2>
    </StyledImprint.StyledDiv>
  );
};

export default ImprintDe;
