import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';

const StyledDiv = styled.div`
  padding: 0 30px 30px 30px;
`;

const StyledLink = styled.a`
  &[href] {
    color: ${getColor('primary')};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledPrivacyPolicy = {
  StyledDiv,
  StyledLink,
};
