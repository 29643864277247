/*************************************************  Imports  *************************************************/
import { useEffect, useMemo, useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';

import styled from '@emotion/styled';

import { muiTheme } from './core/theme/mui-theme/mui-theme';
import { getColor, setThemeColor } from './core/theme/colors/colors';

import { Sidebar } from './core/components/sidebar-view/Sidebar';
import { MapView } from './core/components/map-view/MapView';

import { useTranslation } from 'react-i18next';
import { AppContext, AppContextModel } from './core/app-context';
import { ErrorSnackbar } from './core/components/shared/error-snakcbar/ErrorSnackbar';
import { Loader } from './core/components/shared/loader/Loader';
import { LocationPinPopup } from './core/components/map-view/location-pin-popup/LocationPinPopup';
import { BlockedLocationDialog } from './core/components/blocked-location-dialog/BlockedLocationDialog';
import { DevStatsPopup } from './core/components/sidebar-view/dev-stats-popup/DevStatsPopup';
import { FilterPopup } from './core/components/sidebar-view/payment-providers-and-charging-networks-filters/filter-popup/FilterPopup';
import { GoogleMapsTCDialog } from './core/components/google-maps-tc-dialog/GoogleMapsTCDialog';
import { agreedToGoogleMapsLocalStorageService } from './core/services/local-storage/google-maps-ls.service';
import { routeFiltersLocalStorageService } from './core/services/local-storage/route-filters-ls.service';
import { errorService } from './core/services/error.service';
import { getLocationById } from './core/services/http/get-location-by-id';
import { LocationFullModel } from './core/models/locations-full/location-full.model';

import { matomoInstance } from './analytics';

/*************************************************  Component  *************************************************/
export const App = () => {
  // APP CONTEXT
  const [appContextValue, setAppContextValue] = useState<AppContextModel>({
    map: null,
    origin: null,
    destination: null,
    route: null,
    usersLocation: null,
    filterChargingPointOperators: routeFiltersLocalStorageService.getChargingNetworks() || null,
    filterPaymentServices: routeFiltersLocalStorageService.getPaymentProviders() || null,
    filterUpdated: null,
    filterPowerType: routeFiltersLocalStorageService.getPowerType() || null,
    filterPowerTypeUpdated: null,
    googleMapsTC: agreedToGoogleMapsLocalStorageService.isAgreedToGoogleMapsSet(),
    moovilityStation: null,
    manufacturer: '',
    routeSelectedStationId: '',
    selectedMarker: null,
  });

  const appProviderValue = useMemo(
    () => ({
      appContextValue,
      setAppContextValue,
    }),
    [appContextValue, setAppContextValue]
  );

  // TRANSLATIONS
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setThemeColor('light');

    /* Set language for translation */
    const currentLang = navigator.language.split('-')[0];
    switch (currentLang) {
      case 'de':
        i18n.changeLanguage('de');
        break;
      case 'sr':
        i18n.changeLanguage('sr');
        break;
      default:
        i18n.changeLanguage('en');
    }

    const moovilityUuid = window.location.href.split('uuid=')[1];

    if (moovilityUuid) {
      fetchLocationInfoById(moovilityUuid);
    }
  }, []);

  const fetchLocationInfoById = async (locationId: string) => {
    try {
      const response = await getLocationById(locationId);

      if (response[0]) {
        setMoovilityToContext(response[0]);
      } else {
        // If there is no data about this station or
        // If user manualy types station ID which doesn't exist
        // Response [null]

        //TODO: Maybe show dialog to let user know what's wrong
        // errorService.showError('moovility.errorNoStation');
        window.history.replaceState(null, '', window.location.href.split('uuid=')[0]);
      }
    } catch (error) {
      errorService.showError('map.errorLoadingChargingStationDetails');
    }
  };

  const setMoovilityToContext = (moovilityStationInfo: LocationFullModel) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      moovilityStation: moovilityStationInfo,
    }));
  };

  /*************************************************  Template  *************************************************/
  return (
    <MatomoProvider value={matomoInstance}>
      <MuiThemeProvider theme={muiTheme}>
        <DevStatsPopup />
        <ErrorSnackbar />
        <Loader />
        <AppContainer>
          <AppContext.Provider value={appProviderValue}>
            <LocationPinPopup />
            <BlockedLocationDialog />
            <FilterPopup />
            <Sidebar />
            <MapView />
            <GoogleMapsTCDialog />
          </AppContext.Provider>
        </AppContainer>
      </MuiThemeProvider>
    </MatomoProvider>
  );
};

/*************************************************  Styles  *************************************************/
const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${getColor('background')};
`;
