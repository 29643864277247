import styled from '@emotion/styled';
import { getColor } from '../../../../../theme/colors/colors';

export const StyledTime = styled.p`
  font-size: 12px;
  padding-top: 32px;
  padding-bottom: 13px;
  color: ${getColor('textLightest')};
`;

export const StyledReview = styled.p`
  border: 1px solid ${getColor('textLight')};
  border-radius: 16px;
  display: inline-flex;
  padding: 0 5px;
`;

export const StyledReviewWithTime = { StyledTime, StyledReview };
