import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { devStatsPopupService } from '../../../services/dev-stats-popup.service';
import { PlannerResponseModel } from '../../../models/planner/planner-response.model';
import { StyledDevStatsPopup } from './DevStatsPopup.styles';

export const DevStatsPopup = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [routeInfo, setRouteInfo] = useState<PlannerResponseModel | null>(null);

  const handleClose = useCallback(() => {
    devStatsPopupService.closeDevStatsPopup();
  }, []);

  useEffect(() => {
    const sub = devStatsPopupService.routeInfo.subscribe(
      (routeInfo: PlannerResponseModel | null) => {
        setIsOpen(!!routeInfo);
        setRouteInfo(routeInfo);
      }
    );
    return () => {
      sub.unsubscribe();
    };
  });

  return (
    <Dialog
      open={isOpen}
      className="devStatsDialog"
      hideBackdrop={true}
      disableEnforceFocus={true}
      disableAutoFocus={true}
    >
      <StyledDevStatsPopup.PopupHeader>
        <StyledDevStatsPopup.StyledH4>Dev stats</StyledDevStatsPopup.StyledH4>
        <IconButton size="small" onClick={handleClose}>
          <StyledDevStatsPopup.StyledCloseIcon />
        </IconButton>
      </StyledDevStatsPopup.PopupHeader>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>Duration</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.dev_stats?.durationTotal + ' ms'}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>Jobs</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.dev_stats?.jobsTotal}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>Solutions</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.dev_stats?.solutions}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>Matrix API</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.dev_stats?.apiMatrix + ' (' + routeInfo?.dev_stats?.durationMatrix + ' ms)'}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>Graph Matrix</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.dev_stats?.graphMatrix + ' (' + routeInfo?.dev_stats?.durationGraph + ' ms)'}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>Directions</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.dev_stats?.apiDirections +
            ' (' +
            routeInfo?.dev_stats?.durationDirections +
            ' ms)'}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>Result time</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.dev_stats?.durationResult}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>Generation</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.dev_stats?.generation}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
      <StyledDevStatsPopup.StyledRow>
        <StyledDevStatsPopup.StyledLabel>UUID</StyledDevStatsPopup.StyledLabel>
        <StyledDevStatsPopup.StyledValue>
          {routeInfo?.uuid?.substr(0, 18)}
        </StyledDevStatsPopup.StyledValue>
      </StyledDevStatsPopup.StyledRow>
    </Dialog>
  );
};
