import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';
import { getFont } from '../../../../theme/fonts/fonts';

const TabCard = styled.div`
  padding: 16px;
  background: ${getColor('surface')};
  box-shadow: 0px 4px 12px rgba(0, 0, 66, 0.1);
  border-radius: 18px;
`;

const TabDescription = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 2px 32px 32px;
  color: ${getColor('text')};
`;

const Connector = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const InfoWrapper = styled.div`
  margin-left: 16px;
  width: 100%;
`;

const StandardAndStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StandardAndPower = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${getColor('text')};
`;

const EvseId = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: ${getColor('textLightest')};
`;

const StatusAvailable = styled.div`
  background: ${getColor('successDisabled')};
  border-radius: 12px;
  padding: 8px 10px 5px;
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: ${getColor('successHover')};
`;

const StatusOccupied = styled.div`
  background: ${getColor('statusOccupiedBackground')};
  border-radius: 12px;
  padding: 8px 10px 5px;
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: ${getColor('statusOccupiedText')};
`;

const StatusUnknownOrOutOfOrder = styled.div`
  background: ${getColor('statusUnknownBackground')};
  border-radius: 12px;
  padding: 8px 10px 5px;
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  color: ${getColor('statusUnknownText')};
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid ${getColor('horizontalLine')};
  margin: 30px 0;
`;

export const StyledConnectorsInfo = {
  TabCard,
  TabDescription,
  Connector,
  InfoWrapper,
  StandardAndStatusWrapper,
  StandardAndPower,
  EvseId,
  StatusAvailable,
  StatusOccupied,
  StatusUnknownOrOutOfOrder,
  HorizontalLine,
};
