import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';
import Switch from '@material-ui/core/Switch';

const PowerTypeContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 0 !important;

  :last-child {
    margin-bottom: 3rem !important;
  }
`;

const PowerTypeDescription = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  &.disabledPowerType {
    & h3 {
      color: ${getColor('textLight')} !important;
    }

    & img {
      opacity: 0.23 !important;
    }
  }

  &.enabledPowerType {
    & h1 {
      color: ${getColor('shadow')};
    }
  }
`;

const ChargingType = styled.h3`
  margin-top: 0.4rem;
  padding: 0;
  vertical-align: middle;
  display: table-cell;
  width: 40%;
`;

const StyledBolt = styled.img`
  height: 18px;
  opacity: 1;
`;

const StyledSwitch = styled(Switch)`
  width: 51px;
  margin-right: 0 !important;
  align-items: baseline;
`;

export const StyledPowerTypeItem = {
  PowerTypeContainer,
  PowerTypeDescription,
  ChargingType,
  StyledBolt,
  StyledSwitch,
};
