import { createMuiTheme } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';

import BoltIcon from '../../../assets/images/Bolt.svg';

import { getColor, getDarkerColor, getLighterColor } from '../colors/colors';
import { getVar } from '../ui-variables/ui-variables';
import { getFont } from '../fonts/fonts';

export const muiTheme = createMuiTheme({
  palette: createPalette({
    type: 'light',
    primary: {
      light: getLighterColor('primary', 20),
      main: getColor('primary'),
      dark: getDarkerColor('primary', 20),
      contrastText: getColor('primaryContrast'),
    },

    secondary: {
      light: getLighterColor('accent', 10),
      main: getColor('accent'),
      dark: getDarkerColor('accent', 20),
      contrastText: getColor('accentContrast'),
    },

    error: {
      light: getLighterColor('error', 20),
      main: getColor('error'),
      dark: getDarkerColor('error', 20),
      contrastText: getColor('errorContrast'),
    },

    success: {
      light: getLighterColor('success', 20),
      main: getColor('success'),
      dark: getDarkerColor('success', 20),
      contrastText: getColor('successContrast'),
    },
  }),
  typography: {
    fontFamily: ['OverPass', 'Roboto', 'sans-serif'].join(','),
    htmlFontSize: 10,
  },
  spacing: 5,
  props: {
    MuiButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    MuiFormControl: {
      margin: 'dense',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
    },
    MuiToolbar: {
      variant: 'dense',
    },
    MuiTypography: {
      variant: 'body1',
    },
    MuiSelect: {
      variant: 'standard',
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiMenu: {
      variant: 'menu',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        color: getColor('text'),
      },
    },
    MuiButton: {
      root: {
        color: getColor('text'),
        textTransform: 'none',

        '&.toggleSidebarButton': {
          width: '4rem',
          height: '4rem',
          background: `${getColor('surface')} !important`,
          zIndex: '2',
          minWidth: 'unset',
          padding: 0,
          borderRadius: '50%',
          margin: '1rem 1.5rem',

          '&.close': {
            margin: '0',
            transform: 'translateX(1rem)',
          },

          '&.routeLocationList': {
            background: `${getColor('routeListOverlayBackground')} !important`,
          },
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        '&.MuiInputLabel-shrink': {
          '&.customSelect': {
            transform: 'translate(20px, 10px) scale(0.75);',
            color: getColor('label'),
            fontSize: '1.6rem',
          },
        },
        '&.MuiInputLabel-marginDense': {
          '&.customSelect': {
            transform: 'translate(20px, 24px) scale(1)',
            color: getColor('label'),
            fontSize: '1.4rem',
          },
        },
      },
    },
    MuiSelect: {
      outlined: {
        '&.MuiSelect-outlined': {
          padding: '3rem 2rem 1rem 2rem',
          fontSize: '1.4rem',
        },
      },
      iconOutlined: {
        color: getColor('text'),
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: getColor('primary'),
        },
      },
      notchedOutline: {
        borderRadius: '1.8rem',
        borderColor: getColor('primaryDisabled'),
      },
    },
    MuiSlider: {
      rail: {
        height: '4px',
        borderRadius: '4px',
        backgroundColor: getColor('sliderRail', 0.18),
      },
      track: {
        height: '4px',
        borderRadius: '4px',
        backgroundColor: getColor('primaryHover'),
      },
      thumb: {
        width: '28px',
        height: '28px',
        marginTop: '-13px',
        backgroundImage: `url(${BoltIcon})`,
        backgroundSize: '14px 20px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        boxShadow: ` 0px 0.5px 4px ${getColor('shadow', 0.12)}, 0px 6px 13px ${getColor(
          'shadow',
          0.12
        )}`,
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing',
        },
      },
    },
    MuiFormControl: {
      marginDense: {
        '&.customInput': {
          marginTop: '0px',
          marginBottom: '0px',

          '& .MuiInput-formControl': {
            height: '2.5rem',
            marginTop: '1rem',
          },
          '& .MuiInput-underline': {
            '&:before': {
              border: 'none !important',
            },
            '&:after': {
              border: 'none !important',
            },
          },
          '& .MuiInputBase-inputMarginDense': {
            padding: '1rem 0 0 0',
          },
          '& .MuiInputLabel-marginDense': {
            transform: 'translate(0px, 13px) scale(1)',
            color: `${getColor('label')} !important`,
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(0px, 1.5px) scale(0.75)',
          },
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: getVar('borderRadius'),
      },
    },
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          color: getColor('primary'),
          fontWeight: 700,
          fontSize: '1.4rem',
        },
      },
    },
    MuiDialog: {
      root: {
        '&.loaderDialog': {
          '& .MuiDialog-paper': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            filter: `drop-shadow(2px 4px 6px ${getColor('shadow', 0.3)})`,
            padding: '1rem',
          },
        },
        '&.footerLinksDialog': {
          '& .MuiDialog-container': {
            '& .MuiDialog-paper': {
              position: 'absolute',
              left: '0',
              borderRadius: '0',
              margin: '0',
              height: '100%',
              maxHeight: '100%',
              minWidth: 'unset',
              width: '100%',
              maxWidth: '92rem',
              fontFamily: getFont('mainFont'),
              color: getColor('text'),

              '& .MuiDialogContent-root': {
                padding: '0',
                '& .noAccordion': {
                  marginBottom: '3rem',

                  '@media screen and (max-width: 400px)': {
                    padding: '0 2rem',
                  },
                },

                '& .footerLinksDialogParagraph': {
                  fontFamily: getFont('mainFont'),
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  padding: '0 3rem 3rem 3rem',

                  '@media screen and (max-width: 400px)': {
                    padding: '0 1rem 3rem 1rem',
                  },

                  '&.quote': {
                    paddingLeft: '6rem',
                    fontStyle: 'italic',

                    '@media screen and (max-width: 400px)': {
                      paddingLeft: '2rem',
                    },

                    '&:last-child': {
                      padding: '0 3rem 3rem 6rem',

                      '@media screen and (max-width: 400px)': {
                        padding: '0 1rem 3rem 1rem',
                      },
                    },
                  },

                  '&:last-child': {
                    padding: '0 3rem',

                    '@media screen and (max-width: 400px)': {
                      padding: '0 1rem',
                    },
                  },

                  '& span': {
                    display: 'block',
                  },
                },

                '& h5': {
                  lineHeight: '20px',
                  padding: '2rem 3rem 3rem 3rem',

                  '@media screen and (max-width: 400px)': {
                    padding: '2rem 1rem 3rem 1rem',
                  },
                },

                '& ul': {
                  listStyleType: 'disc',
                  padding: '0 3rem 3rem 6rem',
                  fontSize: '1.4rem',
                  marginTop: '-1rem',

                  '@media screen and (max-width: 400px)': {
                    padding: '0 1rem 3rem 2rem',
                  },

                  '& li': {
                    marginTop: '1rem',
                  },
                },
              },
              '& .MuiDialogTitle-root': {
                padding: '0 30px',
                marginBottom: '8px',

                '& h2': {
                  fontSize: '24px',
                  lineHeight: '30px',
                  fontWeight: '900',
                },
              },
            },
          },
        },
        '&.locationPinInfoDialog': {
          zIndex: '1200 !important;',
          pointerEvents: 'none',
          color: getColor('text'),

          '& > .MuiDialog-scrollPaper': {
            justifyContent: 'flex-end',
            alignItems: 'flex-start',

            '& > .MuiPaper-root': {
              pointerEvents: 'auto',
              borderRadius: '1.2rem',
              padding: 0,
              minWidth: getVar('locationDialogWidth'),
              maxWidth: getVar('locationDialogWidth'),
              width: getVar('locationDialogWidth'),
              height: '100%',
              overflow: 'hidden',

              '@media screen and (max-width:550px)': {
                margin: '0',
                width: '100%',
                minWidth: '100%',
                maxHeight: '100%',
              },
            },
          },
        },
        '&.devStatsDialog': {
          zIndex: '1200 !important;',
          pointerEvents: 'none',
          color: getColor('text'),

          '& > .MuiDialog-scrollPaper': {
            justifyContent: 'flex-end',
            alignItems: 'flex-start',

            '& > .MuiPaper-root': {
              pointerEvents: 'auto',
              borderRadius: '1.2rem',
              padding: '1.5rem',
            },
          },
          '& .MuiDialog-paperWidthSm': {
            minWidth: '320px',
          },
        },
        '&.filterDialog': {
          zIndex: '1200 !important;',
          color: getColor('text'),

          '& .MuiDialog-paper': {
            overflowY: 'hidden',
          },

          '& > .MuiDialog-scrollPaper': {
            '& > .MuiPaper-root': {
              borderRadius: '1.8rem',
              padding: '3.2rem 2rem 3.2rem 3.2rem',
              height: '100%',
            },
          },
          '& .MuiDialog-paperWidthSm': {
            minWidth: 'unset',
            width: '100%',
            maxWidth: '44rem',
            margin: '0',
          },
        },
        '&.routeErrorDialog': {
          '& .MuiDialog-paper': {
            color: getColor('text'),
            padding: '3rem',
            width: '100%',
            maxWidth: '55rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius: getVar('borderRadius'),
            margin: '0',
          },
        },
        '&.blockedLocationDialog': {
          '& .MuiDialog-paper': {
            color: getColor('text'),
            padding: '3rem',
            width: '100%',
            maxWidth: '55rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius: getVar('borderRadius'),
            margin: '0',
          },
        },
        '&.googleMapsTCDialog': {
          '& .MuiDialog-paper': {
            color: getColor('text'),
            padding: '3rem',
            width: '100%',
            maxWidth: '55rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius: getVar('borderRadius'),
            boxShadow: `8px 16px 16px ${getColor('primaryHover', 0.4)}`,
            margin: '0',
          },
        },
      },
    },
    MuiAccordion: {
      root: {
        '&.MuiPaper-elevation1': {
          boxShadow: 'none',
        },
        '&:before': {
          backgroundColor: getColor('textLight'),
        },
      },
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        '&:last-child': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        display: 'block',
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: '58px',
        '& .MuiIconButton-label': {
          color: getColor('textLight'),
        },
      },
      content: {
        fontFamily: getFont('mainFont'),
        color: getColor('text'),
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '14px',
        lineHeight: '18px',
      },
    },
    MuiDialogContent: {
      root: {
        '&.imageGalleryContent': {
          backgroundColor: getColor('successContrast'),
          padding: '0',
          height: '100%',
        },
      },
    },
    MuiSwitch: {
      root: {
        height: '31px',
        width: '51px',
        padding: '0',
        margin: '17px',
        borderRadius: '25',
        '& .MuiButtonBase-root': {
          padding: '2px',
        },
      },
      switchBase: {
        '&$checked': {
          '& + $track': {
            backgroundColor: `${getColor('primary')} !important`,
            opacity: `1 !important`,
          },
        },
      },
      track: {
        borderRadius: '22px',
        backgroundColor: getColor('horizontalLine'),
        opacity: 1,
      },
      thumb: {
        height: '27px',
        width: '27px',
        backgroundColor: getColor('surface'),
      },
    },
  },
});
