import React, { useContext, useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import logoIcon from '../../../../assets/images/logo/logo-icon.svg';
import { RouteLocationList } from '../route-location-list/RouteLocationList';
import { AppContext, AppContextModel } from '../../../app-context';
import { environment } from '../../../../environment';
import { XApiKeyUtil } from '../../../util/x-api-key.util';
import { devStatsPopupService } from '../../../services/dev-stats-popup.service';
import { StyledRouteLocationListOverlay } from './RouteLocationListOverlay.styles';

export const RouteLocationListOverlay = () => {
  const { appContextValue, setAppContextValue } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [counter, setCounter] = useState(1);

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  Logic  *************************************************/
  useEffect(() => {
    if (appContextValue.route) {
      setIsOpen(true);
    }
  }, [appContextValue.route]);

  const resetRouteInfoAndCloseOverlay = () => {
    updateContext('route', null);
    setIsOpen(false);
    devStatsPopupService.closeDevStatsPopup();
  };

  const showDevStats = () => {
    setCounter(counter + 1);
    if (counter === 5 && environment.xApiKey === XApiKeyUtil.X_API_KEYS.dev) {
      devStatsPopupService.showDevStatsPopup(appContextValue.route);
      setCounter(1);
    }
  };

  return (
    <StyledRouteLocationListOverlay.OverlayContainer style={isOpen ? { left: 0 } : {}}>
      <StyledRouteLocationListOverlay.OverlayHeader>
        <StyledRouteLocationListOverlay.LogoIcon
          src={logoIcon}
          onClick={showDevStats}
          alt="Electric Routes Icon"
        />

        <IconButton onClick={resetRouteInfoAndCloseOverlay} data-cy="route-list-close-button">
          <CloseIcon />
        </IconButton>
      </StyledRouteLocationListOverlay.OverlayHeader>

      <RouteLocationList />
    </StyledRouteLocationListOverlay.OverlayContainer>
  );
};
