import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { getVar } from '../../../theme/ui-variables/ui-variables';
import { getColor } from '../../../theme/colors/colors';

const StyledCalcButton = styled(Button)`
  border-radius: ${getVar('borderRadius')} !important;
  padding: 1.5rem !important;
  margin-top: 3rem !important;
  transition: 0.3s;

  &:disabled {
    background-color: ${getColor('primaryDisabled')} !important;
    color: ${getColor('primaryContrast')} !important;
  }
`;

const StyledH2 = styled.h2`
  margin-top: 3rem;
`;

const StyledText = styled.p`
  margin-top: 1rem;
  margin-bottom: 3rem;
  max-width: 39rem;
`;

const StyledButton = styled(Button)`
  border-radius: ${getVar('borderRadius')} !important;
  padding: 1.5rem 3.5rem !important;
  box-shadow: none !important;
  margin-top: 3rem !important;
  max-width: 39rem;
`;

export const StyledCalculateRouteButton = {
  StyledCalcButton,
  StyledH2,
  StyledText,
  StyledButton,
};
