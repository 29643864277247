/*************************************************  Imports  *************************************************/
import styled from '@emotion/styled';
import { getColor } from '../../../../../theme/colors/colors';

/*************************************************  Styles  *************************************************/
const SliderLabel = styled.div`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;

const SliderValue = styled.h5`
  color: ${getColor('primary')};
`;

/*************************************************  Export  *************************************************/
export const SocSliderStyles = { SliderLabel, SliderValue };
