// ******************************************************  TYPE  ****************************************************** //
export type UIVar = keyof typeof uiVariables;

// ******************************************************  FUNCTIONS  ****************************************************** //
export function getVar(v: UIVar) {
  return `var(${uiVariables[v]})`;
}

// ******************************************************  VARIABLES  ****************************************************** //
const uiVariables = {
  sidebarWidth: '--sidebarWidth',
  sidebarPadding: '--sidebarPadding',
  sidebarHeaderHeight: '--sidebarHeaderHeight',
  sidebarFooterHeight: '--sidebarFooterHeight',
  borderRadius: '--borderRadius',
  locationDialogWidth: '--locationDialogWidth',
  locationSpacing: '--locationSpacing',
  locationProfilePictureSize: '--locationProfilePictureSize',
  locationHeaderHeight: '--locationHeaderHeight',
  locationCoverPhotoWidth: '--locationCoverPhotoWidth',
  drivingStyleFilterSize: '--drivingStyleFilterSize',
};
