import { erLocalStore } from './local-store-namespace';

const BLOCKED_NAVIGATOR = 'blocked_navigator';

const setBlockedNavigator = (): void => {
  erLocalStore.set(BLOCKED_NAVIGATOR, true);
};

const deleteBlockedNavigator = (): void => {
  return erLocalStore.remove(BLOCKED_NAVIGATOR);
};

const isBlockedNavigatorSet = (): boolean => {
  return erLocalStore.has(BLOCKED_NAVIGATOR);
};

export const blockedNavigatorLocalStorageService = {
  setBlockedNavigator,
  deleteBlockedNavigator,
  isBlockedNavigatorSet,
};
