import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { POWER_TYPE_LABELS, POWER_TYPE_FIELD_NAMES } from '../../../util/filter-power-type.util';
import { PowerTypeItem } from './power-type-item/PowerTypeItem';
import { StyledFilterPowerType } from './PowerTypeFilter.styles';
import { routeFiltersLocalStorageService } from '../../../services/local-storage/route-filters-ls.service';
import { AppContext, AppContextModel } from '../../../app-context';

export const PowerTypeFilter = () => {
  const [filterPowerTypes, setFilterPowerTypes] = useState(
    routeFiltersLocalStorageService.getPowerType() || {
      power_type_ac: true,
      power_type_dc: true,
      power_type_hpc: true,
    }
  );
  const [powerTypeError, setPowerTypeError] = useState(false);

  const setAppContextValue = useContext(AppContext).setAppContextValue;

  const { t } = useTranslation();

  useEffect(() => {
    if (powerTypeError) {
      const timer = setTimeout(() => {
        setPowerTypeError(false);
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  const setPowerTypes = (name: string, checked: boolean) => {
    const newPowerTypes = { ...filterPowerTypes, [name]: checked };
    setFilterPowerTypes(newPowerTypes);

    // save to localstorage
    routeFiltersLocalStorageService.setPowerType(newPowerTypes);

    // save to context
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      filterPowerType: newPowerTypes,
      filterPowerTypeUpdated: newPowerTypes,
    }));
  };

  const setError = (hasError: boolean) => {
    hasError ? setPowerTypeError(true) : setPowerTypeError(false);
  };

  return (
    <>
      <StyledFilterPowerType.ChargingSpeedHeading>
        {t('carConfig.chargingSpeed')}
      </StyledFilterPowerType.ChargingSpeedHeading>

      <PowerTypeItem
        initialState={filterPowerTypes}
        label={POWER_TYPE_LABELS.POWER_TYPE_AC}
        fieldName={POWER_TYPE_FIELD_NAMES.FILTER_POWER_TYPE_AC}
        numberOfBolts={1}
        setPowerTypes={setPowerTypes}
        setErrorState={setError}
      />

      <PowerTypeItem
        initialState={filterPowerTypes}
        label={POWER_TYPE_LABELS.POWER_TYPE_DC}
        fieldName={POWER_TYPE_FIELD_NAMES.FILTER_POWER_TYPE_DC}
        numberOfBolts={2}
        setPowerTypes={setPowerTypes}
        setErrorState={setError}
      />

      <PowerTypeItem
        initialState={filterPowerTypes}
        label={POWER_TYPE_LABELS.POWER_TYPE_HPC}
        fieldName={POWER_TYPE_FIELD_NAMES.FILTER_POWER_TYPE_HPC}
        numberOfBolts={3}
        setPowerTypes={setPowerTypes}
        setErrorState={setError}
      />
      {powerTypeError ? (
        <StyledFilterPowerType.StyledErrorMessage data-cy="configuration-power-type-error">
          {t('carConfig.chargingSpeedErrorMessage')}
        </StyledFilterPowerType.StyledErrorMessage>
      ) : null}
    </>
  );
};
