export enum POWER_TYPE_LABELS {
  POWER_TYPE_AC = 'AC',
  POWER_TYPE_DC = 'DC',
  POWER_TYPE_HPC = 'HPC',
}

export enum POWER_TYPE_FIELD_NAMES {
  FILTER_POWER_TYPE_AC = 'power_type_ac',
  FILTER_POWER_TYPE_DC = 'power_type_dc',
  FILTER_POWER_TYPE_HPC = 'power_type_hpc',
}

export const powerTypeToPowerTypeLabelMap: { [key in POWER_TYPE_FIELD_NAMES]: string } = {
  power_type_ac: POWER_TYPE_LABELS.POWER_TYPE_AC,
  power_type_dc: POWER_TYPE_LABELS.POWER_TYPE_DC,
  power_type_hpc: POWER_TYPE_LABELS.POWER_TYPE_HPC,
};
