import styled from '@emotion/styled';
import { getColor } from '../../../../../theme/colors/colors';

export const StyledContainer = styled.div`
  padding: 32px 32px 24px 41px !important;
  text-align: center;
  border-bottom: 0.86px solid ${getColor('statusUnknownBackground')};

  & p {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const StyledCount = styled.p`
  color: ${getColor('primary')};
`;

export const StyledPhrase = styled.p`
  white-space: pre-wrap;
`;

export const StyledOverviewOfTheReview = { StyledContainer, StyledCount, StyledPhrase };
