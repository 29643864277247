/*************************************************  Imports  *************************************************/
import { StyledClusterMarker } from './ClusterMarker.styles';

/*************************************************  Component  *************************************************/
export const ClusterMarker = (props: {
  lat: Number | undefined;
  lng: Number | undefined;
  numberOfStations: string;
}) => {
  /*************************************************  Template  *************************************************/
  // className is only for finding this element easy in "InspectElement"
  return (
    <StyledClusterMarker.ClusterContainer className="clusterMarker">
      <h5>{props.numberOfStations}</h5>
    </StyledClusterMarker.ClusterContainer>
  );
};
