import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';

const ChargingSpeedHeading = styled.h1`
  margin-top: 3rem;
`;

const StyledErrorMessage = styled.h5`
  text-align: right;
  color: ${getColor('error')};
  margin-top: 14px;
  font-size: 1.3rem;
`;

export const StyledFilterPowerType = {
  ChargingSpeedHeading,
  StyledErrorMessage,
};
