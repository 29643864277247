/*************************************************  Imports  *************************************************/
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Slider } from '@material-ui/core';

import { SocSliderStyles } from './SocSlider.styles';

import { SocSliderPropsModel } from '../../../../../models/car-config/soc-slider-props.model';

/*************************************************  Component  *************************************************/
export const SocSlider = ({
  label,
  selected,
  defaultConfig,
  onValueChange,
  onChangeCommitted,
  dataCy,
}: SocSliderPropsModel) => {
  /*************************************************  State  *************************************************/
  const [selectedValue, setSelectedValue] = useState(selected);

  const { t } = useTranslation();

  /*************************************************  Logic  *************************************************/
  const handleSliderValueChange = (event: any, value: any) => {
    setSelectedValue(value);
    onValueChange(value);
  };
  /*************************************************  Template  *************************************************/
  return (
    <>
      <SocSliderStyles.SliderLabel>
        <p>{t(label)}</p>
        <SocSliderStyles.SliderValue>{selectedValue}%</SocSliderStyles.SliderValue>
      </SocSliderStyles.SliderLabel>
      <FormControl fullWidth={true}>
        <Slider
          value={selectedValue}
          onChange={handleSliderValueChange}
          onChangeCommitted={onChangeCommitted}
          min={defaultConfig.min}
          max={defaultConfig.max}
          data-cy={dataCy}
        />
      </FormControl>
    </>
  );
};
