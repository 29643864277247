import animationData from '../../../../../assets/lotties/community_empty_state.json';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { ReviewWithTime } from './review-with-time/ReviewWithTime';
import { OverviewOfTheReview } from './overview-of-the-review/OverviewOfTheReview';
import { RatingModel } from '../../../../models/locations-full/rating.model';
import { ReviewNumberModel } from '../../../../models/community-tab/review-number.model';
import { RatingCommentModel } from '../../../../models/community-tab/rating-comment.model';
import { StyledCommunityInfo } from './CommunityInfo.styles';
import { getElapsedTime } from '../../../../util/elapsed-time.util';

export const CommunityInfo = (props: { rating: RatingModel | undefined }) => {
  const { t } = useTranslation();
  const emptyState = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { rating } = props;

  const renderLatestReviews = (latestReviews: RatingCommentModel[]) => {
    if (latestReviews && latestReviews.length) {
      return latestReviews.map((review: RatingCommentModel, index: number) => {
        return (
          <ReviewWithTime
            key={++index}
            date_submitted={review.date_submitted}
            option={review.option}
          />
        );
      });
    }
  };

  const renderReviewOptions = (reviewOptions: ReviewNumberModel[]) => {
    if (reviewOptions && reviewOptions.length) {
      return reviewOptions.map((review: ReviewNumberModel) => {
        return (
          <OverviewOfTheReview
            key={review.option}
            reviewCount={review.count}
            reviewOption={review.option}
          />
        );
      });
    }
  };

  return (
    <>
      {rating && rating!.latest_reviews!.length > 0 ? (
        <>
          <StyledCommunityInfo.WarningMessage
            className={rating?.problem_reported ? 'warning-message' : ''}
          >
            {rating?.problem_reported
              ? t('PROBLEM_REPORTED') + ' ' + getElapsedTime(rating?.date_problem_reported)
              : ''}
          </StyledCommunityInfo.WarningMessage>
          <h3>
            {rating?.number_of_reviews}
            {rating?.number_of_reviews === 1 ? t('USER_REVIEWED') : t('USERS_REVIEWED')}
          </h3>
          {rating?.latest_reviews && renderLatestReviews(rating?.latest_reviews)}
          <StyledCommunityInfo.StyledHr />
          {rating?.review_statistics && renderReviewOptions(rating?.review_statistics)}
        </>
      ) : (
        <>
          <Lottie options={emptyState} width={144} height={144} />
          <StyledCommunityInfo.StyledNoReviews>
            {t('NO_REVIEWS')}
          </StyledCommunityInfo.StyledNoReviews>
        </>
      )}
    </>
  );
};
