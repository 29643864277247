/*************************************************  Imports  *************************************************/
import { useTranslation } from 'react-i18next';
import { useAppAnalytics } from 'analytics';
import { AmenityModel } from '../../../../../models/locations-full/amenity.model';
import { getAmenityIcon } from '../../../../../util/amenity.util';
import { StyledAmenityListItem } from './AmenityListItem.styles';
import { Tooltip } from '@material-ui/core';

import hasWifiIcon from '../../../../../../assets/images/hasWifi.svg';

/*************************************************  Component  *************************************************/
export const AmenityListItem = (props: {
  amenity: AmenityModel;
  renderAmenityPolyline: (amenity: AmenityModel) => void;
  active: boolean;
}) => {
  const { amenity } = props;

  const { t } = useTranslation();

  const { trackEvent } = useAppAnalytics();

  /*************************************************  Template  *************************************************/
  return (
    <StyledAmenityListItem.AmenityContainer
      className={props.active ? 'active' : ''}
      onClick={() => {
        props.renderAmenityPolyline(amenity);
        trackEvent({
          action: 'Amenity is clicked - ' + amenity?.name,
          category: 'chargingStations',
          name: amenity.name,
        });
      }}
      data-cy={'details-tab-amenities-' + amenity.category.toLowerCase().split('_').join('-')}
    >
      <StyledAmenityListItem.IconAndNameContainer>
        <img src={getAmenityIcon(amenity)} />
        <StyledAmenityListItem.MTSpan>
          {amenity.name
            ? amenity.name
            : amenity.category
            ? t(amenity.category)
            : t('general.unknown')}
        </StyledAmenityListItem.MTSpan>
      </StyledAmenityListItem.IconAndNameContainer>

      <StyledAmenityListItem.WifiAndDistanceContainer>
        {amenity.hasWifi ? (
          <Tooltip
            arrow={true}
            color="primary"
            title={t('locationModal.details.amenityHasWifi') as string}
          >
            <StyledAmenityListItem.HasWifiImage src={hasWifiIcon} alt="This amenity has WiFi" />
          </Tooltip>
        ) : null}
        <StyledAmenityListItem.MTSpan>
          {amenity.distance ? amenity.distance + 'm' : null}
        </StyledAmenityListItem.MTSpan>
      </StyledAmenityListItem.WifiAndDistanceContainer>
    </StyledAmenityListItem.AmenityContainer>
  );
};
