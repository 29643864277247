import { ConfiguratorLocalStorageModel } from '../../models/car-config/configurator-local-storage.model';
import { erLocalStore } from './local-store-namespace';

const VEHICLE_CONFIGURATION = 'vehicle_configuration';

const setVehicleConfiguration = (vehicleConfiguration: ConfiguratorLocalStorageModel): void => {
  erLocalStore.set(VEHICLE_CONFIGURATION, vehicleConfiguration);
};

const getVehicleConfiguration = (): ConfiguratorLocalStorageModel => {
  return erLocalStore.get(VEHICLE_CONFIGURATION);
};

const isVehicleConfigurationSet = (): boolean => {
  return erLocalStore.has(VEHICLE_CONFIGURATION);
};

export const vehicleConfigurationService = {
  setVehicleConfiguration,
  getVehicleConfiguration,
  isVehicleConfigurationSet,
};
