import { VehicleDetailsModel } from './../../models/vehicle-details.model';
import { vehicleService } from './../../../api/api';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { axiosData } from '../../../api/axios-data';

export const getVehicleDetails = (vehicleId: string): Promise<VehicleDetailsModel> => {
  return axiosData<VehicleDetailsModel>(
    (): AxiosObservable<VehicleDetailsModel> => {
      return vehicleService.get(`vehicles/${vehicleId}`);
    }
  )().toPromise();
};
