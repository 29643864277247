import styled from '@emotion/styled';
import { getColor } from '../../../../theme/colors/colors';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const HeaderContainer = styled.div`
  width: 100%;
  height: 10.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${getColor('primaryContrast')};
  padding: 3rem;
`;

const StyledCloseButton = styled(Button)`
  width: 4.5rem;
  height: 4.5rem;
  min-width: unset !important;
  background: ${getColor('surface')} !important;
  border-radius: 12px !important;
  box-shadow: 0px 4px 12px ${getColor('shadow', 0.1)} !important;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${getColor('text')};
  width: 2rem !important;
  height: 2rem !important;
`;

const ImageGalleryContainer = styled.div`
  height: calc(100% - 10.5rem);
  overflow: hidden;

  & .image-gallery {
    height: 100%;

    & .image-gallery-content {
      height: 100%;
    }

    & .image-gallery-slide-wrapper {
      height: calc(100% - 10.5rem);
    }

    & .image-gallery-swipe {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }

    & .image-gallery-slides {
      overflow: unset;
    }

    & .image-gallery-slide > div {
      height: 100%;

      & > img.image-gallery-image {
        max-height: calc(100vh - 21rem);
        filter: drop-shadow(0px 10px 15px ${getColor('shadow', 0.2)});
      }
    }

    & button.image-gallery-icon {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 12px;
      background: ${getColor('surface', 0.2)};
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      filter: none;
      transition: 0.5s;

      @media screen and (hover: hover) {
        &:hover {
          background: ${getColor('surface')};

          & > svg.image-gallery-svg {
            color: ${getColor('text')};
          }
        }
      }

      & > svg.image-gallery-svg {
        width: unset;
        height: 3rem;
      }

      &.image-gallery-left-nav {
        left: 3rem;
      }

      &.image-gallery-right-nav {
        right: 3rem;
      }
    }

    & .image-gallery-bullets {
      bottom: -5.75rem;

      & .image-gallery-bullet {
        width: 8px;
        height: 8px;
        border: none;
        background: ${getColor('surface', 0.2)};
        box-shadow: none;

        &.active {
          background: ${getColor('surface', 1)};
        }
      }
    }
  }
`;

export const StyledImageGalleryModal = {
  HeaderContainer,
  StyledCloseButton,
  StyledCloseIcon,
  ImageGalleryContainer,
};
