import React, { forwardRef, ReactElement, Ref } from 'react';
import fullLogo from '../../../../assets/images/logo/logo-beta.svg';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyEn from './privacy-policy/privacy-policy-en/PrivacyPolicyEn';
import PrivacyPolicyDe from './privacy-policy/privacy-policy-de/PrivacyPolicyDe';
import PrivacyPolicySr from './privacy-policy/privacy-policy-sr/PrivacyPolicySr';
import ImprintEn from './imprint/imprint-en/ImprintEn';
import ImprintDe from './imprint/imprint-de/ImprintDe';
import ImprintSr from './imprint/imprint-sr/ImprintSr';
import { StyledFooterDialog } from './FooterLinksDialog.styles';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export const FooterLinksDialog = ({ open, onClose, content }: any) => {
  const { t, i18n } = useTranslation();

  // Set translated dialog content
  const currentLang = i18n.language.toLowerCase();
  let dialogTitle = '';
  let dialogSubtitle = '';
  let dialogContent;

  if (content === 'privacy') {
    dialogTitle = 'sidebar.privacy';

    switch (currentLang) {
      case 'de':
        dialogSubtitle = t('sidebar.privacyPolicyUpdated', {
          date: '29. September 2021',
        });
        dialogContent = <PrivacyPolicyDe />;
        break;
      case 'sr':
        dialogSubtitle = t('sidebar.privacyPolicyUpdated', {
          date: 'September 29, 2021',
        });
        dialogContent = <PrivacyPolicySr />;
        break;
      default:
        dialogSubtitle = t('sidebar.privacyPolicyUpdated', {
          date: 'September 29, 2021',
        });
        dialogContent = <PrivacyPolicyEn />;
    }
  } else {
    dialogTitle = 'sidebar.imprint';

    switch (currentLang) {
      case 'de':
        dialogSubtitle = t('sidebar.imprintUpdated', {
          date: '29. September 2021',
        });
        dialogContent = <ImprintDe />;
        break;
      case 'sr':
        dialogSubtitle = t('sidebar.imprintUpdated', {
          date: 'September 29, 2021',
        });
        dialogContent = <ImprintSr />;
        break;
      default:
        dialogSubtitle = t('sidebar.imprintUpdated', {
          date: 'September 29, 2021',
        });
        dialogContent = <ImprintEn />;
    }
  }

  return (
    <Dialog
      className="footerLinksDialog"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <StyledFooterDialog.LogoAndCloseButtonContainer>
        <StyledFooterDialog.FullLogo src={fullLogo} alt="Electric Routes Logo" />
        <IconButton onClick={onClose} data-cy={content + '-dialog-close-button'}>
          <StyledFooterDialog.CustomCloseIcon />
        </IconButton>
      </StyledFooterDialog.LogoAndCloseButtonContainer>
      <DialogTitle>{t(dialogTitle)}</DialogTitle>
      {content === 'privacy' ? (
        <StyledFooterDialog.CustomDialogSubtitle>
          {dialogSubtitle}
        </StyledFooterDialog.CustomDialogSubtitle>
      ) : null}
      <DialogContent>{dialogContent}</DialogContent>
    </Dialog>
  );
};
