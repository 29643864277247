import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';
import POI from 'assets/images/poi.svg'; // TODO: Replace with Trip Stop icon once design is ready
import { DurationFormatUtil } from 'core/util/duration-format.util';
import { StyledRouteLocationList } from '../RouteLocationList.styles';
import { TripStopModel } from 'core/models/planner/trip-stop.model';
import { TripLegModel } from 'core/models/planner/trip-leg.model';
import { toKilometers } from 'core/util/to-kilometers';
import { WeatherInfo } from './weather-info/WeatherInfo';

interface RouteLocationListProps {
  tripStop: TripStopModel;
  tripLeg: TripLegModel;
  index: number; // NOTE: Read comment below
  onClick?: (locationId: string) => void;
}

export const GeoStopListItem: FunctionComponent<RouteLocationListProps> = ({
  tripStop,
  tripLeg,
  index,
  onClick,
}) => {
  const { t } = useTranslation();
  /**
   * Geo stops do not contain charging station and its unique id, so we must build a "fake" unique id ourselves
   * to support logic for selecting/deselecting cards. Trip stop name could be used for majority of cases, but in
   * case two different towns have a same name, but a different position on the map, we use index to make sure we're
   * able to differentiate the two towns and not run into two cards being selected at the same time.
   *
   * Discuss: adding unique ID to each TripStopModel returned from BE.
   */
  const id = `trip-stop-${tripStop.name}-${index}`;

  const handleClick = () => {
    if (!onClick) {
      return;
    }

    onClick(id);
  };

  return (
    <>
      <StyledRouteLocationList.LocationCard
        id={id}
        onClick={handleClick}
        className="location-card" // TODO: rewrite selection of card logic to not rely on className
      >
        <StyledRouteLocationList.ImageAndNameContainer>
          <img src={POI} alt="trip-stop-icon" />
          <StyledRouteLocationList.TripStopAddressContainer>
            {tripStop.name}
          </StyledRouteLocationList.TripStopAddressContainer>
        </StyledRouteLocationList.ImageAndNameContainer>

        <StyledRouteLocationList.LocationListInfoSoc>
          <StyledRouteLocationList.StyledGreen>
            {round(tripStop.soc_end) + '%'}
          </StyledRouteLocationList.StyledGreen>
        </StyledRouteLocationList.LocationListInfoSoc>
      </StyledRouteLocationList.LocationCard>

      <StyledRouteLocationList.Location>
        <StyledRouteLocationList.LocationNoCard>
          <WeatherInfo tripLeg={tripLeg} />
          <StyledRouteLocationList.LocationListInfoWrapper>
            <StyledRouteLocationList.LocationListInfo>
              {toKilometers(tripLeg.distance) + ' km'}
            </StyledRouteLocationList.LocationListInfo>
            <StyledRouteLocationList.HorizontalDotSeparator />
            <StyledRouteLocationList.LocationListInfo>
              {DurationFormatUtil.formatTime(tripLeg.duration)}
            </StyledRouteLocationList.LocationListInfo>
          </StyledRouteLocationList.LocationListInfoWrapper>
          <StyledRouteLocationList.LocationListInfo>
            {round(tripLeg.soc_start) + '%'}
            {t('routeLocationList.to')}
            {round(tripLeg.soc_end) + '%'}
          </StyledRouteLocationList.LocationListInfo>
        </StyledRouteLocationList.LocationNoCard>
      </StyledRouteLocationList.Location>
    </>
  );
};
