/*************************************************  Imports  *************************************************/
import { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';

import { loaderService } from '../../../services/loader.service';

import LogoIcon from '../../../../assets/images/logo/logo-icon-white.svg';
import { StyledLoader } from './Loader.styles';

/*************************************************  Component  *************************************************/
export const Loader = () => {
  /*************************************************  State  *************************************************/
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /*************************************************  Use Effects  *************************************************/
  useEffect(() => {
    const sub = loaderService.loader.subscribe((showLoader: boolean) => {
      setIsOpen(showLoader);
    });
    return () => {
      sub.unsubscribe();
    };
  });
  /*************************************************  Template  *************************************************/
  return (
    <Dialog open={isOpen} className="loaderDialog" disableBackdropClick={true}>
      <StyledLoader.StyledImage src={LogoIcon} alt="Electric Routes Logo Icon" />
      <StyledLoader.StyledH2>Loading...</StyledLoader.StyledH2>
    </Dialog>
  );
};
