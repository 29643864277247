import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { getColor } from '../../../theme/colors/colors';
import { getFont } from '../../../theme/fonts/fonts';

const LogoAndCloseButtonContainer = styled.div`
  width: 100%;
  padding: 30px 30px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 85px;

  @media screen and (max-width: 400px) {
    margin-bottom: 4.5rem;
  }
`;

const FullLogo = styled.img`
  height: 4rem;
  user-select: none;
`;

const CustomCloseIcon = styled(CloseIcon)`
  color: ${getColor('text')};
`;

const CustomDialogSubtitle = styled.div`
  font-family: ${getFont('mainFont')};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 50px;
  padding: 0 30px;

  @media screen and (max-width: 400px) {
    margin-bottom: 4.5rem;
  }
`;

export const StyledFooterDialog = {
  LogoAndCloseButtonContainer,
  FullLogo,
  CustomCloseIcon,
  CustomDialogSubtitle,
};
