import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxiosResponse } from 'axios';

interface AxiosFetcher<T> {
  (...args: any[]): AxiosObservable<T>;
}

interface DataFetcher<T> {
  (...args: any[]): Observable<T>;
}

export function axiosData<T>(axiosFetcher: AxiosFetcher<T>): DataFetcher<T> {
  return (...params) =>
    axiosFetcher(...params).pipe(map((response: AxiosResponse<T>) => response.data));
}
