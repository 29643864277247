import { useTranslation } from 'react-i18next';
import {
  REVIEW_OPTIONS,
  REVIEW_OPTIONS_DESCRIPTIVE,
} from '../../../../../util/review-options.util';
import { StyledOverviewOfTheReview } from './OverviewOfTheReview.styles';

export const OverviewOfTheReview = (props: { reviewOption: string; reviewCount: number }) => {
  const { t } = useTranslation();

  const pairOptionWithPhrase = (reviewOption: string) => {
    let phrase = getPhrase(reviewOption);
    for (let currentOption in REVIEW_OPTIONS_DESCRIPTIVE) {
      if (
        currentOption === reviewOption &&
        REVIEW_OPTIONS_DESCRIPTIVE.hasOwnProperty(currentOption)
      ) {
        return (
          phrase +
          '\n' +
          REVIEW_OPTIONS_DESCRIPTIVE[currentOption as keyof typeof REVIEW_OPTIONS_DESCRIPTIVE]
        );
      }
    }
  };

  const getPhrase = (reviewOption: string) => {
    switch (reviewOption) {
      case REVIEW_OPTIONS.CLEAN:
      case REVIEW_OPTIONS.NOT_CLEAN:
        return t('PEOPLE_FOUND_THIS_STATION_TO_BE');
      case REVIEW_OPTIONS.NO_FUNCTIONALITY:
        return t('PEOPLE_HAVE');
      case REVIEW_OPTIONS.GOOD:
        return t('PEOPLE_THINK_THAT_THIS_STATION_IS');
      case REVIEW_OPTIONS.NOT_GOOD:
      case REVIEW_OPTIONS.AMENITIES:
        return t('PEOPLE_THINK_THAT_THIS_STATION_HAS');
      case REVIEW_OPTIONS.EASY:
      case REVIEW_OPTIONS.NOT_EASY:
        return t('PEOPLE_FELT_THIS_STATION_IS');
      case REVIEW_OPTIONS.RECOMMENDED:
      case REVIEW_OPTIONS.NOT_RECOMMENDED:
        return t('PEOPLE_SAY_THAT_THIS_STATION_THEY');
      case REVIEW_OPTIONS.NO_AMENITIES:
        return t('PEOPLE_SAY_THAT_IT_IS');
      case REVIEW_OPTIONS.NO_CABLE:
        return t('PEOPLE_REPORTED_THAT_THIS_STATION_CONTAINS');
      case REVIEW_OPTIONS.CABLE:
        return t('PEOPLE_REPORTED_THAT_THIS_STATION_HAS');
      default:
        return '';
    }
  };

  const checkIfReviewOptionHasDescription = (reviewOption: string) => {
    const phraseWithoutDescription = [
      REVIEW_OPTIONS.NO_CARD,
      REVIEW_OPTIONS.PRIVATE,
      REVIEW_OPTIONS.SEMI_PRIVATE,
      REVIEW_OPTIONS.PUBLIC,
    ];
    return !phraseWithoutDescription.includes(reviewOption as REVIEW_OPTIONS);
  };

  return (
    <>
      {props.reviewCount > 0 && checkIfReviewOptionHasDescription(props.reviewOption) && (
        <StyledOverviewOfTheReview.StyledContainer>
          <StyledOverviewOfTheReview.StyledCount>
            {props.reviewCount} {t('PEOPLE')}
          </StyledOverviewOfTheReview.StyledCount>
          <StyledOverviewOfTheReview.StyledPhrase>
            {pairOptionWithPhrase(props.reviewOption)}
          </StyledOverviewOfTheReview.StyledPhrase>
        </StyledOverviewOfTheReview.StyledContainer>
      )}
    </>
  );
};
