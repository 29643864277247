import styled from '@emotion/styled';

const LocationContainer = styled.a`
  cursor: pointer;
  width: 2.6rem;
  height: 3.7rem;
  display: block;
  transform: translate(-50%, calc(-50% - 1.1rem));
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledLocationMarker = {
  LocationContainer,
  StyledImage,
};
