/*************************************************  Imports  *************************************************/
import CurrentLocationIcon from '../../../../../assets/images/current-position.svg';
import { StyledCurrentLocationMarker } from './CurrentLocationMarker.styles';

/*************************************************  Component  *************************************************/
export const CurrentLocationMarker = (props: {
  lat: Number | undefined;
  lng: Number | undefined;
}) => {
  /*************************************************  Template  *************************************************/
  // className is only for finding this element easy in "InspectElement"
  return (
    <StyledCurrentLocationMarker.CurrentLocationContainer className="currentMarker">
      <StyledCurrentLocationMarker.StyledCurrentLocationIcon
        src={CurrentLocationIcon}
        alt="Current Location Icon"
      />
    </StyledCurrentLocationMarker.CurrentLocationContainer>
  );
};
