import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { getColor } from '../../../../theme/colors/colors';

const StyledButton = styled(Button)`
  &.MuiButtonBase-root.MuiButton-outlined {
    border: 0;
    border-radius: 0;
    width: 100%;
    height: 80px;
    padding: 0;
    justify-content: space-between;

    &:first-of-type {
      border-top: 1px solid ${getColor('textLight')};
      border-bottom: 1px solid ${getColor('textLight')};
    }
    &:last-of-type {
      border-bottom: 1px solid ${getColor('textLight')};
      margin-bottom: 3rem;
    }
    svg {
      color: ${getColor('text')};
    }
  }
`;

const NumberOfItems = styled.span`
  color: ${getColor('textLight')};
  margin-left: 1rem;
  display: inline-block;
`;

export const StyledFilter = {
  StyledButton,
  NumberOfItems,
};
