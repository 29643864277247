import React from 'react';
import { ConnectorModel } from '../../../../models/locations-full/connector.model';
import { TYPES, EVSE_STATUSES } from '../../../../util/connectorUtil';
import ConnectorTypeF from '../../../../../assets/images/connector/Connector_TypeF.svg';
import ConnectorType1 from '../../../../../assets/images/connector/Connector_Type1.svg';
import ConnectorType1Combo from '../../../../../assets/images/connector/Connector_Type1Combo.svg';
import ConnectorType2 from '../../../../../assets/images/connector/Connector_Type2.svg';
import ConnectorType2Combo from '../../../../../assets/images/connector/Connector_Type2Combo.svg';
import ConnectorType3 from '../../../../../assets/images/connector/Connector_Type3.svg';
import ConnectorChademo from '../../../../../assets/images/connector/Connector_Chademo.svg';
import ConnectorTypeUnknown from '../../../../../assets/images/connector/Connector_TypeUnknown.svg';
import { useTranslation } from 'react-i18next';
import { StyledConnectorsInfo } from './ConnectorsInfo.styles';

const ConnectorsInfo = (props: any) => {
  const [t] = useTranslation();

  let availableConnectorControls: any = [];
  let occupiedConnectorControls: any = [];
  let outOfOrderConnectorControls: any = [];
  let unknownConnectorControls: any = [];
  if (props.connectors) {
    const allConnectors = [...props.connectors];
    allConnectors.forEach((connector: ConnectorModel) => {
      switch (connector.standard) {
        case TYPES.DOMESTIC_F:
          connector.title = t('locationModal.connectors.typeF');
          connector.icon = ConnectorTypeF;
          break;
        case TYPES.IEC_62196_T1:
          connector.title = t('locationModal.connectors.type1');
          connector.icon = ConnectorType1;
          break;
        case TYPES.IEC_62196_T1_COMBO:
          connector.title = t('locationModal.connectors.type1Combo');
          connector.icon = ConnectorType1Combo;
          break;
        case TYPES.IEC_62196_T2:
          connector.title = t('locationModal.connectors.type2');
          connector.icon = ConnectorType2;
          break;
        case TYPES.IEC_62196_T2_COMBO:
          connector.title = t('locationModal.connectors.type2Combo');
          connector.icon = ConnectorType2Combo;
          break;
        case TYPES.IEC_62196_T3A:
        case TYPES.IEC_62196_T3C:
          connector.title = t('locationModal.connectors.type3');
          connector.icon = ConnectorType3;
          break;
        case TYPES.CHADEMO:
          connector.title = t('locationModal.connectors.chademo');
          connector.icon = ConnectorChademo;
          break;
        default:
          connector.title = t('locationModal.connectors.unknown');
          connector.icon = ConnectorTypeUnknown;
      }
    });

    const availableConnectors = allConnectors.filter((connector: ConnectorModel) => {
      return connector.status === EVSE_STATUSES.AVAILABLE;
    });
    const occupiedConnectors = allConnectors.filter((connector: ConnectorModel) => {
      return (
        connector.status === EVSE_STATUSES.CHARGING ||
        connector.status === EVSE_STATUSES.BLOCKED ||
        connector.status === EVSE_STATUSES.RESERVED
      );
    });
    const outOfOrderConnectors = allConnectors.filter((connector: ConnectorModel) => {
      return (
        connector.status === EVSE_STATUSES.OUTOFORDER ||
        connector.status === EVSE_STATUSES.INOPERATIVE ||
        connector.status === EVSE_STATUSES.PLANNED ||
        connector.status === EVSE_STATUSES.REMOVED
      );
    });
    const unknownConnectors = allConnectors.filter((connector: ConnectorModel) => {
      return !connector.status || connector.status === EVSE_STATUSES.UNKNOWN;
    });
    if (availableConnectors.length) {
      availableConnectorControls = availableConnectors.map((connector: ConnectorModel) => {
        return (
          <StyledConnectorsInfo.Connector key={connector.evse_uid + '_' + connector.id}>
            <img src={connector.icon} />
            <StyledConnectorsInfo.InfoWrapper>
              <StyledConnectorsInfo.StandardAndStatusWrapper>
                <StyledConnectorsInfo.StandardAndPower>
                  {connector.title} ({connector.max_electric_power_kw}kW)
                </StyledConnectorsInfo.StandardAndPower>
                <StyledConnectorsInfo.StatusAvailable>
                  {t('locationModal.connectors.available')}
                </StyledConnectorsInfo.StatusAvailable>
              </StyledConnectorsInfo.StandardAndStatusWrapper>
              <StyledConnectorsInfo.EvseId>ID: {connector.evse_uid}</StyledConnectorsInfo.EvseId>
            </StyledConnectorsInfo.InfoWrapper>
          </StyledConnectorsInfo.Connector>
        );
      });
    }
    if (occupiedConnectors.length) {
      occupiedConnectorControls = occupiedConnectors.map((connector: ConnectorModel) => {
        return (
          <StyledConnectorsInfo.Connector key={connector.evse_uid + '_' + connector.id}>
            <img src={connector.icon} />
            <StyledConnectorsInfo.InfoWrapper>
              <StyledConnectorsInfo.StandardAndStatusWrapper>
                <StyledConnectorsInfo.StandardAndPower>
                  {connector.title} ({connector.max_electric_power_kw}kW)
                </StyledConnectorsInfo.StandardAndPower>
                <StyledConnectorsInfo.StatusOccupied>
                  {t('locationModal.connectors.occupied')}
                </StyledConnectorsInfo.StatusOccupied>
              </StyledConnectorsInfo.StandardAndStatusWrapper>
              <StyledConnectorsInfo.EvseId>ID: {connector.evse_uid}</StyledConnectorsInfo.EvseId>
            </StyledConnectorsInfo.InfoWrapper>
          </StyledConnectorsInfo.Connector>
        );
      });
    }
    if (outOfOrderConnectors.length) {
      outOfOrderConnectorControls = outOfOrderConnectors.map((connector: ConnectorModel) => {
        return (
          <StyledConnectorsInfo.Connector key={connector.evse_uid + '_' + connector.id}>
            <img src={connector.icon} />
            <StyledConnectorsInfo.InfoWrapper>
              <StyledConnectorsInfo.StandardAndStatusWrapper>
                <StyledConnectorsInfo.StandardAndPower>
                  {connector.title} ({connector.max_electric_power_kw}kW)
                </StyledConnectorsInfo.StandardAndPower>
                <StyledConnectorsInfo.StatusUnknownOrOutOfOrder>
                  {t('locationModal.connectors.outOfOrder')}
                </StyledConnectorsInfo.StatusUnknownOrOutOfOrder>
              </StyledConnectorsInfo.StandardAndStatusWrapper>
              <StyledConnectorsInfo.EvseId>ID: {connector.evse_uid}</StyledConnectorsInfo.EvseId>
            </StyledConnectorsInfo.InfoWrapper>
          </StyledConnectorsInfo.Connector>
        );
      });
    }
    if (unknownConnectors.length) {
      unknownConnectorControls = unknownConnectors.map((connector: ConnectorModel) => {
        return (
          <StyledConnectorsInfo.Connector key={connector.evse_uid + '_' + connector.id}>
            <img src={connector.icon} />
            <StyledConnectorsInfo.InfoWrapper>
              <StyledConnectorsInfo.StandardAndStatusWrapper>
                <StyledConnectorsInfo.StandardAndPower>
                  {connector.title} ({connector.max_electric_power_kw}kW)
                </StyledConnectorsInfo.StandardAndPower>
                <StyledConnectorsInfo.StatusUnknownOrOutOfOrder>
                  {t('locationModal.connectors.unknown')}
                </StyledConnectorsInfo.StatusUnknownOrOutOfOrder>
              </StyledConnectorsInfo.StandardAndStatusWrapper>
              <StyledConnectorsInfo.EvseId>ID: {connector.evse_uid}</StyledConnectorsInfo.EvseId>
            </StyledConnectorsInfo.InfoWrapper>
          </StyledConnectorsInfo.Connector>
        );
      });
    }

    return (
      <div>
        <StyledConnectorsInfo.TabDescription>
          {t('locationModal.connectors.desc', {
            numberOfConnectors: props.connectors?.length,
          })}
        </StyledConnectorsInfo.TabDescription>

        <StyledConnectorsInfo.TabCard>
          {availableConnectorControls?.length ? availableConnectorControls : null}

          {availableConnectorControls?.length &&
          (occupiedConnectorControls?.length ||
            outOfOrderConnectorControls?.length ||
            unknownConnectorControls?.length) ? (
            <StyledConnectorsInfo.HorizontalLine />
          ) : null}

          {occupiedConnectorControls?.length ? occupiedConnectorControls : null}

          {occupiedConnectorControls?.length &&
          (outOfOrderConnectorControls?.length || unknownConnectorControls?.length) ? (
            <StyledConnectorsInfo.HorizontalLine />
          ) : null}

          {outOfOrderConnectorControls?.length ? outOfOrderConnectorControls : null}

          {outOfOrderConnectorControls?.length && unknownConnectorControls?.length ? (
            <StyledConnectorsInfo.HorizontalLine />
          ) : null}

          {unknownConnectorControls?.length ? unknownConnectorControls : null}
        </StyledConnectorsInfo.TabCard>
      </div>
    );
  }

  return <div>No connectors info</div>;
};

export default ConnectorsInfo;
