/*************************************************  Imports  *************************************************/
import { useTranslation } from 'react-i18next';

import { LocationFullModel } from '../../../../models/locations-full/location-full.model';

import checkmarkIcon from '../../../../../assets/images/checkmark.svg';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { AccordionSummary, Tooltip } from '@material-ui/core';
import { AmenityListItem } from './amenities/AmenityListItem';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CapabilityListItem } from './capability-list-item/CapabilityListItem';
import { AmenityModel } from '../../../../models/locations-full/amenity.model';
import { useAppAnalytics } from 'analytics';
import { StyledDetailsInfo } from './DetailsInfo.styles';
import { onlyUnique } from '../../../../util/onlyUnique.util';

/*************************************************  Component  *************************************************/
export const DetailsInfo = (props: {
  location: LocationFullModel;
  showOnMap: () => void;
  renderAmenityPolyline: (amenity: AmenityModel) => void;
  selectedAmenity: string | null;
}) => {
  const { location } = props;

  const { t } = useTranslation();

  const { trackEvent } = useAppAnalytics();

  /*************************************************  Logic  *************************************************/
  // ******************************** ADDRESS
  const calcAddressAddon = () => {
    if (location) {
      const addressAddon = [];

      if (location.postal_code) {
        addressAddon.push(location.postal_code);
      }

      if (location.city) {
        addressAddon.push(location.city);
      }

      if (location.country) {
        addressAddon.push(location.country);
      }

      return addressAddon.length > 0 ? addressAddon.join(', ') : null;
    }
  };

  // ********************************* OPEN HOURS
  const renderOpenHours = () => {
    const times = location.opening_times;

    //TODO: Extend this function when we get real examples of 'exceptional_opening' and 'exceptional_closing'
    if (times.twentyfourseven) {
      return (
        <p>
          <StyledDetailsInfo.BlueHours>00:00 - 24:00</StyledDetailsInfo.BlueHours>
          {t('locationModal.details.weekday1') + ' - ' + t('locationModal.details.weekday7')}
        </p>
      );
    } else if (times.regular_hours) {
      const rh = [];

      for (const day of times.regular_hours) {
        rh.push(
          <p key={'regular_hours_weekday_' + day.weekday}>
            <StyledDetailsInfo.BlueHours>
              {day.period_begin} - {day.period_end}
            </StyledDetailsInfo.BlueHours>{' '}
            {t(`locationModal.details.weekday${day.weekday}`)}
          </p>
        );
      }

      return rh;
    }
  };

  // Checks if all values inside of an object are false, null, ...
  // Needed for 'opening_times' object
  const allFalse = (object: any) => {
    for (var property in object) if (object[property]) return false;

    return true;
  };

  // ******************************** AMENITIES
  const renderAmenities = () => {
    if (location.amenities && location.amenities.length) {
      return (
        <>
          <StyledDetailsInfo.SeparatorLine></StyledDetailsInfo.SeparatorLine>

          <StyledDetailsInfo.AmenitiesTitleH3>
            <span>{t('locationModal.details.whatsNearStation')}</span>

            <Tooltip
              arrow={true}
              color="primary"
              title={t('locationModal.details.showOnMap') as string}
            >
              <StyledDetailsInfo.ShowOnMapButton
                color="primary"
                onClick={props.showOnMap}
                data-cy="details-tab-amenity-show-all-button"
              >
                <PinDropIcon />
              </StyledDetailsInfo.ShowOnMapButton>
            </Tooltip>
          </StyledDetailsInfo.AmenitiesTitleH3>

          {location.amenities
            .sort((a: AmenityModel, b: AmenityModel) => a.distance - b.distance)
            .map((amenity) => {
              const isActive = props.selectedAmenity === amenity.id;

              return (
                <AmenityListItem
                  active={isActive}
                  key={amenity.id}
                  amenity={amenity}
                  renderAmenityPolyline={isActive ? props.showOnMap : props.renderAmenityPolyline}
                />
              );
            })}
        </>
      );
    }
  };

  // ******************************** PAYMENT PROVIDERS
  const renderPaymentServiceProviders = () => {
    if (location.payment_services && location.payment_services.length) {
      const providers = [];

      for (const provider of location.payment_services) {
        providers.push(
          <StyledDetailsInfo.ProviderContainer key={provider.uuid}>
            <p>{provider.name}</p>
            <span>{provider.provider}</span>
          </StyledDetailsInfo.ProviderContainer>
        );
      }

      return (
        <>
          <StyledDetailsInfo.PaymentAccordion
            onChange={(event, expanded: boolean) => {
              if (expanded) {
                trackEvent({
                  action: 'Payment providers is clicked',
                  category: 'chargingStations',
                });
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              data-cy="details-tab-payment-providers"
            >
              <h3>{t('locationModal.details.paymentProvider')}</h3>
            </AccordionSummary>
            <StyledDetailsInfo.PaymentAccordionDetails>
              {providers}
            </StyledDetailsInfo.PaymentAccordionDetails>
          </StyledDetailsInfo.PaymentAccordion>

          <StyledDetailsInfo.SeparatorLine />
        </>
      );
    }
  };

  // ******************************** OPERATOR
  const renderOperator = () => {
    if (location.operator) {
      if (location.charging_point_operator) {
        return (
          <>
            <StyledDetailsInfo.TitleH3>
              {t('locationModal.details.operator')}:
            </StyledDetailsInfo.TitleH3>
            <StyledDetailsInfo.OperatorLink
              href={location.operator.website ? calcWebsiteUrl() : ''}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                trackEvent({
                  action: 'Operator - ' + location.charging_point_operator.name,
                  category: 'chargingStations',
                  name: location.charging_point_operator.name,
                })
              }
              data-cy="details-tab-operator-link"
            >
              {location.charging_point_operator.name}
            </StyledDetailsInfo.OperatorLink>
          </>
        );
      }
    }

    return null;
  };

  const calcWebsiteUrl = () => {
    if (location.operator.website.includes('http')) {
      return location.operator.website;
    } else return 'http:\\' + location.operator.website;
  };

  // ******************************** ACCESS - CAPABILITIES
  const renderCapabilities = () => {
    if (location.evses && location.evses.length) {
      const nonDisplayableCapabilities = ['PED_TERMINAL', 'RESERVABLE'];

      const capabilitiesArrays = location.evses
        .filter((evse) => evse.capabilities)
        .map((evse) => evse.capabilities);

      const uniqueCapabilities = capabilitiesArrays
        .reduce((accumulator, value) => accumulator.concat(value), [])
        .filter(onlyUnique)
        .filter((uc) => nonDisplayableCapabilities.indexOf(uc) === -1);

      if (uniqueCapabilities.length) {
        return (
          <>
            <StyledDetailsInfo.TitleH3>
              {t('locationModal.details.access')}:
            </StyledDetailsInfo.TitleH3>
            <StyledDetailsInfo.CapabilitiesContainer>
              {uniqueCapabilities.map((uc) => (
                <CapabilityListItem key={uc} capability={uc} />
              ))}
            </StyledDetailsInfo.CapabilitiesContainer>
          </>
        );
      }
    }
  };

  // ******************************** PARKING RESTRICTIONS - HINTS
  const renderParkingRestrictions = () => {
    if (location.evses && location.evses.length) {
      const hints = location.evses
        .filter((evse) => evse.parking_restrictions)
        .map((evse) => evse.parking_restrictions)
        .filter(onlyUnique);

      if (hints.length) {
        return (
          <>
            <StyledDetailsInfo.TitleH3>
              {t('locationModal.details.parkingRestrictions')}:
            </StyledDetailsInfo.TitleH3>
            <StyledDetailsInfo.HintsContainer>
              {hints.map((hint) => (
                <p key={hint}>{t(hint)}</p>
              ))}
            </StyledDetailsInfo.HintsContainer>
          </>
        );
      }
    }
  };

  /*************************************************  Template  *************************************************/
  return (
    <>
      <StyledDetailsInfo.DetailsInfoContainer>
        {location.opening_times && !allFalse(location.opening_times) ? (
          <>
            <StyledDetailsInfo.TitleH3>
              {t('locationModal.details.openHours')}:
            </StyledDetailsInfo.TitleH3>
            {renderOpenHours()}
          </>
        ) : null}

        {location.address || calcAddressAddon() ? (
          <StyledDetailsInfo.TitleH3>
            {t('locationModal.details.address')}:
          </StyledDetailsInfo.TitleH3>
        ) : null}
        {location.address ? <p>{location.address},</p> : null}
        <p>{calcAddressAddon()}</p>

        {location.parkingType ? (
          <StyledDetailsInfo.BlueChip>
            <img src={checkmarkIcon} />
            <span>{t(location.parkingType)}</span>
          </StyledDetailsInfo.BlueChip>
        ) : null}

        {renderAmenities()}

        <StyledDetailsInfo.SeparatorLine />

        {renderPaymentServiceProviders()}

        {renderOperator()}

        {renderCapabilities()}

        {renderParkingRestrictions()}
      </StyledDetailsInfo.DetailsInfoContainer>
    </>
  );
};
