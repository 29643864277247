import { useEffect, useState } from 'react';
import { DialogContent, Modal } from '@material-ui/core';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { ImageModel } from '../../../../models/locations-full/image.model';
import { ImageGalleryPropsModel } from '../../../../models/image-gallery-props.model';
import { LocationFullModel } from '../../../../models/locations-full/location-full.model';
import { StyledImageGalleryModal } from './ImageGalleryModal.styles';

export const ImageGalleryModal = ({
  isModalActive,
  imagesInfo,
  resetGalleryState,
}: ImageGalleryPropsModel) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isModalActive);
  const [galleryImages, setGalleryImages] = useState<ReactImageGalleryItem[]>([]);

  useEffect(() => {
    if (imagesInfo.images && isModalActive) {
      const mappedDataForGallery = mapDataForImageGallery(imagesInfo);
      setGalleryImages(mappedDataForGallery);
    }
  }, [imagesInfo.images]);

  const handleClose = () => {
    resetGalleryState();
    setIsModalOpen(false);
  };

  const mapDataForImageGallery = (imagesInfo: LocationFullModel) => {
    return imagesInfo.images.map((images: ImageModel) => {
      return {
        original: images?.url,
        thumbnail: images?.thumbnail ? images?.thumbnail : images?.url,
      };
    });
  };

  return (
    <>
      {galleryImages.length ? (
        <Modal open={isModalOpen} onClose={handleClose}>
          <DialogContent className="imageGalleryContent">
            <StyledImageGalleryModal.HeaderContainer>
              <h3>{imagesInfo.name}</h3>

              <StyledImageGalleryModal.StyledCloseButton
                variant="contained"
                size="medium"
                onClick={handleClose}
                data-cy="gallery-close-button"
              >
                <StyledImageGalleryModal.StyledCloseIcon />
              </StyledImageGalleryModal.StyledCloseButton>
            </StyledImageGalleryModal.HeaderContainer>

            <StyledImageGalleryModal.ImageGalleryContainer>
              <ImageGallery
                items={galleryImages}
                showThumbnails={false}
                showBullets={true}
                showFullscreenButton={false}
                showPlayButton={false}
              />
            </StyledImageGalleryModal.ImageGalleryContainer>
          </DialogContent>
        </Modal>
      ) : (
        <div></div>
      )}
    </>
  );
};
