import { EnvironmentType } from './core/util/environment-type.util';

export const environment = {
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  services: {
    chargingStationService: process.env.REACT_APP_API_URL_CHARGING_STATION,
    vehicleService: process.env.REACT_APP_API_URL_VEHICLE,
    plannerService: process.env.REACT_APP_API_URL_PLANNER,
    geocodingService: process.env.REACT_APP_API_URL_GEOCODING,
  },
  xApiKey: process.env.REACT_APP_X_API_KEY,
  env: process.env.REACT_APP_ENV_TYPE,
  matomoSiteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID as string, 10),
  matomoUrlBase: process.env.REACT_APP_MATOMO_URL_BASE,
} as {
  googleMapsKey: string;
  services: any;
  xApiKey: string;
  env: EnvironmentType;
  matomoSiteId: number;
  matomoUrlBase: string;
};
