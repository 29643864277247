import { PlannerRequestModel } from '../../models/planner/planner-request.model';
import { PlannerResponseModel } from '../../models/planner/planner-response.model';
import { PlannerMultiStopRequestModel } from '../../models/planner/planner-multi-stop-request.model';
import { axiosData } from '../../../api/axios-data';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { plannerService } from '../../../api/api';
import { RouteStop } from 'core/models/planner/route-stop.model';

export const planRouteSimple = (body: PlannerRequestModel): Promise<PlannerResponseModel> => {
  return axiosData<PlannerResponseModel>(
    (): AxiosObservable<PlannerResponseModel> => {
      return plannerService.post<PlannerResponseModel>('/plan', body);
    }
  )().toPromise();
};

export const planRouteMultiStop = (
  body: PlannerMultiStopRequestModel
): Promise<PlannerResponseModel> => {
  return axiosData<PlannerResponseModel>(
    (): AxiosObservable<PlannerResponseModel> => {
      return plannerService.post<PlannerResponseModel>('/plan-multi', body);
    }
  )().toPromise();
};

// TODO: Discuss with BE to unify two endpoints since they use the same body format apart from 'waypoints' field, which can be made optional
export const planRoute = ({
  request,
  stops,
}: {
  request: PlannerRequestModel;
  stops?: RouteStop[];
}) => {
  if (Array.isArray(stops) && stops.length > 0) {
    return planRouteMultiStop({
      ...request,
      waypoints: stops.map((stop) => ({
        coordinates: {
          latitude: stop.lat,
          longitude: stop.lng,
        },
        name: stop.name,
        duration: 0, // not supported at the moment, 0 as default
      })),
    });
  }

  return planRouteSimple(request);
};
