import BoltIcon from '../../../../../assets/images/BoltBlack.svg';
import React from 'react';
import * as _ from 'lodash';
import { PowerTypeItemPropsModel } from '../../../../models/power-type/power-type-item-props.model';
import { FilterPowerTypeModel } from '../../../../models/power-type/filter-power-type.model';
import { StyledPowerTypeItem } from './PowerTypeItem.styles';

export const PowerTypeItem = ({
  initialState,
  label,
  fieldName,
  numberOfBolts,
  setPowerTypes,
  setErrorState,
}: PowerTypeItemPropsModel) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberOfDeselectedPowerTypes = getNumberOfDeselectedPowerTypes(initialState);

    // at least one power type must always be selected
    const hasError = numberOfDeselectedPowerTypes >= 2 && !event.target.checked;

    if (hasError) {
      // do not deselect the last selected power type
      event.preventDefault();
    } else {
      setPowerTypes(fieldName, event.target.checked);
    }

    setErrorState(hasError);
  };

  const getNumberOfDeselectedPowerTypes = (initialState: FilterPowerTypeModel) => {
    let numberOfDeselectedPowerTypes = 0;
    for (const [, value] of Object.entries(initialState)) {
      if (!value) {
        ++numberOfDeselectedPowerTypes;
      }
    }
    return numberOfDeselectedPowerTypes;
  };

  const createBolts = () => {
    return _.times(numberOfBolts).map((value: number) => {
      return <StyledPowerTypeItem.StyledBolt key={value} src={BoltIcon} />;
    });
  };

  return (
    <StyledPowerTypeItem.PowerTypeContainer>
      <StyledPowerTypeItem.PowerTypeDescription
        className={initialState[fieldName] ? 'enabledPowerType' : 'disabledPowerType'}
      >
        <StyledPowerTypeItem.ChargingType>{label}</StyledPowerTypeItem.ChargingType>
        {createBolts()}
      </StyledPowerTypeItem.PowerTypeDescription>
      <StyledPowerTypeItem.StyledSwitch
        disableRipple
        color="primary"
        onChange={changeHandler}
        checked={initialState[fieldName]}
        name={fieldName}
        id={'configuration-power-type-' + label.toLowerCase() + '-input'}
        data-cy={'configuration-power-type-' + label.toLowerCase() + '-input'}
      />
    </StyledPowerTypeItem.PowerTypeContainer>
  );
};
