/*************************************************  Imports  *************************************************/
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppAnalytics } from 'analytics';
import { DrivingStylePropsModel } from '../../../../models/car-config/driving-style-props.model';
import { DrivingStyleModel } from '../../../../models/car-config/driving-style.model';
import styled from '@emotion/styled';
import { getVar } from '../../../../theme/ui-variables/ui-variables';
import { getColor } from '../../../../theme/colors/colors';

const isMacOS = () => {
  return window.navigator.appVersion.indexOf('Mac') !== -1;
};

type DrivingStyleProps = {
  isMacOS: boolean;
};

/*************************************************  Component  *************************************************/
export const DrivingStyle = ({
  selectedDrivingStyle,
  onDrivingStyleChange,
}: DrivingStylePropsModel) => {
  /*************************************************  State  *************************************************/
  const [drivingStyle, setDrivingStyle] = useState<DrivingStyleModel>(
    selectedDrivingStyle ? selectedDrivingStyle : 'NORMAL'
  );

  const { t } = useTranslation();
  const { trackEvent } = useAppAnalytics();

  /*************************************************  Logic  *************************************************/
  const handleDrivingStyleChange = (newStyle: DrivingStyleModel) => {
    setDrivingStyle(newStyle);
    onDrivingStyleChange(newStyle);

    trackEvent({
      category: 'carAndRouteSettings-granular',
      action: 'Driving mode changed',
      name: newStyle,
    });
  };

  /*************************************************  Template  *************************************************/
  return (
    <>
      <StyledHeading>{t('carConfig.drivingStyle.heading')}</StyledHeading>

      <ButtonsContainer>
        <StyledButton
          isMacOS={isMacOS()}
          type="button"
          onClick={() => handleDrivingStyleChange('ECO')}
          className={drivingStyle === 'ECO' ? 'active' : ''}
        >
          {t('carConfig.drivingStyle.eco')}
        </StyledButton>
        <StyledButton
          isMacOS={isMacOS()}
          type="button"
          onClick={() => handleDrivingStyleChange('NORMAL')}
          className={drivingStyle === 'NORMAL' ? 'active' : ''}
        >
          {t('carConfig.drivingStyle.normal')}
        </StyledButton>
        <StyledButton
          isMacOS={isMacOS()}
          type="button"
          onClick={() => handleDrivingStyleChange('FAST')}
          className={drivingStyle === 'FAST' ? 'active' : ''}
        >
          {t('carConfig.drivingStyle.fast')}
        </StyledButton>
      </ButtonsContainer>

      <StyledText>
        {drivingStyle ? t(`carConfig.drivingStyle.text.${drivingStyle.toLowerCase()}`) : ''}
      </StyledText>
    </>
  );
};

const StyledHeading = styled.h1`
  margin-top: 3.5rem;
`;

const ButtonsContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  border-radius: ${getVar('drivingStyleFilterSize')};
  overflow: hidden;
  background: ${getColor('drivingStyleFilterContainer')};
`;

const StyledButton = styled.button<DrivingStyleProps>`
  width: calc(100% / 3);
  height: ${getVar('drivingStyleFilterSize')};
  background-color: transparent;
  font-weight: 700;
  font-size: 1.8rem;
  padding: calc((${getVar('drivingStyleFilterSize')} - 1.8rem) / 2) 1rem;
  color: ${getColor('primary')};
  transition: 0.5s;
  border-radius: ${getVar('drivingStyleFilterSize')};
  display: ${(props) => (props.isMacOS ? 'flex' : 'inline-block')};
  align-items: ${(props) => (props.isMacOS ? 'center' : 'unset')};
  justify-content: ${(props) => (props.isMacOS ? 'center' : 'unset')};

  &:hover {
    background-color: ${getColor('primary', 0.15)};
  }

  &.active {
    background-color: ${getColor('primary')};
    color: ${getColor('primaryContrast')};
    pointer-events: none;
  }
`;

const StyledText = styled.p`
  font-size: 1.4rem;
  margin: 2.5rem 0 4.5rem 0;
`;
