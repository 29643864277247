import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { axiosData } from '../../../api/axios-data';
import { GeocodingResultModel } from '../../models/geocoding-result.model';
import { geocodingService } from '../../../api/api';

export const getGeocodingAutocomplete = (
  query: string,
  locale: string
): Promise<GeocodingResultModel> => {
  return axiosData<GeocodingResultModel>(
    (): AxiosObservable<GeocodingResultModel> => {
      return geocodingService.get(`/geocoding/autocomplete?query=${query}&locale=${locale}`);
    }
  )().toPromise();
};
