//*************************************************  Imports  *************************************************//
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppAnalytics } from 'analytics';

import { Box, TextField } from '@material-ui/core';
import useDebounce from '../../shared/custom-hooks/useDebounce';

import { AppContext, AppContextModel } from '../../../app-context';
import { errorService } from '../../../services/error.service';
import { getGeocodingAutocomplete } from '../../../services/http/get-geocoding-autocomplete';
import { blockedNavigatorLocalStorageService } from '../../../services/local-storage/blocked-navigator-ls.service';

import {
  GeocodingAutocompleteModel,
  GeocodingResultModel,
} from '../../../models/geocoding-result.model';

import { GeolocationInputStyles } from './GeolocationInput.styles';
import PinIcon from 'assets/images/pin-new.svg';
import FlagIcon from 'assets/images/arrival-new.svg';
import TargetIcon from '../../../../assets/images/my-location.svg';
import DefaultMarkerIcon from '../../../../assets/images/marker/marker-default.svg';
import { AutocompleteLoader } from './autocomplete-loader/AutocompleteLoader';
import { blockedLocationService } from '../../../services/blocked-location.service';
import { useRouteStops } from './useRouteStops';

type InputVariant = 'origin' | 'destination';

//*************************************************  Component  *************************************************//
export const GeolocationInput = (props: { inputFocus: boolean; variant: InputVariant }) => {
  //*************************************************  State  *************************************************//
  const { appContextValue, setAppContextValue } = useContext(AppContext);
  const station =
    appContextValue && appContextValue[props.variant] && appContextValue[props.variant];
  const [address, setAddress] = useState('');
  const { stops } = useRouteStops();

  const [isLoading, setIsLoading] = useState(false);
  const [
    autocompleteOptions,
    setAutocompleteOptions_LOCAL_STATE,
  ] = useState<GeocodingResultModel>();

  //*************************************************  Logic  *************************************************//
  // Update context -- helps with 'stale' context value in async functions

  const updateUserLocationAndOriginContext = (ulValue: any, originValue: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      usersLocation: ulValue,
      origin: originValue,
    }));
  };

  const updateContext = useCallback(
    (key: string, value: any) => {
      setAppContextValue((appContextValue: AppContextModel) => ({
        ...appContextValue,
        [key]: value,
      }));
    },
    [setAppContextValue]
  );

  /**
   * TODO: Fix this setState hijacking once autocomplete results are moved to Redux.
   *
   * Intermediate step can be moving results to context first.
   *
   * Why this: These autocomplete results are needed elsewhere, in origin/destination switch for better UX, to know
   * when to hide/show the switch button.
   *
   */
  const setAutocompleteOptions = useCallback(
    (options: GeocodingResultModel) => {
      setAutocompleteOptions_LOCAL_STATE(options);
      updateContext('totalResults', options.results.length);
    },
    [updateContext]
  );

  const inputRef = useRef<any>(null);

  const { t } = useTranslation();

  const { trackEvent } = useAppAnalytics();

  //*************************************************  Other variant  *************************************************//
  // This component is used as 'origin' or 'destination' input
  // To avoid duplicating most of the code, we provide 'variant' prop
  // Based on that we set input icon, text, placeholder,...
  // 'Other variant' is used for centering or reconfiguring the map
  const getOtherVariant = () => {
    return props.variant === 'origin' ? 'destination' : 'origin';
  };

  const [otherVariant] = useState<'origin' | 'destination'>(() => getOtherVariant());
  //*************************************************  User Locale  *************************************************//
  const getUsersLocale = () => {
    if (navigator.languages) return navigator.languages[0].split('-')[0];
    return navigator.language.split('-')[0];
  };

  const [usersLocale] = useState(() => getUsersLocale());

  //*************************************************  API  *************************************************//
  // Whenever address is changed API is called
  // Since Address is changed even on autocomplete option click, we will make an unwanted aPI call
  // This resolves that issue
  const makeADebouncedCall = useRef(false);
  // Debounce logic and API call
  const debouncedSearchTerm = useDebounce(address, 200, makeADebouncedCall.current);

  // Fetch logic
  const fetchAutocomplete = useCallback(
    async (query: string) => {
      try {
        const response = await getGeocodingAutocomplete(query, usersLocale);

        setAutocompleteOptions(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorService.showError('sidebar.autocomplete.error');
      }
    },
    [usersLocale, setAutocompleteOptions]
  );

  // useEffect for calling the API
  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsLoading(true);

      fetchAutocomplete(debouncedSearchTerm);
    } else {
      setAutocompleteOptions({ results: [] });
    }
  }, [debouncedSearchTerm, fetchAutocomplete, setAutocompleteOptions]);

  //*************************************************  UseEffect  *************************************************//
  // 'Current location' text update on translation change
  useEffect(() => {
    if (
      address === 'Your Current Position' ||
      address === 'Vaša trenutna lokacija' ||
      address === 'Deine aktuelle Position'
    ) {
      setAddress(t('sidebar.yourCurrentPosition'));
    }
  }, [t, address]);

  // populate 'address' when order of stations changes in context, the address field is not reactive to context change of origin/destination keys
  useEffect(() => {
    if (!station) {
      return;
    }

    setAddress(station.name);
  }, [station]);

  // Navigator logic
  useEffect(() => {
    if (!appContextValue.usersLocation) {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state !== 'denied') {
            blockedNavigatorLocalStorageService.deleteBlockedNavigator();
          }
        });
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          blockedNavigatorLocalStorageService.deleteBlockedNavigator();
        });
      }
    }
  }, [appContextValue]);

  // Tablet logic for focusing on 'mini sidebar' button click
  useEffect(() => {
    if (props.inputFocus && inputRef.current) {
      const timeout = setTimeout(() => {
        inputRef.current.focus();
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props.inputFocus]);

  // Moovility station
  useEffect(() => {
    if (appContextValue.moovilityStation) {
      if (props.variant === 'origin') {
        if (appContextValue.usersLocation) {
          setAddress(t('sidebar.yourCurrentPosition'));
        }
      } else {
        setAddress(appContextValue.moovilityStation.name);
      }
    }
  }, [appContextValue.moovilityStation, appContextValue.usersLocation, props.variant, t]);

  /* -------------------- Input logic -------------------- */
  const handleChange = (value: string) => {
    //After 'address' is set, 'useDebounce' hook will perform API call
    makeADebouncedCall.current = true;

    setAddress(value);
  };

  const handleClearField = () => {
    // Clear the form control value
    if (address) {
      setAddress('');
    }
    // Remove from the map
    if (appContextValue[props.variant]) {
      updateContext(props.variant, null);
    }
  };

  const handleOnFocus = () => {
    updateContext('activeInput', props.variant);
  };

  const handleOnBlur = () => {
    updateContext('activeInput', '');
  };

  /* -------------------- Autocomplete selection logic -------------------- */
  const handleSelect = (value: GeocodingAutocompleteModel) => {
    //This prevents 'useDebounce' to make a new API call after 'address' is set
    makeADebouncedCall.current = false;

    setAddress(value.title);

    const selectedLocation = {
      name: value.title.split(',')[0],
      lat: value.geo_location.latitude,
      lng: value.geo_location.longitude,
    };

    updateContext(props.variant, selectedLocation);

    setAutocompleteOptions({ results: [] });

    focusOnMap({
      lat: value.geo_location.latitude,
      lng: value.geo_location.longitude,
    });
  };

  /* -------------------- Focus or center map -------------------- */
  const focusOnMap = (coords: { lat: number; lng: number }) => {
    if (appContextValue[otherVariant]) {
      const bounds = new google.maps.LatLngBounds();

      const locations = [
        coords,
        {
          lat: appContextValue[otherVariant].lat,
          lng: appContextValue[otherVariant].lng,
        },
        ...stops,
      ];

      locations
        .filter((location) => typeof location?.lat === 'number')
        .forEach((location) => {
          const position = new google.maps.LatLng(location.lat, location.lng);
          bounds.extend(position);
        });

      appContextValue.map.fitBounds(bounds);
    } else {
      appContextValue.map.setCenter(coords);
    }
  };

  /* -------------------- Current location button -------------------- */
  // Logic
  // This will be available only when variant = 'origin' so there is no need to set context differently
  const setCurrentPositionAsOrigin = () => {
    trackEvent({ action: 'Use current position is chosen', category: 'routeCalculation' });
    makeADebouncedCall.current = false;

    if (appContextValue.usersLocation) {
      updateContext('origin', {
        name: 'sidebar.yourCurrentPosition',
        lat: appContextValue.usersLocation.lat,
        lng: appContextValue.usersLocation.lng,
      });

      setAddress(t('sidebar.yourCurrentPosition'));

      focusOnMap({
        lat: appContextValue.usersLocation.lat,
        lng: appContextValue.usersLocation.lng,
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          updateUserLocationAndOriginContext(
            {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            {
              name: 'sidebar.yourCurrentPosition',
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
          );

          setAddress(t('sidebar.yourCurrentPosition'));

          focusOnMap({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          blockedLocationService.showDialog();
        }
      );
    }
  };

  // Template
  const renderCurrentLocationButton = () => {
    if (!address && !blockedNavigatorLocalStorageService.isBlockedNavigatorSet()) {
      return (
        <div className="currentLocationButtonContainer">
          <GeolocationInputStyles.CurrentLocationButton
            onClick={setCurrentPositionAsOrigin}
            data-cy="current-location-button"
          >
            <GeolocationInputStyles.CurrentLocationIcon
              src={TargetIcon}
              alt="Current position icon"
            />
            <GeolocationInputStyles.CurrentLocationText>
              {t('sidebar.useCurrentPosition')}
            </GeolocationInputStyles.CurrentLocationText>
          </GeolocationInputStyles.CurrentLocationButton>
        </div>
      );
    }
  };

  const icons: { [variant in InputVariant]: string } = {
    origin: PinIcon,
    destination: FlagIcon,
  };

  const placeholders: { [variant in InputVariant]: string } = {
    origin: t('origin.enterOrigin'),
    destination: t('destination.enterDestination'),
  };

  const labels: { [variant in InputVariant]: string } = {
    origin: t('origin.from'),
    destination: t('destination.to'),
  };

  //*************************************************  Template  *************************************************//
  return (
    <GeolocationInputStyles.ContentContainer
      className={props.variant === 'destination' ? 'destination' : 'origin'}
    >
      <Box display="flex">
        <GeolocationInputStyles.CustomInput>
          <GeolocationInputStyles.InputIcon
            src={icons[props.variant]}
            alt={props.variant + ' icon'}
            style={
              props.variant === 'destination' ? { marginLeft: '-0.5rem', marginRight: '2rem' } : {}
            }
          />
          <TextField
            value={t(address)} // TODO: use flag to mark this is current location { lat, lng, name, isCurrentLocation } instead of translating any incoming string
            inputRef={inputRef}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            fullWidth
            placeholder={placeholders[props.variant]}
            label={labels[props.variant]}
            className="customInput"
            onChange={(event: any) => handleChange(event.target.value)}
            data-cy={'geolocation-' + props.variant}
            id={'geolocation-' + props.variant + '-input'}
            autoComplete="off"
          />
          {address ? (
            <GeolocationInputStyles.CustomIconButton
              onClick={handleClearField}
              data-cy={'geolocation-' + props.variant + '-input-close'}
            >
              <GeolocationInputStyles.CustomCloseIcon />
            </GeolocationInputStyles.CustomIconButton>
          ) : null}
        </GeolocationInputStyles.CustomInput>
      </Box>

      <div>
        {isLoading ? <AutocompleteLoader /> : null}

        {autocompleteOptions?.results?.slice(0, 10).map((option: GeocodingAutocompleteModel) => {
          return (
            <GeolocationInputStyles.SuggestionItem
              key={option.title + option.geo_location.latitude + option.geo_location.longitude}
              onClick={() => handleSelect(option)}
              data-cy="geolocation-autocomplete-option"
            >
              <GeolocationInputStyles.SuggestionItemIcon
                src={DefaultMarkerIcon}
                alt="Default Marker Icon"
              />

              <GeolocationInputStyles.SuggestionItemTextContainer>
                <GeolocationInputStyles.SuggestionItemTextMain>
                  {option.title.split(',')[0]}
                </GeolocationInputStyles.SuggestionItemTextMain>
                <GeolocationInputStyles.SuggestionItemTextSecondary>
                  {option.title.substring(option.title.indexOf(',') + 1)}
                </GeolocationInputStyles.SuggestionItemTextSecondary>
              </GeolocationInputStyles.SuggestionItemTextContainer>
            </GeolocationInputStyles.SuggestionItem>
          );
        })}
      </div>

      {props.variant === 'origin' ? renderCurrentLocationButton() : null}
    </GeolocationInputStyles.ContentContainer>
  );
};
