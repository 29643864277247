import { erLocalStore } from './local-store-namespace';

const AGREED_TO_GOOGLE_MAPS = 'agreed_to_google_maps';

const setAgreedToGoogleMaps = (): void => {
  erLocalStore.set(AGREED_TO_GOOGLE_MAPS, true);
};

const isAgreedToGoogleMapsSet = (): boolean => {
  return erLocalStore.has(AGREED_TO_GOOGLE_MAPS);
};

export const agreedToGoogleMapsLocalStorageService = {
  setAgreedToGoogleMaps,
  isAgreedToGoogleMapsSet,
};
