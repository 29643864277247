import { useRef, useCallback, useEffect, useContext } from 'react';
/*************************************************  Imports  *************************************************/
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppAnalytics } from 'analytics';
import { ConfiguratorDropdown } from '../configurator-dropdown/ConfiguratorDropdown';

import { CarSelectionFormModel } from '../../../../models/car-config/car-selection-form.model';
import { CarSelectionPropsModel } from '../../../../models/car-config/car-selection-props.model';
import { ModelsVariantsModel } from '../../../../models/car-config/models-variants.model';

import { CarSelectionStyles } from './CarSelection.styles';
import { AppContext, AppContextModel } from '../../../../app-context';
import { onlyUnique } from '../../../../util/onlyUnique.util';

/*************************************************  Component  *************************************************/
export const CarSelection = ({
  vehicles,
  localStorageData,
  onCarChange,
}: CarSelectionPropsModel) => {
  const { trackEvent } = useAppAnalytics();

  /*************************************************  State  *************************************************/
  const [manufacturers, setManufacturers] = useState<string[]>([]);
  const [modelsAndVariants, setModelsAndVariants] = useState<ModelsVariantsModel>({
    models: [],
    variants: [],
  });

  const [carSelectionForm, setCarSelectionForm] = useState<CarSelectionFormModel>({
    manufacturer: localStorageData ? localStorageData.config.manufacturer : '',
    model: localStorageData ? localStorageData.config.model : '',
    variant: localStorageData ? localStorageData.config.variant : '',
    vehicleId: localStorageData ? localStorageData.vehicleId : '',
  });

  const { t } = useTranslation();

  const { appContextValue, setAppContextValue } = useContext(AppContext);

  /*************************************************  setContext  *************************************************/
  const updateContext = (key: string, value: any) => {
    setAppContextValue((appContextValue: AppContextModel) => ({
      ...appContextValue,
      [key]: value,
    }));
  };

  /*************************************************  Filtering  *************************************************/
  //Executed on first load and on every 'manufacturer' change in 'setInitialFormData' function
  const filterModelsAndVariants = (
    manufacturer: string,
    hasInitialDataFromLocalStorage?: boolean
  ) => {
    const selectedModels = vehicles
      // Gets all of the vehicles with same manufacturer as the 'selected manufacturer'
      ?.filter((v) => v.manufacturer === manufacturer)
      // Mapping filtered objects into array of 'manufacturers'
      .map((v) => v.model)
      // Getting only unique values from new array
      .filter(onlyUnique)
      .sort();

    const selectedVariants = vehicles
      ?.filter((v) => v.manufacturer === manufacturer && v.model === selectedModels[0])
      .map((v) => v.vehicle_variant)
      .filter(onlyUnique)
      .sort();

    setModelsAndVariants({
      models: selectedModels,
      variants: selectedVariants,
    });

    if (!hasInitialDataFromLocalStorage) {
      findSelectedVehicleId(selectedVariants[0], selectedModels[0], manufacturer);
    }
  };

  // Executed only onload
  const filterOutManufacturers = () => {
    const m = vehicles
      .map((v) => v.manufacturer)
      .filter(onlyUnique)
      .sort();

    setManufacturers(m);
  };

  // Executed on every 'model' change
  const filterOutVariants = (selectedModel: string) => {
    trackEvent({
      category: 'carAndRouteSettings-granular',
      action: 'Car model changed',
      name: selectedModel,
    });

    const selectedVariants = vehicles
      ?.filter((v) => v.manufacturer === carSelectionForm.manufacturer && v.model === selectedModel)
      .map((v) => v.vehicle_variant)
      .filter(onlyUnique)
      .sort();

    findSelectedVehicleId(selectedVariants[0], selectedModel);

    setModelsAndVariants({
      ...modelsAndVariants,
      variants: selectedVariants,
    });

    setCarSelectionForm({
      ...carSelectionForm,
      model: selectedModel,
      variant: selectedVariants[0],
    });
  };

  const findSelectedVehicleId = (
    selectedVariant: string,
    selectedModel?: string,
    selectedManufacturer?: string
  ) => {
    const sModel = selectedModel || carSelectionForm.model;
    const sManufacturer = selectedManufacturer || carSelectionForm.manufacturer;

    const car = vehicles.filter(
      (v) =>
        v.vehicle_variant === selectedVariant &&
        v.model === sModel &&
        v.manufacturer === sManufacturer
    );

    const selectedCarFormConfig = {
      manufacturer: sManufacturer,
      model: sModel,
      variant: selectedVariant,
      vehicleId: car[0].vehicle_id,
    };

    setCarSelectionForm(selectedCarFormConfig);

    onCarChange(selectedCarFormConfig);
  };

  const handleVariantChange = (selectedVariant: string) => {
    trackEvent({
      category: 'carAndRouteSettings-granular',
      action: 'Car variant changed',
      name: `${carSelectionForm.manufacturer} ${carSelectionForm.model}: ${selectedVariant}`,
    });

    findSelectedVehicleId(selectedVariant);
    setCarSelectionForm({ ...carSelectionForm, variant: selectedVariant });
  };

  /*************************************************  Initialize form  *************************************************/
  const isInitialFormSetUp = useRef(false);

  const setInitialFormData = useCallback(
    (manufacturer?: string) => {
      trackEvent({
        category: 'carAndRouteSettings-granular',
        action: 'Car brand changed',
        name: manufacturer,
      });

      const selectedManufacturer = manufacturer
        ? manufacturer
        : localStorageData
        ? localStorageData.config.manufacturer
        : manufacturers[0];

      if (!isInitialFormSetUp.current) {
        filterModelsAndVariants(selectedManufacturer, !!localStorageData);

        isInitialFormSetUp.current = true;
      } else {
        filterModelsAndVariants(selectedManufacturer);
      }

      updateContext('manufacturer', selectedManufacturer);
    },
    [filterModelsAndVariants, trackEvent, manufacturers, modelsAndVariants]
  );

  useEffect(() => {
    if (vehicles.length && !isInitialFormSetUp.current) {
      filterOutManufacturers();

      if (manufacturers.length) {
        setInitialFormData();
      }
    }
  });

  /*************************************************  Update state  *************************************************/
  useEffect(() => {}, [manufacturers, modelsAndVariants]);

  /*************************************************  Template  *************************************************/
  const renderCarSelectionForm = () => {
    if (isInitialFormSetUp.current) {
      return (
        <>
          <CarSelectionStyles.CarHeading>{t('carConfig.myCar')}</CarSelectionStyles.CarHeading>
          <ConfiguratorDropdown
            values={manufacturers}
            label="carConfig.brand"
            onValueChange={setInitialFormData}
            selected={localStorageData && localStorageData.config.manufacturer}
            dataCy="configuration-manufacturer"
          />
          <ConfiguratorDropdown
            values={modelsAndVariants.models}
            label="carConfig.model"
            onValueChange={filterOutVariants}
            selected={localStorageData && localStorageData.config.model}
            dataCy="configuration-model"
          />
          <ConfiguratorDropdown
            values={modelsAndVariants.variants}
            label="carConfig.variant"
            onValueChange={handleVariantChange}
            selected={localStorageData && localStorageData.config.variant}
            dataCy="configuration-variant"
          />
        </>
      );
    }
  };

  return <>{renderCarSelectionForm()}</>;
};
