import { keyframes } from '@emotion/react';
import { getColor } from '../../../../theme/colors/colors';
import styled from '@emotion/styled';

const pulse = keyframes`
  0% {
      box-shadow: 0 0 0 0 ${getColor('accent', 0.7)}, 0 0 0 0  ${getColor('accent', 0.7)};
  }
  40% {
      box-shadow: 0 0 0 30px  ${getColor('accent', 0)}, 0 0 0 0  ${getColor('accent', 0.7)};
  }
  80% {
      box-shadow: 0 0 0 30px rgba(255, 204, 255, 0), 0 0 0 30px  ${getColor('accent', 0)};
  }
  100% {
      box-shadow: 0 0 0 0 rgba(255, 204, 255, 0), 0 0 0 30px  ${getColor('accent', 0)};
  }
`;

const CurrentLocationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  animation: ${pulse} 2s linear infinite;
  transform: translate(-50%, -50%);
  z-index: 999;
`;

const StyledCurrentLocationIcon = styled.img`
  margin-left: 0.4rem;
  margin-top: 0.7rem;
`;

export const StyledCurrentLocationMarker = {
  CurrentLocationContainer,
  StyledCurrentLocationIcon,
};
