import styled from '@emotion/styled';
import { getVar } from '../../theme/ui-variables/ui-variables';

const MapContainer = styled.div`
  width: calc(100% - ${getVar('sidebarWidth')});
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1100px) {
    width: 100%;
  }

  @media screen and (max-width: 550px) {
    height: calc(100% - (${getVar('sidebarHeaderHeight')} * 2));
    margin-top: ${getVar('sidebarHeaderHeight')};
  }
`;

const MapBackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledMapView = {
  MapContainer,
  MapBackgroundContainer,
};
