/*************************************************  Imports  *************************************************/
import styled from '@emotion/styled';

/*************************************************  Styles  *************************************************/
const SocHeading = styled.h1`
  margin-top: 3rem;
`;

const MinMaxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 1rem;

  & > .MuiFormControl-root.MuiFormControl-fullWidth {
    width: calc(50% - 0.5rem);
  }
`;
/*************************************************  Export  *************************************************/
export const SocSelectionStyles = { SocHeading, MinMaxContainer };
